import Default from 'utils/constants/default';
import { ComputeSegment } from 'models/configuration/compute-segment';
import { ComputeSegmentAI } from 'models/configuration/compute-segment-ai';

export function calculateVariation(num1, num2) {
  const num1Value = parseFloat(num1);
  const num2Value = parseFloat(num2);
  return num1Value > 0
    ? (Math.abs(num1Value - num2Value) * 100) / num1Value
    : Number.MAX_SAFE_INTEGER;
}

export function toFixed(num, fixed) {
  return num.toFixed(fixed);
}

export function isFinitive(value) {
  return !isNaN(value) && Number.isFinite(parseFloat(value));
}

export function formatNumber(value, n, x, s, c) {
  if (value == null) return '';
  if (isNaN(value)) return value;

  const floatValue = parseFloat(value);
  const num = floatValue.toFixed(Math.max(0, ~~n));
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\D' : '$'})`;

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), `$&${s || ','}`);
}

export function isPositiveNumber(value) {
  return !isNaN(value) && parseFloat(value) >= 0;
}

export function formatPercentage(num, p) {
  if (isNaN(num)) {
    return '';
  }

  const f = parseFloat(num);
  if (Math.floor(f) === f) {
    return formatNumber(f, 0);
  }

  const p1 = isPositiveNumber(p) ? p : 1;
  let result = `${formatNumber(f, p1)}`;
  while (result.endsWith('0')) {
    result = result.substring(0, result.length - 1);
  }
  return result;
}

export function formatInteger(num) {
  if (isNaN(num)) {
    return '';
  }

  const i = parseInt(num, 10);
  return formatNumber(i, 0);
}

export function isValidAmount(amount) {
  return isPositiveNumber(amount);
}

export function isValidPercent(value) {
  return isPositiveNumber(value);
}

export function isValidInteger(value) {
  return !isNaN(value) && Number.isInteger(parseFloat(value)) && parseInt(value, 10) >= 1;
}

export function isValidFloat(value) {
  return !isNaN(value) && parseFloat(value) >= 0;
}

export function isValidPositiveNumber(value) {
  return !isNaN(value) && parseFloat(value) > 0;
}

export function isIntegerInRange(value, min, max) {
  if (!isValidInteger(value)) {
    return false;
  }

  const parsedValue = parseInt(value, 10);
  if (!!min && parsedValue < parseInt(min, 10)) {
    return false;
  }

  if (!!max && parsedValue > parseInt(max, 10)) {
    return false;
  }

  return true;
}

export function isValidDays(numOfDays) {
  return isValidInteger(numOfDays);
}
export function isValidTimeframeThreshold(computeSegment, timeframeThreshold) {
  const segmentValues = {
    [ComputeSegment.day]: 1,
    [ComputeSegment.hour]: 24,
    [ComputeSegment.minute]: 60 * 24,
  };

  const maxThreshold = 365 * segmentValues[computeSegment];
  const isTimeframeThresholdExceeded = timeframeThreshold > maxThreshold;

  return isTimeframeThresholdExceeded;
}

export function checkTimeframeThreshold(computeSegmentOrDefault, timeframeThreshold) {
  let maxThreshold = 0;

  switch (computeSegmentOrDefault) {
    case ComputeSegment.day:
      maxThreshold = 365;
      break;
    case ComputeSegment.hour:
      maxThreshold = 365 * 24;
      break;
    case ComputeSegment.minute:
      maxThreshold = 365 * 24 * 60;
      break;
    default:
      break;
  }

  const isTimeframeThresholdExceeded = timeframeThreshold > maxThreshold;
  const label = isTimeframeThresholdExceeded
    ? `The timeframe threshold of ${timeframeThreshold} cannot exceed ${maxThreshold} ${computeSegmentOrDefault}s.`
    : 'The timeframe threshold is within the acceptable range.';

  return {
    exceeded: isTimeframeThresholdExceeded,
    label,
  };
}

export function checkAITimeframeThreshold(computeSegmentOrDefault, timeframeThreshold) {
  let maxThreshold = 0;
  let computeSegment = 'day';
  switch (computeSegmentOrDefault) {
    case ComputeSegmentAI.d:
      maxThreshold = 365;
      computeSegment = 'day';
      break;
    case ComputeSegmentAI.h:
      maxThreshold = 365 * 24;
      computeSegment = 'hour';
      break;
    case ComputeSegmentAI.m:
      maxThreshold = 365 * 24 * 60;
      computeSegment = 'minute';
      break;
    case ComputeSegmentAI.cw:
      maxThreshold = 52;
      computeSegment = 'week';
      break;
    case ComputeSegmentAI.cd:
      maxThreshold = 365;
      computeSegment = 'day';

      break;
    default:
      break;
  }

  let isTimeframeThresholdInvalid = false;
  let label = 'The timeframe threshold is within the acceptable range.';

  if (timeframeThreshold < 0) {
    isTimeframeThresholdInvalid = true;
    label = 'The timeframe threshold must be greater than 0.';
  } else if (timeframeThreshold === 0) {
    isTimeframeThresholdInvalid = true;
    label = '';
  } else if (timeframeThreshold > maxThreshold) {
    isTimeframeThresholdInvalid = true;
    label = `The timeframe threshold of ${timeframeThreshold} cannot exceed ${maxThreshold} ${computeSegment}s.`;
  }

  return {
    exceeded: isTimeframeThresholdInvalid,
    label,
  };
}

export function compareFloat(f1, f2) {
  return !isNaN(f1) && !isNaN(f2) && parseFloat(f1) === parseFloat(f2);
}

export function compareInt(i1, i2) {
  return (
    !isNaN(i1) &&
    Number.isInteger(parseFloat(i1)) &&
    !isNaN(i2) &&
    Number.isInteger(parseFloat(i2)) &&
    parseInt(i1, 10) === parseInt(i2, 10)
  );
}

export function formatMoney(amount) {
  const amount1 = amount === undefined || amount === null || amount.length === 0 ? 0 : amount;
  if (!isNaN(amount1)) {
    return formatNumber(amount1, 2, 3, ',', '.');
  }
  return '0.00';
}

export function formatMoneyRounded(amount) {
  const amount1 = amount === undefined || amount === null || amount.length === 0 ? 0 : amount;
  if (!isNaN(amount1)) {
    return formatNumber(amount1, 0, 3, ',', '.');
  }
  return '0';
}

export function formatMoneyAndCurrency(amount, currency) {
  return `${currency || Default.CURRENCY} ${formatMoney(amount)}`;
}

export function randomHtmlId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

export const getNormalizeValue = (value, total, factor) => (value / total) * factor;
