import { z } from 'zod';

export const TenantIdSchema = z.string().uuid();

export type TenantId = z.infer<typeof TenantIdSchema>;

export const TenantSchema = z.object({
  id: TenantIdSchema,
  name: z.string().optional(),
  experimentationTenant: z.boolean().optional(),
  subTenantUuids: z.array(z.string()).optional(),
});

export type Tenant = z.infer<typeof TenantSchema>;

export interface TenantCategory {
  category: string;
}

export interface TenantConfig {
  tenantName: string;
  tenantId: string;
  timestamp: string;
  updatedAt: string;
  userId: string;
  experimentationTenant: boolean;
  caseListSort?: string;
  tenantCategory?: TenantCategory;
}
