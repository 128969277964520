const USER_PERMISSIONS = {
  read: {
    fileList: 'read:file-list',

    /** Workspace */
    workspace: 'read:workspace',
    tasks: 'read:tasks',
    caseQualityAssurance: 'read:case-quality-assurance',

    /** Dashboard */
    dashboard: 'read:dashboard',

    /** Cases */
    trxCaseList: 'read:trx-case-list', // permission to see trx cases
    oldTrxCaseList: 'read:old-trx-case-list', // permission to see old search for trx-cases
    myCases: 'read:my-cases',
    escalatedCases: 'read:escalated-cases',
    openCases: 'read:open-cases',
    closedCases: 'read:closed-case',
    autoClosedCases: 'read:auto-closed-cases',
    unsubstantiatedCases: 'read:auto-closed-cases', // Reading unsubstantiatedCases requires the same permissions as autoClosedCases
    autoClosedReviewCases: 'read:auto-closed-review-cases',
    notOpenedCases: 'read:not-opened-cases',
    beingProcessedCases: 'read:being-processed-cases',
    assignedUser: 'read:assigned-user',
    customer: 'read:customer',
    caseFilter: 'read:case-filter',
    caseState: 'read:case-state',
    caseDetails: 'read:case-details',
    operator: 'read:operator',
    customerCaseList: 'read:customer-case-list',
    accountLookupId: 'read:account-lookup-id', // permission to see account lookup ID in cases

    /** Configurations */
    configuration: 'read:configuration',
    settings: 'read:settings',
    customerRiskRating: 'read:customer-risk-rating-config',
    workflowAndUsers: 'read:workflow-and-users',
    userManagementConfig: 'read:user-management-config',
    tenantStructure: 'read:tenant-structure',
    highlightTest: 'read:highlight-test',
    callbackConfig: 'read:callback-config',
    immediateDecisionConfig: 'read:immediate-decision-config',
    ruleManager: 'read:rule-manager',
    listManager: 'read:list-manager',
    entityResolution: 'read:entity-resolution',
    sanctionAndPep: 'read:sanction-and-pep',
    dataFeeds: 'read:data-feeds',
    machineIntelligence: 'read:machine-intelligence',
    detachmentConfig: 'read:detachment-config',
    tenantCategory: 'read:tenant-category',

    /** Audit */
    auditTrack: 'read:audit-track',

    /** Regulatory reporting */
    fincenCtrReport: 'read:fincen-ctr-report',
    fincenSarReport: 'read:fincen-sar-report',
    goamlChStrReport: 'read:goaml-str-report',
  },
  write: {
    createSubtenant: 'write:create-subtenant',
    masterTenant: 'write:master-tenant',
    fileList: 'write:file-list',

    /** Workspace */
    caseQualityAssurance: 'write:case-quality-assurance',

    /** Cases */
    myCases: 'write:my-cases',
    openCases: 'write:open-cases',
    closedCases: 'write:closed-cases',
    autoClosedCases: 'write:auto-closed-cases',
    autoClosedReviewCases: 'write:auto-closed-review-cases',
    notOpenedCases: 'write:not-opened-cases',
    beingProcessedCases: 'write:being-processed-cases',
    closeMultipleCase: 'write:close-multiple-cases',
    customerCaseList: 'write:customer-case-list',
    caseNoteAttachment: 'write:case-note-attachment',
    caseDetails: 'write:case-details',

    /** Configurations */
    configuration: 'write:configuration',
    settings: 'write:settings',
    customerRiskRating: 'write:customer-risk-rating-config',
    workflowAndUsers: 'write:workflow-and-users',
    userManagement: 'write:user-management',
    userManagementConfig: 'write:user-management-config',
    userManagementConfigAdmin: 'write:user-management-config-admin',
    callbackConfig: 'write:callback-config',
    immediateDecisionConfig: 'write:immediate-decision-config',
    ruleManager: 'write:rule-manager',
    listManager: 'write:list-manager',
    sanctionAndPep: 'write:sanction-and-pep',
    dataFeeds: 'write:data-feeds',
    machineIntelligence: 'write:machine-intelligence',
    detachmentConfig: 'write:detachment-config',
    tooling: 'write:tooling',
    newRiskFactor: 'write:new-risk-factor',
    sanctionProvider: 'write:settings-sanction-provider',
    assignAllTenantsToUsers: 'write:assign-all-tenants-to-users',
    whitelabel: 'write:whitelabel',
    categoryConfig: 'write:category-config',
    experimentationEnvironment: 'write:experimentation-environment',
    sftpConfiguration: 'write:sftp-configuration',
    tenantApiKeys: 'write:tenant-api-keys',
    rulePropagation: 'write:rule-propagation',
    adhocScreening: 'write:ad-hoc-screening',
    entityResolution: 'write:entity-resolution',
    tenantConfigExport: 'write:tenant-config-export',
    tenantConfigImport: 'write:tenant-config-import',
    tenantCategory: 'write:tenant-category',

    /** User Management */
    excludeUserDomain: 'write:exclude-user-domain',

    /** Audit */
    auditTrack: 'write:audit-track',
  },
};

export default USER_PERMISSIONS;
