import i18next from 'i18next';

export const isClosedCase = (caseStatus) =>
  caseStatus === i18next.t('mapping:caseStatus.CLOSED.key') ||
  caseStatus === i18next.t('mapping:caseStatusV2.closed.key');

export const isOpenCase = (caseStatus) =>
  caseStatus === i18next.t('mapping:caseStatus.OPEN.key') ||
  caseStatus === i18next.t('mapping:caseStatusV2.open.key');

export const isAutoclosedCase = (caseStatus) =>
  caseStatus === i18next.t('mapping:caseStatus.AUTO_CLOSED.key') ||
  caseStatus === i18next.t('mapping:caseStatusV2.auto-closed.key');

export const isUnsubstantiatedCase = (processingStatus) =>
  processingStatus === i18next.t('mapping:processingStatusV2.unsubstantiated.key');

export const isAutoclosedCaseOrIsUnsubstantiatedCase = (caseStatus, processingStatus) =>
  isAutoclosedCase(caseStatus) || isUnsubstantiatedCase(processingStatus);

export const isOpeningCase = (caseStatus) =>
  caseStatus === i18next.t('mapping:caseStatus.OPENING.key') ||
  caseStatus === i18next.t('mapping:caseStatusV2.opening.key');

export const isNotOpenedCase = (caseStatus) =>
  caseStatus === i18next.t('mapping:caseStatus.NOT_OPENED.key') ||
  caseStatus === i18next.t('mapping:caseStatusV2.not-opened.key');
