import { useState } from 'react';
import { CustomView } from 'models/custom-view';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LocalStorageService from 'services/local-storage-service';

const fetchCustomViews = async () =>
  new Promise((resolve) => {
    resolve(LocalStorageService.getTrxCustomViews());
  });

const createCustomView = async (customView: CustomView) =>
  new Promise((resolve) => {
    resolve(LocalStorageService.addTrxCustomView(customView));
  });

const updateCustomView = async (customView: CustomView) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(customView);
    }, 1800);
  });

const deleteCustomView = async (customViewId: string) =>
  new Promise((resolve) => {
    resolve(LocalStorageService.deleteTrxCustomView(customViewId));
  });

export const useCustomViews = (featureEnabled = true) => {
  const [appliedCustomView, setAppliedCustomView] = useState<CustomView | null>(null);
  const queryClient = useQueryClient();
  const dataKey = 'customViews';

  const query = useQuery([dataKey], fetchCustomViews, {
    enabled: featureEnabled,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 15,
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
    },
  });

  const applyCustomView = (customView: CustomView) => {
    setAppliedCustomView(customView);
  };

  const createMutation = useMutation(createCustomView, {
    onSuccess: () => {
      queryClient.invalidateQueries([dataKey]);
    },
  });

  const updateMutation = useMutation(updateCustomView, {
    onSuccess: () => {
      queryClient.invalidateQueries([dataKey]);
    },
  });

  const deleteMutation = useMutation(deleteCustomView, {
    onSuccess: () => {
      queryClient.invalidateQueries([dataKey]);
    },
  });

  return {
    applyCustomView,
    appliedCustomView,
    customViews: query.data as CustomView[],
    isLoading:
      query.isLoading ||
      createMutation.isLoading ||
      updateMutation.isLoading ||
      deleteMutation.isLoading,
    createCustomView: createMutation.mutate,
    updateCustomView: updateMutation.mutate,
    deleteCustomView: deleteMutation.mutate,
  };
};
