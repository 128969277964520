const ErrorCode = {
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',

  NO_TENANT_ID: 'NO_TENANT_ID',

  CASE_MANAGER_BACKEND_NOT_AVAILABLE: 'CASE_MANAGER_BACKEND_NOT_AVAILABLE',
  TENANT_CASE_FILES_NOT_AVAILABLE: 'TENANT_CASE_FILES_NOT_AVAILABLE',
  SECURE_STORAGE_NOT_AVAILABLE: 'SECURE_STORAGE_NOT_AVAILABLE',
  SANCTION_BACKEND_NOT_AVAILABLE: 'SANCTION_BACKEND_NOT_AVAILABLE',

  CASE_DETAIL_DATA_NOT_FOUND: 'CASE_DETAIL_DATA_NOT_FOUND',
  CASE_DETAIL_HAS_BEEN_MODIFIED: 'CASE_DETAIL_HAS_BEEN_MODIFIED',

  CASES_DATA_INVALID: 'CASES_DATA_INVALID',
  CASE_DETAIL_DATA_INVALID: 'CASE_DETAIL_DATA_INVALID',
  SANCTION_HITS_DATA_INVALID: 'SANCTION_HITS_DATA_INVALID',
  SECURE_STORAGE_DATA_INVALID: 'SECURE_STORAGE_DATA_INVALID',

  CASE_NOTE_DATA_INVALID: 'CASE_NOTE_DATA_INVALID',
  AUDIT_TRACK_DATA_INVALID: 'AUDIT_TRACK_DATA_INVALID',
  STAND_ALONE_AUDIT_TRACK_DATA_INVALID: 'STAND_ALONE_AUDIT_TRACK_DATA_INVALID',

  SANCTION_LIST_DATA_INVALID: 'SANCTION_LIST_DATA_INVALID',
  ENTITY_TYPES_DATA_INVALID: 'ENTITY_TYPES_DATA_INVALID',

  DECISION_ENGINE_DATA_INVALID: 'DECISION_ENGINE_DATA_INVALID',

  ACCOUNT_BLACKLIST_LIST_DATA_INVALID: 'ACCOUNT_BLACKLIST_LIST_DATA_INVALID',
  SUSPICIOUS_WORDS_DATA_INVALID: 'SUSPICIOUS_WORDS_DATA_INVALID',

  AUTH0_API_NOT_AVAILABLE: 'AUTH0_API_NOT_AVAILABLE',
  AUTH0_API_ERROR: 'AUTH0_API_ERROR',
  DETACHMENT_CONFIG_MISSING: 'DETACHMENT_CONFIG_MISSING',
  DETACHMENT_CONFIG_FAILED_UPDATE: 'DETACHMENT_CONFIG_FAILED_UPDATE',

  PASSTHROUGH_CONFIG_DATA_INVALID: 'PASSTHROUGH_CONFIG_DATA_INVALID',

  SMURFING_CONFIG_DATA_INVALID: 'SMURFING_CONFIG_DATA_INVALID',
  CONFLUENCE_PAGE_NOT_AVAILABLE: 'CONFLUENCE_PAGE_NOT_AVAILABLE',
  CONFLUENCE_DATA_INVALID: 'CONFLUENCE_DATA_INVALID',

  CONFIG_DATA_INVALID: 'CONFIG_DATA_INVALID',

  MCC_LIST_DATA_NOT_FOUND: 'MCC_LIST_DATA_NOT_FOUND',
  MCC_LIST_DATA_INVALID: 'MCC_LIST_DATA_INVALID',

  HIDTA_HIFCA_LIST_DATA_NOT_FOUND: 'HIDTA_HIFCA_LIST_DATA_NOT_FOUND',
  HIDTA_HIFCA_LIST_DATA_INVALID: 'HIDTA_HIFCA_LIST_DATA_INVALID',

  OCCUPATION_LIST_DATA_NOT_FOUND: 'OCCUPATION_LIST_DATA_NOT_FOUND',
  OCCUPATION_LIST_DATA_INVALID: 'OCCUPATION_LIST_DATA_INVALID',

  RISKY_WORDS_LIST_DATA_NOT_FOUND: 'RISKY_WORDS_LIST_DATA_NOT_FOUND',
  RISKY_WORDS_LIST_DATA_INVALID: 'RISKY_WORDS_LIST_DATA_INVALID',

  FILTERS_LIST_DATA_NOT_FOUND: 'FILTERS_LIST_DATA_NOT_FOUND',
  FILTERS_LIST_DATA_INVALID: 'FILTERS_LIST_DATA_INVALID',

  PRODUCT_TYPE_LIST_DATA_NOT_FOUND: 'PRODUCT_TYPE_LIST_DATA_NOT_FOUND',
  PRODUCT_TYPE_LIST_DATA_INVALID: 'PRODUCT_TYPE_LIST_DATA_INVALID',

  hidtaHifca: {
    dataNotFound: 'HIDTA_HIFCA_LIST_DATA_NOT_FOUND',
    dataInvalid: 'HIDTA_HIFCA_LIST_DATA_INVALID',
  },

  ACCOUNT_HOLDING_PARTY_DATA_INVALID: 'ACCOUNT_HOLDING_PARTY_DATA_INVALID',

  CUSTOMER_RISK_RATING_CONFIG_DATA_INVALID: 'CUSTOMER_RISK_RATING_CONFIG_DATA_INVALID',
  CUSTOMER_RISK_RATING_CONFIG_UPDATE_DATA_INVALID:
    'CUSTOMER_RISK_RATING_CONFIG_UPDATE_DATA_INVALID',

  AUDIT_TRAIL_ADD_PAGE_INVALID: 'AUDIT_TRAIL_ADD_PAGE_INVALID',
  RESET_CONTENT: 'RESET_CONTENT', // 205

  MISSING_PERMISSIONS: 'MISSING_PERMISSIONS',

  FINCEN_SAR_DATA_INVALID: 'FINCEN_SAR_DATA_INVALID',
  LIST_PROVIDERS_MISMATCH: 'LIST_PROVIDERS_MISMATCH',

  DASHBOARD_DATA_NOT_AVAILABLE: 'DASHBOARD_DATA_NOT_AVAILABLE',
} as const;

export default ErrorCode;
