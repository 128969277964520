import i18next from 'i18next';
import { FCValidationResult } from 'modules/trx-cases/case-detail/model/fincen/shared/fc-validation-result';
import Strings from 'utils/helpers/locales/strings.en';

type AdditionalInfoForRedBox = { type?: string; apiUrl?: string; correlationId?: string };

type StringsTypeAlias = {
  [key: string]: string;
};

type HeadingsTypeAlias = {
  [key: string]: {
    heading: string;
  };
};

export const getErrorMessageFromCode = (code: string) => {
  const stringsSanitized: StringsTypeAlias = Strings.ErrorMessage;
  const headingsSanitized: HeadingsTypeAlias = i18next.t('error:types') ?? {};
  return headingsSanitized[code]?.heading ?? stringsSanitized[code] ?? '';
};

export function getErrorByCode(
  code: string,
  moreData?: Array<string>,
  additionalInfo?: AdditionalInfoForRedBox,
) {
  let message: string = getErrorMessageFromCode(code);
  let details;
  if (Array.isArray(moreData)) {
    for (let i = 0; i < moreData.length; i++) {
      const data = moreData[i];
      const placeholder = `{${i}}`;
      message = message.replace(placeholder, data);
    }
  }
  if (additionalInfo?.type === 'backend') {
    details = {
      caseUrl: window.location.toString(),
      apiUrl: additionalInfo?.apiUrl,
      sessionId: additionalInfo?.correlationId,
    };
  }

  return { code, message, details };
}

export function raiseErrorByCode(
  code: string,
  moreData?: Array<string>,
  additionalInfo?: AdditionalInfoForRedBox,
) {
  const err = getErrorByCode(code, moreData, additionalInfo);
  return Promise.reject(err);
}

export function calculateExpireAt(mins: string) {
  return JSON.stringify(parseInt(mins, 10) * 60 * 1000 + new Date().getTime());
}

export type FCReportError = {
  code: string;
  message: string;
  detailsForRedBox: {
    caseUrl: string;
    apiUrl?: string;
    sessionId?: string;
  };
  validationResult: FCValidationResult;
};

export function raiseErrorByCodeForFCReport(
  code: string,
  additionalInfo: AdditionalInfoForRedBox,
  validationResult: FCValidationResult,
) {
  const message: string = getErrorMessageFromCode(code);
  const detailsForRedBox = {
    caseUrl: window.location.toString(),
    apiUrl: additionalInfo?.apiUrl,
    sessionId: additionalInfo?.correlationId,
  };
  const err: FCReportError = {
    code,
    message,
    detailsForRedBox,
    validationResult,
  };

  return Promise.reject(err);
}

export const maskUserId = (headers: { [key: string]: string }): { [key: string]: string } => {
  const { userId } = headers;

  if (!userId) return headers;

  if (userId.includes('@')) {
    const [name, domain] = userId.split('@');
    let maskedName;
    if (name.length > 2) {
      maskedName = name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
    } else if (name.length === 2) {
      maskedName = `${name[0]}*`;
    } else if (name.length === 1) {
      maskedName = '*';
    } else {
      maskedName = name;
    }
    headers.userId = `${maskedName}@${domain}`;
  } else {
    let maskedName = '';
    for (let i = 0; i < userId.length; i++) {
      if (userId[i] === ' ') {
        maskedName += ' ';
      } else if (i === 0 || i === userId.length - 1) {
        maskedName += userId[i];
      } else {
        maskedName += '*';
      }
    }
    headers.userId = maskedName;
  }
  return headers;
};
