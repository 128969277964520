import {
  addAuditTrack,
  addAuditTrackError,
  addAuditTrackSuccess,
  addStandaloneAuditTrackError,
  addStandaloneAuditTrackSuccess,
  getAuditTracksError,
  getAuditTracksSuccess,
  getCaseDetail,
  getCaseStateChanges,
  getDistinctCurrencyValuesByCaseStatusError,
  getDistinctCurrencyValuesByCaseStatusSuccess,
  getDistinctUserIdsFromAuditTracksError,
  getDistinctUserIdsFromAuditTracksSuccess,
  getForwardingCaseDetail,
  showBeneficiaryImageError,
  showBeneficiaryImageSuccess,
  showOriginatorImageError,
  showOriginatorImageSuccess,
  updateCaseStatusForcefullyError,
  updateCaseStatusForcefullySuccess,
  updateSanctionHitValidMatchesError,
  updateSanctionHitValidMatchesSuccess,
} from 'middleware/actions/case';

import { hideError, retryErrorBegan, showError } from 'middleware/actions/error';

import { showNotification } from 'middleware/actions/notification';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';

import {
  deletePepSanctionInstanciableConfigSuccess,
  getInstanciablePepSanctionConfigError,
  getInstanciablePepSanctionConfigSuccess,
  getPepSanctionConfigError,
  getPepSanctionConfigSuccess,
  getPepSanctionRegions,
  getPepSanctionRegionsError,
  getPepSanctionRegionsSuccess,
  getRegionSanctionListsError,
  getRegionSanctionListsSuccess,
  getSanctionListLastUpdateError,
  getSanctionListLastUpdateSuccess,
  getSanctionListsError,
  getSanctionListsSuccess,
  saveInstanciablePepSanctionConfigError,
  saveInstanciablePepSanctionConfigSuccess,
  saveInstanciablePepSanctionProviderError,
  saveInstanciablePepSanctionProviderSuccess,
  savePepSanctionConfigError,
  savePepSanctionConfigSuccess,
} from 'middleware/actions/sanction-list';
import {
  deleteApiKeyWatcher,
  getApiKeyConfigWatcher,
} from 'modules/configuration/api-key-config/middleware/sagas/api-key-config-saga';
import {
  deleteSftpConfigWatcher,
  getSftpConfigWatcher,
  updateSftpConfigWatcher,
  getSftpConfigUnmaskedWatcher,
} from 'modules/configuration/settings/sftp-config/middleware/sagas/sftp-config';
import {
  deleteCaseNotificationsConfigWatcher,
  getCaseNotificationsConfigWatcher,
  updateCaseNotificationsConfigWatcher,
} from 'modules/configuration/workflows-and-users/components/case-notification/middleware/sagas/case-notifications-config';
import {
  getTenantConfig,
  getTenantConfigError,
  getTenantConfigSuccess,
  putTenantConfigError,
  putTenantConfigSuccess,
} from 'modules/configuration/workflows-and-users/middleware/actions/tenant-config';
import { TENANT_CONFIG_ACTIONS } from 'modules/configuration/workflows-and-users/middleware/action-types';

import { appendTimestamp } from 'utils/helpers/date-time-util';

import {
  AuditTrackActions,
  CaseActions,
  ErrorActions,
  ImageActions,
  PEP_SANCTION_CONFIG_ACTIONS,
  PepSanctionConfigActions,
  SanctionListActions,
} from 'middleware/action-types';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  deleteAccountBasedFrequencyConfigWatcher,
  getAccountBasedFrequencyConfigWatcher,
  updateAccountBasedFrequencyConfigWatcher,
} from 'modules/configuration/rule-manager/account-based-frequency/middleware/sagas/account-based-frequency-config';

import {
  deleteAccountBasedFrequencyIncreaseDecreaseConfigWatcher,
  getAccountBasedFrequencyIncreaseDecreaseConfigWatcher,
  updateAccountBasedFrequencyIncreaseDecreaseConfigWatcher,
} from 'modules/configuration/rule-manager/account-based-frequency-increase-decrease/middleware/sagas/account-based-frequency-increase-decrease-config';

import {
  getAssessmentEngineConfigWatcher,
  updateAssessmentEngineConfigWatcher,
} from 'middleware/sagas/assessment-engine-config';

import {
  databaseLoginWatcher,
  getAuth0UserPermissionsWatcher,
  getDetachmentConfigWatcher,
  getPlatformViewingConfigWatcher,
  getUserListWatcher,
  resetCacheWatcher,
  ssoLoginWatcher,
} from 'modules/authentication/login/middleware/sagas/auth-saga';
import {
  addCaseToGroupWatcher,
  breakOutCaseFromGroupBeginWatcher,
  getCaseCollectionByIdsWatcher,
  getCaseLast4MonthsTxHeatmapImageWatcher,
  getCaseLast4MonthsTxMetadataWatcher,
  getCustomerPreviousAlertsWatcher,
  getCustomerProfileBalancesWatcher,
  getTransactionsV2Watcher,
  getTransactionsWatcher,
} from 'modules/trx-cases/case-detail/middleware/sagas/case';
import { getCaseDetailConfigWatcher } from 'modules/trx-cases/case-detail/middleware/sagas/case-config';
import { getArticleBodyWatcher } from 'middleware/sagas/confluence';
import {
  deleteCounterPartyFrequencyConfigWatcher,
  getCounterPartyFrequencyConfigWatcher,
  updateCounterPartyFrequencyConfigWatcher,
} from 'modules/configuration/rule-manager/counter-party-frequency/middleware/sagas/counter-party-frequency-config';
import {
  getCountryRiskConfigWatcher,
  updateCountryRiskConfigWatcher,
} from 'modules/configuration/rule-manager/country-config/middleware/sagas/country-risk-config';

import {
  deleteCrossBorderVolumeConfigWatcher,
  getCrossBorderVolumeConfigWatcher,
  updateCrossBorderVolumeConfigWatcher,
} from 'modules/configuration/rule-manager/cross-border-volume/middleware/sagas/cross-border-volume-config';

import { getCustomerDetailsWatcher } from 'middleware/sagas/customer-details';

import { getCustomerCasesWatcher } from 'modules/customer-cases/case-list/middleware/sagas/customer-case';
import { getCaseDetailWatcher as getCustomerCaseDetailWatcher } from 'modules/customer-cases/case-detail/middleware/sagas/customer-case-detail';

import { updateCaseStateWatcher as updateCaseStateCustomerWatcher } from 'modules/customer-cases/case-detail/middleware/sagas/case-state';

import {
  createRiskFactorWatcher,
  deleteRiskFactorWatcher,
  getCustomerRiskRatingConfigForIndividualWatcher,
  getCustomerRiskRatingConfigWatcher,
  updateCustomerRiskRatingConfigForIndividualWatcher,
  updateCustomerRiskRatingConfigWatcher,
} from 'middleware/sagas/customer-risk-rating-config';
import {
  getDecisionEngineConfigWatcher,
  updateDecisionEngineConfigWatcher,
} from 'middleware/sagas/decision-engine-config';

import {
  getDetachmentConfigUtilWatcher,
  insertDetachmentConfigUtilWatcher,
  resetDetachmentConfigUtilWatcher,
  updateDetachmentConfigUtilWatcher,
} from 'middleware/sagas/detachment-config';

import {
  deleteDormantAccountConfigWatcher,
  getDormantAccountConfigWatcher,
  updateDormantAccountConfigWatcher,
} from 'modules/configuration/rule-manager/dormant-account/middleware/sagas/dormant-account-config';

import {
  getGoAMLXMLWatcher,
  saveGoAMLXMLWatcher,
  validateGoAMLXMLWatcher,
} from 'middleware/sagas/goaml';
import {
  unmaskWatcher,
  unveilCounterpartyDataWatcher,
  unveilMasterDataWatcher,
  unveilSanctionHitNamesWatcher,
} from 'middleware/sagas/mask';

import {
  activateMccFileListWatcher,
  activateUpdateActiveMccListVersionWatcher,
  getMccFileListWatcher,
  updateActiveMccListVersionWatcher,
} from 'middleware/sagas/mcc-list';

import {
  activateOccupationFileListWatcher,
  activateUpdateActiveOccupationListVersionWatcher,
  getOccupationFileListWatcher,
  updateActiveOccupationListVersionWatcher,
} from 'middleware/sagas/occupation-list';

import {
  activateProductTypeFileListIndividualWatcher,
  activateProductTypeFileListOrganizationWatcher,
  activateUpdateActiveProductTypeListVersionWatcherIndividual,
  activateUpdateActiveProductTypeListVersionWatcherOrganization,
  getProductTypeFileListIndividualWatcher,
  getProductTypeFileListOrganizationWatcher,
  updateActiveProductTypeListVersionIndividualWatcher,
  updateActiveProductTypeListVersionOrganizationWatcher,
} from 'middleware/sagas/product-type-list';

import {
  getNetworkAnalysisWatcher,
  getPassthroughNetworkWatcher,
  getPassthroughV2NetworkWatcher,
  getPassthroughV3NetworkWatcher,
} from 'middleware/sagas/network-analysis';

import {
  getPassthroughConfigWatcher,
  updatePassthroughConfigWatcher,
} from 'modules/configuration/rule-manager/passthrough/middleware/sagas/passthrough-config';

import {
  deleteRoundAmountConfigWatcher,
  getRoundAmountConfigWatcher,
  updateRoundAmountConfigWatcher,
} from 'modules/configuration/rule-manager/round-amount/middleware/sagas/round-amount-config';

import {
  deleteRoundAmountRatioIncreaseDecreaseConfigWatcher,
  getRoundAmountRatioIncreaseDecreaseConfigWatcher,
  updateRoundAmountRatioIncreaseDecreaseConfigWatcher,
} from 'modules/configuration/rule-manager/round-amount-ratio-increase-decrease/middleware/sagas/round-amount-ratio-increase-decrease-config';
import {
  deleteSettingsCountryRisksWatcher,
  getSettingsWatcher,
  updateSettingsCountryRisksWatcher,
  updateSettingsWatcher,
} from 'middleware/sagas/settings';
import {
  deleteSmurfingConfigWatcher,
  getSmurfingV2ConfigWatcher,
  updateSmurfingV2ConfigWatcher,
} from 'modules/configuration/rule-manager/smurfing/middleware/sagas/smurfing-config';

import {
  deleteSuspiciousAvgTicketSizeConfigWatcher,
  getSuspiciousAvgTicketSizeConfigWatcher,
  updateSuspiciousAvgTicketSizeConfigWatcher,
} from 'modules/configuration/rule-manager/suspicious-avg-ticket-size/middleware/sagas/suspicious-avg-ticket-size-config';

import {
  addMasterTenantWatcher,
  addTenantToUsersWatcher,
  addTenantWatcher,
  getAllTenantsWatcher,
  getMasterTenantCreationStatusWatcher,
  getMasterTenantsWatcher,
  getTenantCallbackConfigSecretWatcher,
  getTenantScorechainConfigSecretWatcher,
  getUsersNotInTenantWatcher,
  getUsersWatcher,
  putTenantCallbackConfigWatcher,
  putTenantScorechainConfigWatcher,
  updateTenantStructureWatcher,
  updateUserWatcher,
} from 'modules/configuration/workflows-and-users/middleware/sagas/tenant-manager';

import {
  executeReprocessCasesWatcher,
  postReprocessCaseWatcher,
  queryReprocessCasesWatcher,
} from 'middleware/sagas/tooling';

import {
  deleteVolumeIncreaseDecreaseConfigWatcher,
  getVolumeIncreaseDecreaseConfigWatcher,
  updateVolumeIncreaseDecreaseConfigWatcher,
} from 'modules/configuration/rule-manager/volume-increase-decrease/middleware/sagas/volume-increase-decrease-config';
import { getVolumeRiskConfigWatcher } from 'middleware/sagas/volume-risk-config';
import SessionService from 'modules/authentication/login/services/session-service';
import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';
import SanctionListAPICommunicator from 'services/sanction-list-api-communicator';
import SanctionListManager from 'services/sanction-list-manager';
import TenantConfigAPICommunicator from 'modules/configuration/workflows-and-users/services/tenant-config-api-communicator';
import TenantConfigManager from 'modules/configuration/workflows-and-users/services/tenant-config-manager';
import {
  deleteVolumeRiskV2ConfigWatcher,
  getVolumeRiskV2ConfigBeginWatcher,
  updateVolumeRiskV2ConfigWatcher,
} from 'modules/configuration/rule-manager/volume-config-v2/middleware/sagas/volume-risk-v2-config';
import {
  deleteCountryRiskV2ConfigWatcher,
  getCountryRiskV2ConfigBeginWatcher,
  updateCountryRiskV2ConfigWatcher,
} from 'modules/configuration/rule-manager/country-config-v2/middleware/sagas/country-risk-v2-config';
import {
  getHidtaHifcaFileListWatcher,
  updateActiveHidtaHifcaListVersionWatcher,
} from './hidta-hifca-list';
import {
  deletePassthroughV2ConfigWatcher,
  getPassthroughV2ConfigWatcher,
  updatePassthroughV2ConfigWatcher,
} from 'modules/configuration/rule-manager/passthrough-v2/middleware/sagas/passthrough-v2-config';

import {
  deletePassthroughV3ConfigWatcher,
  getPassthroughV3ConfigWatcher,
  updatePassthroughV3ConfigWatcher,
} from 'modules/configuration/rule-manager/passthrough-v3/middleware/sagas/passthrough-v3-config';

import {
  deleteRiskyWordsV2ConfigWatcher,
  getRiskyWordsV2ConfigWatcher,
  updateRiskyWordsV2ConfigWatcher,
} from 'modules/configuration/rule-manager/risky-words-v2/middleware/sagas/risky-words-v2-config';

import {
  getRiskyWordsV2FileListWatcher,
  updateActiveRiskyWordsV2ListVersionWatcher,
} from 'middleware/sagas/risky-words-v2-list';

import {
  getFiltersFileListWatcher,
  updateActiveFiltersListVersionWatcher,
} from 'middleware/sagas/filters-list';

import {
  deleteCounterPartyVolumeConfigWatcher,
  getCounterPartyVolumeConfigWatcher,
  updateCounterPartyVolumeConfigWatcher,
} from 'modules/configuration/rule-manager/counter-party-volume/middleware/sagas/counter-party-volume-config';
import {
  addCaseNoteWatcher,
  downloadCaseNoteAttachmentWatcher,
  getDistinctUserIdsFromNotesWatcher,
} from 'modules/trx-cases/case-detail/middleware/sagas/case-notes';
import {
  addCustomerCaseNoteWatcher,
  downloadCaseNoteAttachmentCCWatcher,
} from 'modules/customer-cases/case-detail/middleware/sagas/case-note';
import { requestResetPasswordWatcher } from 'modules/authentication/reset-password/middleware/sagas/reset-password-saga';

import { getAllAuditTrailsWatcher } from 'modules/audit-trail/middleware/sagas/audit-trail';
import {
  getGroupsCasesPerformanceWatcher,
  getGroupsFalsePositivePatternWatcher,
  getGroupsFalsePositiveRateWatcher,
  getGroupsTruePositivePatternWatcher,
  getRiskyWordsPatternWatcher,
} from 'modules/dashboard/efficiency/middleware/sagas/efficiency-saga';
import {
  getGroupsCountMetricWatcher,
  getGroupsHealthMetricWatcher,
  getGroupsTypeCountMetricWatcher,
} from 'modules/dashboard/case-statistic/middleware/sagas/case-statistics-saga';

import { assessPepSanctionWatcher } from 'modules/customer-cases/case-detail/middleware/sagas/pep-sanction';

import { stopGroupingWatcher } from 'modules/trx-cases/case-detail/middleware/sagas/case-grouping';
import { requestManualRiskLevelChangeWatcher } from 'modules/customer-cases/case-detail/middleware/sagas/manual-risk-level-change';
import {
  getCaseDetailWatcher,
  getCasesByForwardingToCaseIdWatcher,
  getForwardingCaseDetailWatcher,
} from 'modules/trx-cases/case-detail/middleware/sagas/case-detail';
import {
  assignCaseToOperatorWatcher,
  getCaseStateChangesWatcher,
  updateCaseStateWatcher,
} from 'modules/trx-cases/case-detail/middleware/sagas/case-state';
import {
  deleteBlacklistConfigWatcher,
  getBlacklistConfigWatcher,
  updateBlacklistConfigWatcher,
} from 'middleware/sagas/blacklist-config';
import {
  assessCountryRiskWatcher,
  assessLabelWatcher,
  assessVolumeRiskWatcher,
  assessWatcher,
} from 'modules/trx-cases/case-detail/middleware/sagas/case-assessment';
import { getRuleCategoriesWatcher } from 'middleware/sagas/rule-category';

import { getAllCasesWatcher } from 'modules/trx-cases/case-list/middleware/sagas/case-list';
import {
  deletePlausibilityConfigWatcher,
  getPlausibilityConfigWatcher,
  updatePlausibilityConfigWatcher,
} from 'middleware/sagas/config/plausibility';
import {
  deleteReferenceAccountV2ConfigWatcher,
  getReferenceAccountV2ConfigWatcher,
  updateReferenceAccountV2ConfigWatcher,
} from 'modules/configuration/rule-manager/reference-account-v2/middleware/sagas/reference-account-v2';
import {
  getBlacklistFileListWatcher,
  updateActiveBlacklistListVersionWatcher,
} from 'middleware/sagas/blacklist-list-config';
import {
  deleteFiltersInstantiableConfigListConfigWatcher,
  getFiltersInstantiableListConfigWatcher,
  updateFiltersInstantiableConfigListConfigWatcher,
} from 'modules/configuration/list-manager/filters-instantiable-list/middleware/sagas/filters-instantiable-list-config';
import {
  deleteRiskyWordsInstantiableConfigListConfigWatcher,
  getRiskyWordsInstantiableListConfigWatcher,
  updateActiveRiskyWordInstantiableListConfigVersionWatcher,
  updateRiskyWordsInstantiableConfigListConfigWatcher,
} from 'modules/configuration/list-manager/risky-words-instantiable-list/middleware/sagas/risky-words-instantiable-list-config';
import {
  deleteBlacklistInstantiableListConfigWatcher,
  getBlacklistInstantiableListConfigWatcher,
  updateActiveBlacklistInstantiableListConfigVersionWatcher,
  updateBlacklistInstantiableListConfigWatcher,
} from 'modules/configuration/list-manager/blacklist-instantiable-list/middleware/sagas/blacklist-instantiable-list-config';
import {
  deleteExpectedCustomerValueConfigWatcher,
  getExpectedCustomerValueConfigWatcher,
  updateExpectedCustomerValueConfigWatcher,
} from 'modules/configuration/rule-manager/expected-customer-value/middleware/sagas/expected-customer-value-config';
import { getTransactionFieldsWatcher } from 'middleware/sagas/config/transaction-fields';

import {
  deleteAmlRiskScreeningConfigWatcher,
  getAmlRiskScreeningConfigWatcher,
  saveAmlRiskScreeningConfigWatcher,
  getAmlRiskScreeningImportStatusWatcher,
} from 'middleware/sagas/aml-risk-screening-config';
import {
  deleteFlaggingConfigWatcher,
  getFlaggingConfigWatcher,
  updateFlaggingConfigWatcher,
} from 'modules/configuration/rule-manager/flagging/middleware/sagas/flagging-config';
import { getListForRiskFactorWatcher } from 'modules/configuration/customer-risk-rating/middleware/sagas/risk-level-map';
import {
  deleteRuleBuilderConfigWatcher,
  getRuleBuilderConfigWatcher,
  saveRuleBuilderConfigWatcher,
} from 'modules/configuration/rule-manager/common/rule-builder/middleware/sagas/rule-builder';

import {
  deleteDataProviderListConfigWatcher,
  getDataProviderListConfigWatcher,
  saveDataProviderListConfigWatcher,
} from 'modules/configuration/list-manager/data-provider-list/sagas/data-provider-list-config-saga';
import {
  getScreeningListWatcher,
  getScreeningListAttributesWatcher,
} from 'modules/configuration/list-manager/data-provider-list/sagas/screening-list-saga';
import { getDataProviderAuditTrailWatcher } from 'modules/configuration/list-manager/data-provider-list/sagas/data-provider-audit-trail-saga';

import { getCustomersWatcher } from 'modules/customer-data/customer-list/middleware/sagas/customer-list';
import {
  getAllCustomerCasesWatcher,
  getAllTransactionCasesWatcher,
  getCustomerDetailWatcher,
  getMoreCustomerCasesWatcher,
  getMoreTransactionCasesWatcher,
} from 'modules/customer-data/customer-detail/middleware/sagas/customer-detail';
import {
  deleteFanInConfigWatcher,
  getFanInConfigWatcher,
  saveFanInConfigWatcher,
} from 'modules/configuration/machine-intelligence/fan-in/middleware/sagas/fan-in';
import {
  copyInstanceWatcher,
  getEligibleTenantsWatcher,
} from 'modules/configuration/config-propagation/middleware/saga/config-propagation-saga';
import {
  getBehavioralAnalysisConfigWatcher,
  saveBehavioralAnalysisConfigWatcher,
  deleteBehavioralAnalysisConfigWatcher,
} from 'modules/configuration/machine-intelligence/behavioral-analysis/middleware/sagas/behavioral-analysis';
import { putTenantUserManagementConfigWatcher } from 'modules/configuration/workflows-and-users/components/user-management/middleware/sagas/user-management';
import {
  getEntityResolutionConfigWatcher,
  saveEntityResolutionConfigWatcher,
  getEntityResolutionProfileWatcher,
  deleteEntityResolutionProfileWatcher,
} from 'modules/entity-resolution/middleware/entity-resolution-saga';

const caseCommunicator = new CaseAPICommunicator();
const caseManager = new CaseManager(caseCommunicator);
const tenantConfigCommunicator = new TenantConfigAPICommunicator();
const tenantConfigManager = new TenantConfigManager(tenantConfigCommunicator);
const sanctionListCommunicator = new SanctionListAPICommunicator();
const sanctionListManager = new SanctionListManager(sanctionListCommunicator);
const auth = new SessionService();

function* getAuditTracksBegin(action) {
  try {
    yield put(startProcessing(AuditTrackActions.AUDIT_TRACK_GET));

    const json = yield caseManager.queryAuditTracks(
      action.page === 0 ? action.size.initial : action.size.requestMore,
      action.page,
      action.sortParams,
      action.filterColumn,
      action.filterValue,
      action.filterOperator,
      action.filterStartDate,
      action.filterEndDate,
      action.excludeSystemUser,
    );
    yield put(getAuditTracksSuccess(json.data));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(AuditTrackActions.AUDIT_TRACK_GET));
  } catch (error) {
    yield put(getAuditTracksError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(AuditTrackActions.AUDIT_TRACK_GET));
  }
}

function* getAuditTracksWatcher() {
  yield takeEvery(AuditTrackActions.AUDIT_TRACK_GET, getAuditTracksBegin);
}

function* getDistinctCurrencyValuesByCaseStatus(action) {
  try {
    yield put(startProcessing(CaseActions.CURRENCY_GET));

    const json = yield caseManager.queryDistinctCurrencyValuesByCaseStatus(action.caseStatus);
    yield put(getDistinctCurrencyValuesByCaseStatusSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(CaseActions.CURRENCY_GET));
  } catch (error) {
    yield put(getDistinctCurrencyValuesByCaseStatusError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(CaseActions.CURRENCY_GET));
  }
}

function* getDistinctCurrencyValuesByCaseStatusWatcher() {
  yield takeLatest(CaseActions.CURRENCY_GET, getDistinctCurrencyValuesByCaseStatus);
}

function* updateSanctionHitValidMatchesBegin(action) {
  yield put(startProcessing(CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE));

  const { payload } = action;
  const requestId = payload.sanctionOperatorMatchRequestId;
  payload.operatorId = auth.getUsername();

  const body = {
    questionId: payload.questionId,
    answer: payload.sanctionMatchType,
    userId: payload.operatorId,
    party: payload.party,
    sourceField: payload.sourceField,
    provider: payload.provider,
    providerId: payload.idAtProvider,
    lastProviderUpdate: appendTimestamp(payload.lastUpdatedAtProvider),
    processedAt: payload.processedAt,
    holderNameToken: payload.holderNameToken,
    accountNumberToken: payload.accountNumberToken,
    instanceId: payload.instanceId,
  };

  try {
    const json = yield caseManager.assessSanctionHit(payload.caseId, body);
    yield put(updateSanctionHitValidMatchesSuccess(json, requestId));

    const uuid = payload.caseId;
    if (uuid === payload.masterUuid) {
      yield put(getCaseDetail(uuid, false));
    } else {
      yield put(getForwardingCaseDetail(uuid));
    }

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(updateSanctionHitValidMatchesError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE));
}

function* updateSanctionHitValidMatchesWatcher() {
  yield takeLatest(
    CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE,
    updateSanctionHitValidMatchesBegin,
  );
}

function* retryErrorBegin(action) {
  const error = action.payload;
  // Chain it in order to hide it if retry success
  error.action.linkedError = { code: error.code };

  yield put(retryErrorBegan(error));

  yield put(error.action);
}

function* getDistinctUserIdsFromAuditTracks(action) {
  try {
    yield put(startProcessing(AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID));

    const json = yield caseManager.queryDistinctUserIdsFromAuditTracks();
    yield put(getDistinctUserIdsFromAuditTracksSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID));
  } catch (error) {
    yield put(getDistinctUserIdsFromAuditTracksError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID));
  }
}

function* getDistinctUserIdsFromAuditTracksWatcher() {
  yield takeLatest(
    AuditTrackActions.GET_AUDIT_TRACK_UNIQUE_USER_ID,
    getDistinctUserIdsFromAuditTracks,
  );
}

export function* getTenantConfigBegin(action) {
  try {
    yield put(startProcessing(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET));

    const json = yield tenantConfigManager.fetchTenantConfig();
    yield put(getTenantConfigSuccess(json));

    if (action?.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET));
  } catch (error) {
    yield put(getTenantConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET));
  }
}

function* getTenantConfigWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_GET, getTenantConfigBegin);
}

function* saveTenantConfigBegin(action) {
  const { payload } = action;

  try {
    yield put(startProcessing(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT));

    const json = yield tenantConfigManager.saveTenantConfig(payload);
    yield put(putTenantConfigSuccess(json));
    yield put(showNotification({ message: 'Configuration successfully stored!' }));

    yield put(getTenantConfig());

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT));
  } catch (error) {
    yield put(putTenantConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT));
  }
}

function* saveTenantConfigWatcher() {
  yield takeLatest(TENANT_CONFIG_ACTIONS.TENANT_CONFIG_PUT, saveTenantConfigBegin);
}

function* retryErrorWatcher() {
  yield takeLatest(ErrorActions.ERRORS_RETRY, retryErrorBegin);
}

function* addAuditTrackBegin(action) {
  const payload = action.payload || {};
  const { uuid } = payload;
  try {
    yield put(startProcessing(AuditTrackActions.REQUEST_ADD_AUDIT_TRACK));

    const json = yield caseManager.addAuditTrack(
      uuid,
      payload.userId,
      payload.message,
      payload.event,
      payload.caseStateChange,
    );
    yield put(addAuditTrackSuccess(json));

    if (payload.reloadCaseId) {
      if (payload.reloadCaseId === payload.masterUuid) {
        yield put(getCaseDetail(payload.reloadCaseId, false));
      } else {
        yield put(getForwardingCaseDetail(payload.reloadCaseId));
      }
    }

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(AuditTrackActions.REQUEST_ADD_AUDIT_TRACK));
  } catch (error) {
    yield put(addAuditTrackError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(AuditTrackActions.REQUEST_ADD_AUDIT_TRACK));
  }
}

function* addAuditTrackWatcher() {
  yield takeLatest(AuditTrackActions.REQUEST_ADD_AUDIT_TRACK, addAuditTrackBegin);
}

function* getSanctionListsBegin(action) {
  try {
    yield put(startProcessing(SanctionListActions.GET_SANCTION_LIST));

    const json = yield sanctionListManager.queryReferenceNames(
      action.size,
      action.page,
      action.sortParams,
      action.filterColumn,
      action.filterValue,
      action.filterOperator,
      action.filterStartDate,
      action.filterEndDate,
    );
    yield put(getSanctionListsSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(SanctionListActions.GET_SANCTION_LIST));
  } catch (error) {
    yield put(getSanctionListsError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(SanctionListActions.GET_SANCTION_LIST));
  }
}

function* getSanctionListsWatcher() {
  yield takeLatest(SanctionListActions.GET_SANCTION_LIST, getSanctionListsBegin);
}

function* getSanctionListsLastUpdateBegin(action) {
  try {
    yield put(startProcessing(SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE));

    const json = yield sanctionListManager.querySanctionListLastUpdate();
    yield put(getSanctionListLastUpdateSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE));
  } catch (error) {
    yield put(getSanctionListLastUpdateError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE));
  }
}

function* getSanctionListsLastUpdateWatcher() {
  yield takeLatest(
    SanctionListActions.GET_SANCTION_LIST_LAST_UPDATE,
    getSanctionListsLastUpdateBegin,
  );
}

function* getPepSanctionConfigBegin(action) {
  try {
    yield put(startProcessing(PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG));

    const json = yield sanctionListManager.queryPepSanctionConfig();
    yield put(getPepSanctionConfigSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG));
  } catch (error) {
    yield put(getPepSanctionConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG));
  }
}

function* getInstanciablePepSanctionConfigBegin(action) {
  try {
    yield put(startProcessing(PEP_SANCTION_CONFIG_ACTIONS.instanciable.get));

    const json = yield sanctionListManager.queryInstanciablePepSanctionConfig();
    yield put(getInstanciablePepSanctionConfigSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(PEP_SANCTION_CONFIG_ACTIONS.instanciable.get));
  } catch (error) {
    yield put(getInstanciablePepSanctionConfigError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PEP_SANCTION_CONFIG_ACTIONS.instanciable.get));
  }
}

function* getPepSanctionConfigMatchWatcher() {
  yield takeLatest(PepSanctionConfigActions.GET_PEP_SANCTION_CONFIG, getPepSanctionConfigBegin);
}

function* getInstanciablePepSanctionConfigMatchWatcher() {
  yield takeLatest(
    PEP_SANCTION_CONFIG_ACTIONS.instanciable.get,
    getInstanciablePepSanctionConfigBegin,
  );
}

function* getPepSanctionRegionsBegin(action) {
  try {
    yield put(startProcessing(PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS));

    const json = yield sanctionListManager.queryPepSanctionRegions();
    yield put(getPepSanctionRegionsSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS));
  } catch (error) {
    yield put(getPepSanctionRegionsError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS));
  }
}

function* getPepSanctionRegionsWatcher() {
  yield takeLatest(PepSanctionConfigActions.GET_PEP_SANCTION_REGIONS, getPepSanctionRegionsBegin);
}

function* getRegionSanctionListsBegin(action) {
  yield put(startProcessing(PepSanctionConfigActions.GET_REGION_SANCTION_LISTS));
  try {
    const json = yield sanctionListManager.queryRegionSanctionLists(
      action.size,
      action.page,
      action.sortField,
      action.sortDirection,
      action.filters,
    );
    yield put(getRegionSanctionListsSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(getRegionSanctionListsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(PepSanctionConfigActions.GET_REGION_SANCTION_LISTS));
}

function* getRegionSanctionListsWatcher() {
  yield takeEvery(PepSanctionConfigActions.GET_REGION_SANCTION_LISTS, getRegionSanctionListsBegin);
}

function* showBeneficiaryImageBegin(action) {
  const { payload } = action;

  try {
    yield put(startProcessing(ImageActions.SHOW_BENEFICIARY_IMAGE));

    yield put(showBeneficiaryImageSuccess(payload));

    const noteString = `Unveiled image of '${payload.account}'`;

    yield put(
      addAuditTrack(payload.masterUuid, payload.uuid, payload.userId, noteString, payload.event),
    );

    yield put(showNotification({ message: noteString }));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(ImageActions.SHOW_BENEFICIARY_IMAGE));
  } catch (error) {
    yield put(showBeneficiaryImageError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(ImageActions.SHOW_BENEFICIARY_IMAGE));
  }
}

function* showBeneficiaryImageWatcher() {
  yield takeLatest(ImageActions.SHOW_BENEFICIARY_IMAGE, showBeneficiaryImageBegin);
}

function* showOriginatorImageBegin(action) {
  const { payload } = action;

  try {
    yield put(startProcessing(ImageActions.SHOW_ORIGINATOR_IMAGE));

    yield put(showOriginatorImageSuccess(payload));

    const noteString = `Unveiled image of '${payload.account}'`;

    yield put(
      addAuditTrack(payload.masterUuid, payload.uuid, payload.userId, noteString, payload.event),
    );

    yield put(showNotification({ message: noteString }));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }

    yield put(stopProcessing(ImageActions.SHOW_ORIGINATOR_IMAGE));
  } catch (error) {
    yield put(showOriginatorImageError());
    yield put(showError({ ...error, action }));
    yield put(stopProcessing(ImageActions.SHOW_ORIGINATOR_IMAGE));
  }
}

function* showOriginatorImageWatcher() {
  yield takeLatest(ImageActions.SHOW_ORIGINATOR_IMAGE, showOriginatorImageBegin);
}

function* addStandaloneAuditTrackBegin(action) {
  yield put(startProcessing(AuditTrackActions.ADD_STAND_ALONE_AUDIT_TRACK));

  const { userId, message, event, sectionId } = action.payload ?? {};

  try {
    const json = yield caseManager.addStandaloneAuditTrack(userId, message, event, sectionId);
    yield put(addStandaloneAuditTrackSuccess(json));

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(addStandaloneAuditTrackError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(AuditTrackActions.ADD_STAND_ALONE_AUDIT_TRACK));
}

function* addStandaloneAuditTrackWatcher() {
  yield takeLatest(AuditTrackActions.ADD_STAND_ALONE_AUDIT_TRACK, addStandaloneAuditTrackBegin);
}

function* updateCaseStatusBegin(action) {
  yield put(startProcessing(CaseActions.CASE_STATUS_FORCEFUL_UPDATE));
  try {
    const { payload } = action;
    const { caseId, tenantId } = payload;
    const json = yield caseManager.updateCaseStatus(payload);
    yield put(updateCaseStatusForcefullySuccess(json));

    yield put(getCaseStateChanges(caseId, tenantId));
  } catch (error) {
    yield put(updateCaseStatusForcefullyError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CaseActions.CASE_STATUS_FORCEFUL_UPDATE));
}

function* updateCaseStatusBeginWatcher() {
  yield takeLatest(CaseActions.CASE_STATUS_FORCEFUL_UPDATE, updateCaseStatusBegin);
}

function* savePepSanctionConfigBegin(action) {
  yield put(startProcessing(PEP_SANCTION_CONFIG_ACTIONS.update.data));
  try {
    const params = action?.payload.params ?? {};
    const json = yield sanctionListManager.savePepSanctionConfig(params);

    yield put(savePepSanctionConfigSuccess(json));

    // There could be a change in list regions, such as add/remove Custom List
    yield put(getPepSanctionRegions());

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(savePepSanctionConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(PEP_SANCTION_CONFIG_ACTIONS.update.data));
}

function* savePepSanctionConfigWatcher() {
  yield takeLatest(PEP_SANCTION_CONFIG_ACTIONS.update.data, savePepSanctionConfigBegin);
}

function* saveInstanciablePepSanctionConfigBegin(action) {
  yield put(startProcessing(PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.data));
  try {
    const payload = action?.payload ?? {};
    const json = yield sanctionListManager.saveInstanciablePepSanctionConfig(payload);

    yield put(saveInstanciablePepSanctionConfigSuccess(json));

    // There could be a change in list regions, such as add/remove Custom List
    yield put(getPepSanctionRegions());

    if (action.linkedError) {
      yield put(hideError(action.linkedError));
    }
  } catch (error) {
    yield put(saveInstanciablePepSanctionConfigError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.data));
}

function* saveInstanciablePepSanctionConfigWatcher() {
  yield takeLatest(
    PEP_SANCTION_CONFIG_ACTIONS.updateInstanciable.data,
    saveInstanciablePepSanctionConfigBegin,
  );
}

function* deletePepSanctionInstanciableConfigBegin(action) {
  yield put(startProcessing(PEP_SANCTION_CONFIG_ACTIONS.deleteInstanciable.send));

  try {
    const json = yield sanctionListManager.deletePepSanctionInstanciableConfig(action.payload);
    yield put(deletePepSanctionInstanciableConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PEP_SANCTION_CONFIG_ACTIONS.deleteInstanciable.send));
}

export function* deletePepSanctionInstanciableConfigWatcher() {
  yield takeLatest(
    PEP_SANCTION_CONFIG_ACTIONS.deleteInstanciable.send,
    deletePepSanctionInstanciableConfigBegin,
  );
}

function* updatePepSanctionInstanciableProviderBegin(action) {
  yield put(startProcessing(PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.data));

  try {
    const json = yield sanctionListManager.updatePepSanctionInstanciableProvider(action.payload);
    yield put(saveInstanciablePepSanctionProviderSuccess(json));
  } catch (error) {
    yield put(saveInstanciablePepSanctionProviderError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.data));
}

export function* updatePepSanctionInstanciableProviderWatcher() {
  yield takeLatest(
    PEP_SANCTION_CONFIG_ACTIONS.updateInstanciableProvider.data,
    updatePepSanctionInstanciableProviderBegin,
  );
}

export default function* rootSaga() {
  yield all([
    getAllCasesWatcher(),
    getCasesByForwardingToCaseIdWatcher(),
    getCaseDetailWatcher(),
    getForwardingCaseDetailWatcher(),
    getCaseStateChangesWatcher(),
    updateCaseStateWatcher(),
    addCaseNoteWatcher(),
    getDistinctCurrencyValuesByCaseStatusWatcher(),
    updateSanctionHitValidMatchesWatcher(),
    unmaskWatcher(),
    unveilSanctionHitNamesWatcher(),
    retryErrorWatcher(),
    getDistinctUserIdsFromNotesWatcher(),
    stopGroupingWatcher(),
    downloadCaseNoteAttachmentWatcher(),
    getTenantConfigWatcher(),
    saveTenantConfigWatcher(),
    addAuditTrackWatcher(),
    getSanctionListsWatcher(),
    getAuditTracksWatcher(),
    getDistinctUserIdsFromAuditTracksWatcher(),
    getSanctionListsLastUpdateWatcher(),
    getPepSanctionConfigMatchWatcher(),
    getInstanciablePepSanctionConfigMatchWatcher(),
    getCountryRiskConfigWatcher(),
    updateCountryRiskConfigWatcher(),
    getAssessmentEngineConfigWatcher(),
    updateAssessmentEngineConfigWatcher(),
    showBeneficiaryImageWatcher(),
    showOriginatorImageWatcher(),
    getVolumeRiskConfigWatcher(),
    assessVolumeRiskWatcher(),
    assessCountryRiskWatcher(),
    assessLabelWatcher(),
    assessWatcher(),
    getTransactionsWatcher(),
    getTransactionsV2Watcher(),
    getPepSanctionRegionsWatcher(),
    getRegionSanctionListsWatcher(),
    savePepSanctionConfigWatcher(),
    saveInstanciablePepSanctionConfigWatcher(),
    addStandaloneAuditTrackWatcher(),
    getDecisionEngineConfigWatcher(),
    updateDecisionEngineConfigWatcher(),
    getNetworkAnalysisWatcher(),
    getPassthroughNetworkWatcher(),
    getPassthroughV2NetworkWatcher(),
    getPassthroughV3NetworkWatcher(),
    getAuth0UserPermissionsWatcher(),
    getUserListWatcher(),
    assignCaseToOperatorWatcher(),
    resetCacheWatcher(),
    getDetachmentConfigWatcher(),
    getPlatformViewingConfigWatcher(),
    updateDetachmentConfigUtilWatcher(),
    insertDetachmentConfigUtilWatcher(),
    getDetachmentConfigUtilWatcher(),
    resetDetachmentConfigUtilWatcher(),
    databaseLoginWatcher(),
    ssoLoginWatcher(),
    requestResetPasswordWatcher(),
    getCustomerPreviousAlertsWatcher(),
    getCustomerProfileBalancesWatcher(),
    getCaseLast4MonthsTxMetadataWatcher(),
    getCaseLast4MonthsTxHeatmapImageWatcher(),
    unveilCounterpartyDataWatcher(),
    unveilMasterDataWatcher(),
    getPassthroughConfigWatcher(),
    updatePassthroughConfigWatcher(),
    breakOutCaseFromGroupBeginWatcher(),
    postReprocessCaseWatcher(),
    queryReprocessCasesWatcher(),
    executeReprocessCasesWatcher(),
    addCaseToGroupWatcher(),
    getArticleBodyWatcher(),
    getSmurfingV2ConfigWatcher(),
    updateSmurfingV2ConfigWatcher(),
    deleteSmurfingConfigWatcher(),
    getRoundAmountConfigWatcher(),
    updateRoundAmountConfigWatcher(),
    deleteRoundAmountConfigWatcher(),
    getCounterPartyFrequencyConfigWatcher(),
    updateCounterPartyFrequencyConfigWatcher(),
    deleteCounterPartyFrequencyConfigWatcher(),
    getAccountBasedFrequencyConfigWatcher(),
    updateAccountBasedFrequencyConfigWatcher(),
    getDormantAccountConfigWatcher(),
    updateDormantAccountConfigWatcher(),
    deleteDormantAccountConfigWatcher(),
    addTenantWatcher(),
    addTenantToUsersWatcher(),
    getAllTenantsWatcher(),
    getUsersWatcher(),
    getUsersNotInTenantWatcher(),
    updateUserWatcher(),
    getCrossBorderVolumeConfigWatcher(),
    updateCrossBorderVolumeConfigWatcher(),
    deleteCrossBorderVolumeConfigWatcher(),
    deleteVolumeIncreaseDecreaseConfigWatcher(),
    getVolumeIncreaseDecreaseConfigWatcher(),
    updateVolumeIncreaseDecreaseConfigWatcher(),
    deleteSuspiciousAvgTicketSizeConfigWatcher(),
    getSuspiciousAvgTicketSizeConfigWatcher(),
    updateSuspiciousAvgTicketSizeConfigWatcher(),
    getMccFileListWatcher(),
    getHidtaHifcaFileListWatcher(),
    getOccupationFileListWatcher(),
    getProductTypeFileListIndividualWatcher(),
    getProductTypeFileListOrganizationWatcher(),
    activateMccFileListWatcher(),
    activateOccupationFileListWatcher(),
    activateProductTypeFileListIndividualWatcher(),
    activateProductTypeFileListOrganizationWatcher(),
    updateActiveMccListVersionWatcher(),
    updateActiveOccupationListVersionWatcher(),
    updateActiveProductTypeListVersionIndividualWatcher(),
    updateActiveProductTypeListVersionOrganizationWatcher(),
    updateActiveHidtaHifcaListVersionWatcher(),
    activateUpdateActiveMccListVersionWatcher(),
    activateUpdateActiveOccupationListVersionWatcher(),
    activateUpdateActiveProductTypeListVersionWatcherIndividual(),
    activateUpdateActiveProductTypeListVersionWatcherOrganization(),
    deleteAccountBasedFrequencyConfigWatcher(),
    getMasterTenantsWatcher(),
    deleteAccountBasedFrequencyIncreaseDecreaseConfigWatcher(),
    updateAccountBasedFrequencyIncreaseDecreaseConfigWatcher(),
    getAccountBasedFrequencyIncreaseDecreaseConfigWatcher(),

    deleteRoundAmountRatioIncreaseDecreaseConfigWatcher(),
    updateRoundAmountRatioIncreaseDecreaseConfigWatcher(),
    getRoundAmountRatioIncreaseDecreaseConfigWatcher(),

    updateTenantStructureWatcher(),
    updateCaseStatusBeginWatcher(),

    putTenantCallbackConfigWatcher(),
    getTenantCallbackConfigSecretWatcher(),

    putTenantScorechainConfigWatcher(),
    getTenantScorechainConfigSecretWatcher(),

    putTenantUserManagementConfigWatcher(),

    getGoAMLXMLWatcher(),
    saveGoAMLXMLWatcher(),
    validateGoAMLXMLWatcher(),

    getGroupsCountMetricWatcher(),
    getGroupsTypeCountMetricWatcher(),
    getGroupsHealthMetricWatcher(),
    getGroupsFalsePositivePatternWatcher(),
    getGroupsFalsePositiveRateWatcher(),
    getGroupsTruePositivePatternWatcher(),
    getGroupsCasesPerformanceWatcher(),
    getRiskyWordsPatternWatcher(),

    getCustomerDetailsWatcher(),
    updateCaseStateCustomerWatcher(),

    getCustomerRiskRatingConfigWatcher(),
    updateCustomerRiskRatingConfigWatcher(),
    createRiskFactorWatcher(),
    deleteRiskFactorWatcher(),

    getCustomerRiskRatingConfigForIndividualWatcher(),
    updateCustomerRiskRatingConfigForIndividualWatcher(),

    getSettingsWatcher(),
    updateSettingsWatcher(),
    updateSettingsCountryRisksWatcher(),
    deleteSettingsCountryRisksWatcher(),
    addMasterTenantWatcher(),
    getMasterTenantCreationStatusWatcher(),

    getCustomerCasesWatcher(),
    getCustomersWatcher(),
    getCustomerDetailWatcher(),
    getCustomerCaseDetailWatcher(),
    getAllCustomerCasesWatcher(),
    getMoreCustomerCasesWatcher(),
    getAllTransactionCasesWatcher(),
    getMoreTransactionCasesWatcher(),
    addCustomerCaseNoteWatcher(),
    downloadCaseNoteAttachmentCCWatcher(),

    getVolumeRiskV2ConfigBeginWatcher(),
    updateVolumeRiskV2ConfigWatcher(),
    deleteVolumeRiskV2ConfigWatcher(),

    getCountryRiskV2ConfigBeginWatcher(),
    updateCountryRiskV2ConfigWatcher(),
    deleteCountryRiskV2ConfigWatcher(),

    getCaseDetailConfigWatcher(),

    getPassthroughV2ConfigWatcher(),
    updatePassthroughV2ConfigWatcher(),
    deletePassthroughV2ConfigWatcher(),

    getPassthroughV3ConfigWatcher(),
    updatePassthroughV3ConfigWatcher(),
    deletePassthroughV3ConfigWatcher(),

    getCounterPartyVolumeConfigWatcher(),
    updateCounterPartyVolumeConfigWatcher(),
    deleteCounterPartyVolumeConfigWatcher(),

    getBlacklistConfigWatcher(),
    updateBlacklistConfigWatcher(),
    deleteBlacklistConfigWatcher(),

    getRiskyWordsV2ConfigWatcher(),
    updateRiskyWordsV2ConfigWatcher(),
    deleteRiskyWordsV2ConfigWatcher(),
    getRiskyWordsV2FileListWatcher(),
    updateActiveRiskyWordsV2ListVersionWatcher(),

    getFiltersFileListWatcher(),
    updateActiveFiltersListVersionWatcher(),

    getFiltersInstantiableListConfigWatcher(),
    updateFiltersInstantiableConfigListConfigWatcher(),
    deleteFiltersInstantiableConfigListConfigWatcher(),

    getBlacklistFileListWatcher(),
    updateActiveBlacklistListVersionWatcher(),
    getRiskyWordsInstantiableListConfigWatcher(),
    updateRiskyWordsInstantiableConfigListConfigWatcher(),
    deleteRiskyWordsInstantiableConfigListConfigWatcher(),

    getBlacklistInstantiableListConfigWatcher(),
    updateActiveBlacklistInstantiableListConfigVersionWatcher(),
    updateBlacklistInstantiableListConfigWatcher(),
    updateActiveRiskyWordInstantiableListConfigVersionWatcher(),
    deleteBlacklistInstantiableListConfigWatcher(),

    getDataProviderListConfigWatcher(),
    saveDataProviderListConfigWatcher(),
    deleteDataProviderListConfigWatcher(),

    getScreeningListWatcher(),
    getScreeningListAttributesWatcher(),

    getDataProviderAuditTrailWatcher(),

    getEntityResolutionConfigWatcher(),
    saveEntityResolutionConfigWatcher(),
    getEntityResolutionProfileWatcher(),
    deleteEntityResolutionProfileWatcher(),

    getCaseCollectionByIdsWatcher(),

    getAllAuditTrailsWatcher(),

    assessPepSanctionWatcher(),

    requestManualRiskLevelChangeWatcher(),

    deletePepSanctionInstanciableConfigWatcher(),
    updatePepSanctionInstanciableProviderWatcher(),

    getRuleCategoriesWatcher(),

    getTransactionFieldsWatcher(),

    getPlausibilityConfigWatcher(),
    updatePlausibilityConfigWatcher(),
    deletePlausibilityConfigWatcher(),

    getReferenceAccountV2ConfigWatcher(),
    updateReferenceAccountV2ConfigWatcher(),
    deleteReferenceAccountV2ConfigWatcher(),

    getExpectedCustomerValueConfigWatcher(),
    updateExpectedCustomerValueConfigWatcher(),
    deleteExpectedCustomerValueConfigWatcher(),

    getAmlRiskScreeningConfigWatcher(),
    saveAmlRiskScreeningConfigWatcher(),
    deleteAmlRiskScreeningConfigWatcher(),
    getAmlRiskScreeningImportStatusWatcher(),

    getFanInConfigWatcher(),
    saveFanInConfigWatcher(),
    deleteFanInConfigWatcher(),

    getBehavioralAnalysisConfigWatcher(),
    saveBehavioralAnalysisConfigWatcher(),
    deleteBehavioralAnalysisConfigWatcher(),

    getRuleBuilderConfigWatcher(),
    saveRuleBuilderConfigWatcher(),
    deleteRuleBuilderConfigWatcher(),

    getFlaggingConfigWatcher(),
    updateFlaggingConfigWatcher(),
    deleteFlaggingConfigWatcher(),

    getListForRiskFactorWatcher(),

    getCaseNotificationsConfigWatcher(),
    updateCaseNotificationsConfigWatcher(),
    deleteCaseNotificationsConfigWatcher(),

    getApiKeyConfigWatcher(),
    deleteApiKeyWatcher(),

    getSftpConfigWatcher(),
    updateSftpConfigWatcher(),
    deleteSftpConfigWatcher(),
    getSftpConfigUnmaskedWatcher(),

    getEligibleTenantsWatcher(),
    copyInstanceWatcher(),
  ]);
}
