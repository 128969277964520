import { isEmpty } from 'lodash';
import i18next from 'i18next';
import { CaseStatuses } from 'models/case/case-state/case-status';
import { ProcessingStatusesV2 } from 'models/case/case-state/processing-status';
import { NestedFilter } from 'models/case/search/group-search-request';
import { Filter } from 'models/api/search';

export const caseStatus = (status: Array<CaseStatuses>): Filter => ({
  field: i18next.t('mapping:filters.filtering.caseStatuses.key'),
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  values: status,
});

export const processingStatus = (status: Array<ProcessingStatusesV2>): Filter => ({
  field: i18next.t('mapping:filters.filtering.processingStatusesV2.key'),
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  values: status,
});

export const assignedUser = (userId: string): Filter => ({
  field: i18next.t('mapping:filters.filtering.assignedToUserId.key'),
  condition: i18next.t('mapping:operator.text.TEXT_EQUAL.key'),
  values: [userId],
});

export const buildPreFilters = (
  caseStatusFilter: string,
  userId: string,
  nestedFilter: NestedFilter,
): (Filter | NestedFilter)[] => {
  const preFilters: (Filter | NestedFilter)[] = [];

  switch (caseStatusFilter) {
    case i18next.t('mapping:caseStatusFilters.ESCALATED.key'):
    case i18next.t('mapping:caseStatusFilters.AUTO_CLOSED_REVIEW.key'): {
      preFilters.push(
        processingStatus(
          i18next.t(
            `mapping:caseStatusFilters.${caseStatusFilter.toUpperCase()}.values.processingStatuses`,
          ),
        ),
      );
      break;
    }

    case i18next.t('mapping:caseStatusFilters.MY_CASES.key'): {
      preFilters.push(assignedUser(userId));
      break;
    }
    default: {
      if (!isEmpty(caseStatusFilter)) {
        const caseStatuses = i18next.t(
          `mapping:caseStatusFilters.${caseStatusFilter.toUpperCase()}.values.caseStatuses`,
          { defaultValue: [] },
        );
        if (caseStatuses.length > 0) {
          preFilters.push(caseStatus([...caseStatuses] as CaseStatuses[]));
        }
      } else if (nestedFilter) {
        preFilters.push(nestedFilter);
      }
    }
  }

  return preFilters;
};
