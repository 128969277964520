import { buildLastUpdatedSubmitValues } from 'components/filters/fields/last-updated';
import { TIME_FRAME_OPTIONS } from 'utils/constants/dashboard-constant';
import UrlFilterParamsParser from 'utils/helpers/url-utils/url-filter-params-parser';
import { SortingRule } from 'models/api/search';
import { DEFAULT_FILTERING, DEFAULT_SORTING } from 'components/custom-view/constants';

const stripDefaultFiltering = (search: string): string => {
  const urlSearchParams = new URLSearchParams(search);
  const filters = urlSearchParams.getAll('f');
  const sorting = urlSearchParams.getAll('s');
  const nextFilters = filters.filter((filter) => !`^${filter}$`.match(DEFAULT_FILTERING));
  const nextUrlSearchParams = new URLSearchParams();
  nextFilters.forEach((filter) => nextUrlSearchParams.append('f', filter));
  sorting.forEach((sort) => nextUrlSearchParams.append('s', sort));
  const parsed = UrlFilterParamsParser.parse(nextUrlSearchParams.toString(), {}, []);
  return UrlFilterParamsParser.buildUrl(parsed.filters, parsed.sorts);
};

const enrichWithDefaults = (customViewUrlParams: string, currentParams: string): string => {
  const currentLastUpdatedAtFilter = UrlFilterParamsParser.parse(currentParams).filters.find(
    (it) => it.field === 'updatedAt',
  );
  const defaultLastUpdatedAtFilter = buildLastUpdatedSubmitValues(
    TIME_FRAME_OPTIONS.LAST_3_MONTH.value.from,
    '',
  );

  const parsedDefaultFilters = UrlFilterParamsParser.parse('', null, [
    currentLastUpdatedAtFilter ?? defaultLastUpdatedAtFilter,
  ]);
  const parsedCustom = UrlFilterParamsParser.parse(customViewUrlParams);
  let sortingConfig: SortingRule[] = [...parsedCustom.sorts];

  if (parsedCustom.sorts.length === 0) {
    const defaultSort: SortingRule = {
      field: 'updatedAt',
      direction: 'DESC',
    };

    sortingConfig = [defaultSort];
  }

  return UrlFilterParamsParser.buildUrl(
    [...parsedDefaultFilters.filters, ...parsedCustom.filters],
    sortingConfig,
  );
};

const isPredefinedView = (search: string) => {
  const urlSearchParams = new URLSearchParams(search);
  const filters = urlSearchParams.getAll('f');
  const sorting = urlSearchParams.getAll('s');

  if (filters.length === 1 && sorting.length === 1) {
    const decodedFilter = decodeURIComponent(filters[0]);
    const decodedSorting = decodeURIComponent(sorting[0]);

    const isFilterMatch = DEFAULT_FILTERING.test(`^${decodedFilter}$`);
    const isSortingMatch = decodedSorting === DEFAULT_SORTING;

    return isFilterMatch && isSortingMatch;
  }

  return false;
};

export { enrichWithDefaults, isPredefinedView, stripDefaultFiltering };
