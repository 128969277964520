import { useMemo } from 'react';
import { Grid } from '@mui/material';
import Filters from 'components/filters/filters';
import { nonDeletableFilters } from 'modules/trx-cases/case-detail/utils/helpers/filters';
import ColumnManagement from 'components/table/column-management/column-management';
import { LOCAL_STORAGE } from 'utils/constants/constant';
import { CasesFileExportMenu } from 'components/cases-file-export-menu/cases-file-export-menu';
import { API_URLS } from 'utils/constants/api-config';
import { useTranslation } from 'react-i18next';
import CaseStatusFilters from 'utils/constants/case-status-filters';
import CustomViewOptions from 'components/custom-view/custom-view-options';

const TrxCaseListHeader = ({
  enabledFilters,
  handleFilterConfigChanges,
  urlFilterOptions,
  columnConfig,
  handleChangeColumnConfig,
  sortingOptions,
  filterOptions,
  preFilters,
  totalCases,
  caseStatusFilter,
}) => {
  const { t } = useTranslation();

  const exportMessage = useMemo(() => {
    const currentCaseStatusFilterKey = caseStatusFilter.key;
    const allowedCaseStates = [
      CaseStatusFilters.MY_CASES.key,
      CaseStatusFilters.ANY_CASES.key,
      CaseStatusFilters.NOT_OPENED.key,
    ];

    return allowedCaseStates.includes(currentCaseStatusFilterKey)
      ? t('csv.download.message')
      : undefined;
  }, [caseStatusFilter, t]);

  return (
    <Grid
      container
      item
      xs={12}
      wrap="nowrap"
      className="py-2 pl-3"
      alignItems="center"
      style={{ position: 'sticky', left: 0 }}
    >
      <Grid item xs>
        <Filters
          enabledFilters={enabledFilters}
          nonDeletableFilters={nonDeletableFilters(t)}
          onFilterConfigChanges={handleFilterConfigChanges}
          urlFilterConfig={urlFilterOptions}
          dataTestIdPrefix="filter-cases"
        />
      </Grid>

      <Grid item className="px-2 border-left">
        <CustomViewOptions />
        <ColumnManagement
          columnList={columnConfig}
          handleChangeColumnConfig={handleChangeColumnConfig}
          localStorageKey={LOCAL_STORAGE.trxCaseList.columnConfig}
        />
        <CasesFileExportMenu
          menuOptions={[
            {
              label: t('csv.download.groups'),
              url: API_URLS.groupService.export,
            },
            {
              label: t('csv.download.cases'),
              url: API_URLS.csvExporterService.transactionList,
              limit: 50_000,
              message: exportMessage,
              useStreamDownloader: true,
            },
          ]}
          sortingOptions={sortingOptions}
          filterOptions={[...filterOptions, ...preFilters]}
          total={totalCases}
        />
      </Grid>
    </Grid>
  );
};

export default TrxCaseListHeader;
