import DOMPurify from 'dompurify';
import he from 'he';
import { REGEX } from 'utils/constants/constant';
import { formatMoney } from 'utils/helpers/math-util';
import { formatAllDateTimes } from 'utils/helpers/time-util';

export function replaceAll(search, replacement, target) {
  return target.replace(new RegExp(search, 'g'), replacement);
}

export function makeReadableTitleFromDataFeatureName(dataFeatureName) {
  const featureName = replaceAll('-', ' ', dataFeatureName);
  return featureName.charAt(0).toUpperCase() + featureName.slice(1);
}
const decodeHtml = (str = '') => {
  if (!str?.length) {
    return '';
  }
  let decodedStr = str.replaceAll('&nbsp;', ' ');

  decodedStr = he.decode(decodedStr);
  return decodedStr;
};
const stripScripts = (str) => {
  const div = document.createElement('div');
  div.innerHTML = str;
  const scripts = div.getElementsByTagName('script');
  let i = scripts.length;
  // eslint-disable-next-line no-plusplus
  while (i--) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }
  return decodeHtml(div.innerHTML);
};

export const stripHtml = (htmlString) => htmlString.replace(REGEX.htmlTag, '');

const stripTrimString = (str) => {
  const trimmedStr = str.trim();
  let strippedStr = stripScripts(trimmedStr);
  strippedStr = stripHtml(strippedStr);

  return strippedStr;
};

const encodeHtml = (str = '') => {
  if (!str?.length) {
    return '';
  }
  const trimmedStr = str.trim();

  let encodedStr = he.encode(trimmedStr);
  encodedStr = encodedStr.replaceAll(' ', '&nbsp;');
  return encodedStr;
};

const escapeLinkTag = (str) => {
  let text = decodeHtml(str);
  text = text.replace(/<a.*?(href=\x22.*?\x22).*?>/g, '<a $1>');
  text = text.replace(/<a href="">/g, '<a>');
  return text.replace(/href="(.*?)"/, (m, $1) => `href="${encodeURIComponent($1)}"`);
};

const urlify = (text) =>
  text.replace(REGEX.url, (url) => {
    if (url.endsWith('<br />')) {
      // On adding a note, pressing shift+Enter is decoded into <br /> at the end of url. Handle URL with <br /> at the end
      const cleanedUrl = url.slice(0, -6); // Remove the <br /> part
      return `<a target="_blank" href="${cleanedUrl}">${cleanedUrl}</a>`;
    }
    return `<a target="_blank" href="${url}">${url}</a>`;
  });
function makeReadableBinaryValue(value) {
  if (value === undefined || String(value).length === 0) {
    return '';
  }
  if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
    return value;
  }
  if (value > 0) {
    return 'True';
  }
  return 'False';
}

function formatFeatureValueDefault(str) {
  const maxDecimalPlace = 3;
  if (isNaN(str)) {
    return str;
  }
  if (isFinite(str) === false) {
    return 'None';
  }
  if (str.indexOf('.') < 0) {
    return str;
  }
  const floatValue = parseFloat(str);
  const intValue = parseInt(floatValue, 10);
  const postDecimalValue = floatValue - intValue;

  let decimalPlace = -Math.floor(Math.log(postDecimalValue) / Math.log(10));
  if (isFinite(decimalPlace) && decimalPlace > 0) {
    decimalPlace = decimalPlace > maxDecimalPlace ? maxDecimalPlace : decimalPlace;
  } else {
    decimalPlace = 0;
  }
  return parseFloat(str).toFixed(decimalPlace);
}

export function makeReadableValueDependingOnUnit(value, unit) {
  if (unit === undefined || unit.length === 0 || value === undefined) {
    return value;
  }

  if (unit.toLowerCase().includes('currency')) {
    return formatMoney(value);
  }
  if (unit.toLowerCase().includes('binary')) {
    return makeReadableBinaryValue(value);
  }
  return formatFeatureValueDefault(value);
}

/**
 * returns SNAKE_CASE strings as capitalised and separated by spaces
 * used in staff `role`, eg. "ACCOUNT_MANAGER"
 * @param {*} str
 */
const makeSnakeCaseReadable = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).split('_').join(' ').toLowerCase();

/**
 * i18next uses dots (.) as a key seperator if there's a key with a dot
 * use this function to convert it to (_)
 * @param {*} str
 */
const escapeI18KeySeperator = (str) => str.replaceAll('.', '_');

const isUppercase = (ch) => /[A-Z]/.test(ch);

const extractUppercaseChars = (str) =>
  str
    .split('')
    .filter((ch) => isUppercase(ch))
    .join('');

export const shortenLongString = (longString) => {
  const components = longString.split('.');
  return components
    .map((comp) => comp.charAt(0) + extractUppercaseChars(comp.substring(1)).toLowerCase())
    .join('_');
};

export const shortenSourceField = (screeningSourceField) => {
  const shortened = shortenLongString(screeningSourceField);
  // to distinguish between sourceFields containining index, e.g. masterData.accountHoldingPartyData.companyDetails.shareholders[2].personal
  if (screeningSourceField.includes('[') && screeningSourceField.includes(']')) {
    const index = screeningSourceField.split('[')[1].split(']')[0];
    return `${shortened}_${index}`;
  }
  return shortened;
};

const removeAttachmentPlaceholder = (text) => text.replaceAll(REGEX.fileDownloadPlaceholder, '');
const removeLinkPlaceholder = (text) => text.replaceAll(REGEX.linkPlaceholder, '');

/**
 * Returns encoded value. Without double encoding or URI encoding.
 * For encoded twice like '&#x26;lt;' returns '&lt;'.
 * For URI encoding like '50%25' returns '50%'
 *
 * Input: https://www.example.com&nbsp;&#x26;lt;br&#x26;gt;&#x26;lt;br&#x26;gt;&#x26;lt;b&#x26;gt;test&nbsp;html&nbsp;tags&#x26;lt;/b&#x26;gt;
 * Output: https://www.example.com &lt;br&gt;&lt;br&gt;&lt;b&gt;test html tags&lt;/b&gt;
 */
const decodeUriAndDoubledEncoding = (noteData) => {
  const note = typeof noteData === 'string' ? noteData : '';
  let decodedNote = '';

  // fix/crr-124
  // the string in note is not always encoded when saved
  try {
    decodedNote = decodeURIComponent(note);
  } catch (err) {
    decodedNote = decodeHtml(note);
  }

  // If not decoded by try catch above then just decode HTML
  if (decodedNote === note) {
    decodedNote = decodeHtml(note);
  }
  decodedNote = formatAllDateTimes(decodedNote);
  decodedNote = removeAttachmentPlaceholder(decodedNote);
  decodedNote = removeLinkPlaceholder(decodedNote);
  return decodedNote;
};

export const decodeString = (data) => decodeHtml(decodeUriAndDoubledEncoding(data));

/**
 * Returns urlified encoded value. For encoded twice like '&#x26;lt;' returns '&lt;'.
 * We need to leave encoding as we use `dangerouslySetInnerHTML` to display notes. Using this parameter makes react to evaluate HTML.
 * If we want to HTML to be rendered we need to pass html tags as not encoded tags. If a tag needs to be displayed as a text they need to be encoded.
 *
 * Input: https://www.example.com&nbsp;&#x26;lt;br&#x26;gt;&#x26;lt;br&#x26;gt;&#x26;lt;b&#x26;gt;test&nbsp;html&nbsp;tags&#x26;lt;/b&#x26;gt;
 * Output: <a href="https://www.example.com" target="_blank">https://www.example.com</a> &lt;br&gt;&lt;br&gt;&lt;b&gt;test html tags&lt;/b&gt;
 */
export const decodeNoteAndPurify = (noteData) =>
  DOMPurify.sanitize(urlify(decodeUriAndDoubledEncoding(noteData)), {
    ADD_ATTR: ['target'],
  });

export const trimString = (str) => str?.trim()?.replace(/  +/g, ' ');

export const concatIfNonNull = (str1, str2) => trimString(`${str1 ?? ''} ${str2 ?? ''} `);

/**
 * Returns group name of a nested attribute
 *
 * Input: demo->case-here
 * Output: demo
 */
const getGroupName = (stringLabel) => {
  if (stringLabel.includes('->')) {
    return stringLabel.split('->')[0];
  }
  return '';
};

const replaceDotColonWithUnderscore = (target) => target.replace(/[.:]/g, '_');

export {
  getGroupName,
  makeSnakeCaseReadable,
  escapeI18KeySeperator,
  stripTrimString,
  urlify,
  encodeHtml,
  decodeHtml,
  isUppercase,
  extractUppercaseChars,
  escapeLinkTag,
  replaceDotColonWithUnderscore,
};
