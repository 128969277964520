const CaseType = {
  PEP: 'PEP',
  RCA: 'RCA',
  SIP: 'SIP',
  SIE: 'SIE',
  SP: 'SP',
  SE: 'SE',
  CRH: 'COUNTRY_RISK_HIGH',
  CRM: 'COUNTRY_RISK_MEDIUM',
  COUNTRY_RISK_V2: 'COUNTRY_RISK_V2',
  VOLUME_RISK: 'VOLUME_RISK',
  VOLUME_RISK_V2: 'VOLUME_RISK_V2',
  USAGE_RISK: 'USAGE_RISK',
  ACCOUNT_BLACKLISTED: 'ACCOUNT_BLACKLISTED',
  BP: 'BP',
  BE: 'BE',
  BLACKLIST: 'BLACKLIST',
  REFERENCE_ACCOUNT: 'REFERENCE_ACCOUNT',
  REFERENCE_ACCOUNT_V2: 'REFERENCE_ACCOUNT_V2',
  MONEY_MULE: 'MONEY_MULE',
  PHISHING: 'PHISHING',
  FAN_IN: 'FAN_IN',
  CASH_TRANSACTION: 'CASH_TRANSACTION',
  PASSTHROUGH: 'PASSTHROUGH',
  PASSTHROUGH_V2: 'PASSTHROUGH_V2',
  PASSTHROUGH_V3: 'PASSTHROUGH_V3',
  RISKY_WORDS_V2: 'RISKY_WORDS_V2',
  SMURFING: 'SMURFING',
  ANOMALY: 'ANOMALY',
  ROUND_AMOUNT: 'ROUND_AMOUNT',
  ACCOUNT_BASED_FREQUENCY: 'ACCOUNT_BASED_FREQUENCY',
  UNUSUAL_COUNTER_PARTY_FREQUENCY: 'UNUSUAL_COUNTER_PARTY_FREQUENCY',
  DORMANT_ACCOUNT_ACTIVATION: 'DORMANT_ACCOUNT_ACTIVATION',
  CROSS_BORDER_VOLUME: 'CROSS_BORDER_VOLUME',
  VOLUME_INCREASE_DECREASE: 'VOLUME_INCREASE_DECREASE',
  SUSPICIOUS_AVG_TICKET_SIZE: 'SUSPICIOUS_AVG_TICKET_SIZE',
  ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE: 'ACCOUNT_BASED_FREQUENCY_INCREASE_DECREASE',
  ROUND_AMOUNT_RATIO_INCREASE_DECREASE: 'ROUND_AMOUNT_RATIO_INCREASE_DECREASE',
  COUNTER_PARTY_VOLUME: 'COUNTER_PARTY_VOLUME',
  REG_314A: 'REG_314A',
  AMP: 'AMP',
  AME: 'AME',
  SOE: 'SOE',
  PLAUSIBILITY: 'PLAUSIBILITY',
  AE: 'AE',
  AP: 'AP',
  EXPECTED_CUSTOMER_VALUE: 'EXPECTED_CUSTOMER_VALUE',
  WALLET_SCREENING: 'WALLET_SCREENING',
  FREQUENT_CHANGES_RULE: 'FREQUENT_CHANGES_RULE',
  PERCENTAGE_RULE: 'PERCENTAGE_RULE',
  GROUPING_RULE: 'GROUPING_RULE',
  AML_RISK_SCREENING: 'AML_RISK_SCREENING',
  VOLUME_RISK_V3: 'VOLUME_RISK_V3',
  FREQUENCY_V2: 'FREQUENCY_V2',
};

export default CaseType;
