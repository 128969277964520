import React, { FC } from 'react';
import { Chip } from '@mui/material';
import Spinner from 'components/loaders/spinner';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export type LoadingChipButtonProps = {
  title: string;
  isSelected: boolean;
  isLoading: boolean;
  icon?: React.ReactElement;
  onClick: (event: React.MouseEvent<never>) => void;
};

export const LoadingChipButton: FC<LoadingChipButtonProps> = ({
  title,
  icon,
  isSelected,
  isLoading,
  onClick,
}) => {
  const spinner = (
    <Spinner
      size={16}
      thickness={4}
      color="inherit"
      className="mb-n1xs ml-2"
      isLoading={isLoading}
      actionType={undefined}
      multipleActions={undefined}
    />
  );

  return (
    <Chip
      icon={icon}
      label={title}
      color={isSelected ? 'primary' : 'default'}
      onClick={onClick}
      clickable
      onDelete={onClick}
      deleteIcon={isLoading ? spinner : <ArrowDropDownIcon />}
    />
  );
};
