import React from 'react';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import USER_PERMISSIONS from 'utils/constants/user-permissions';
import config from 'utils/helpers/config-utils/config';
import CaseRouteUrls from 'utils/constants/case-route-url';
import { lazyRetry } from 'utils/helpers/lazy-retry';

import Strings from 'utils/helpers/locales/strings.en';
import { matchPath } from 'react-router';
import SpeedIcon from '@mui/icons-material/Speed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import SettingsIcon from '@mui/icons-material/Settings';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BrushIcon from '@mui/icons-material/Brush';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GavelIcon from '@mui/icons-material/Gavel';
import CastIcon from '@mui/icons-material/Cast';
import ListIcon from '@mui/icons-material/List';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ScienceIcon from '@mui/icons-material/Science';
import { AccountBox, UpcomingOutlined, Try, Add, Bolt, Hub, ShowChart } from '@mui/icons-material';

import InitialRedirect from 'modules/authentication/login/components/initial-redirect';
import LoginViewV2Container from 'modules/authentication/login/view/login-view-v2-container';
import ResetPasswordView from 'modules/authentication/reset-password/views/reset-password-view';
import CustomViewCases from 'modules/trx-cases/case-list/custom-view-cases';

const DetachmentConfig = React.lazy(() =>
  lazyRetry(
    () => import('modules/configuration/detachment-config/detachment-config'),
    'DetachmentConfig',
  ),
);
const BeingProcessedCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/trx-cases/case-list/being-processed-cases'),
    'BeingProcessedCases',
  ),
);
const WorkflowsAndUsers = React.lazy(() =>
  lazyRetry(
    () => import('modules/configuration/workflows-and-users/workflows-and-users'),
    'WorkflowsAndUsers',
  ),
);
const CustomerCaseDetailLayout = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-cases/case-detail/case-detail-layout'),
    'CustomerCaseDetailLayout',
  ),
);
const CustomerAutoClosedCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-cases/case-list/auto-closed-cases'),
    'CustomerAutoClosedCases',
  ),
);
const CustomerUnsubstantiatedCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-cases/case-list/unsubstantiated-cases'),
    'CustomerUnsubstantiatedCases',
  ),
);
const CustomerMyCases = React.lazy(() =>
  lazyRetry(() => import('modules/customer-cases/case-list/my-cases'), 'CustomerMyCases'),
);
const CustomerEscalatedCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-cases/case-list/escalated-cases'),
    'CustomerEscalatedCases',
  ),
);
const CustomerClosedCases = React.lazy(() =>
  lazyRetry(() => import('modules/customer-cases/case-list/closed-cases'), 'CustomerClosedCases'),
);
const CustomerNotOpenCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-cases/case-list/not-open-cases'),
    'CustomerNotOpenCases',
  ),
);
const CustomerOpenCases = React.lazy(() =>
  lazyRetry(() => import('modules/customer-cases/case-list/open-cases'), 'CustomerOpenCases'),
);
const CustomerAnyCases = React.lazy(() =>
  lazyRetry(() => import('modules/customer-cases/case-list/any-cases'), 'CustomerAnyCases'),
);
const Tooling = React.lazy(() =>
  lazyRetry(() => import('modules/configuration/tooling/tooling'), 'Tooling'),
);
const BrandingPage = React.lazy(() =>
  lazyRetry(() => import('modules/configuration/branding/branding-page'), 'BrandingPage'),
);
const MachineIntelligence = React.lazy(() =>
  lazyRetry(
    () => import('modules/configuration/machine-intelligence/machine-intelligence'),
    'MachineIntelligence',
  ),
);
const HealthPerformanceDashboardManager = React.lazy(() =>
  lazyRetry(
    () => import('modules/dashboard/case-statistic/health-performance-dashboard-manager'),
    'HealthPerformanceDashboardManager',
  ),
);
const CustomerStatisticsDashboardPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/dashboard/customer-statistics/customer-statistics-dashboard-page'),
    'CustomerStatisticsDashboardPage',
  ),
);
const PlatformPerformanceDashboardPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/dashboard/platform-performance/platform-performance-dashboard-page'),
    'PlatformPerformanceDashboardPage',
  ),
);
const TeamPerformanceDashboardPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/dashboard/team-performance/team-performance-dashboard-page'),
    'TeamPerformanceDashboardPage',
  ),
);
const Experimentation = React.lazy(() =>
  lazyRetry(
    () => import('modules/configuration/experimentation/experimentation'),
    'Experimentation',
  ),
);
const DataFeeds = React.lazy(() =>
  lazyRetry(() => import('modules/configuration/data-feeds/data-feeds'), 'DataFeeds'),
);
const DashboardManager = React.lazy(() =>
  lazyRetry(() => import('modules/dashboard/efficiency/dashboard-manager'), 'DashboardManager'),
);
const CustomerRiskRatingPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/configuration/customer-risk-rating/customer-risk-rating-page'),
    'CustomerRiskRatingPage',
  ),
);
const ClosedCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/closed-cases'), 'ClosedCases'),
);
const CaseDetailLayout = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-detail/case-detail-layout'), 'CaseDetailLayout'),
);
const RegulatoryReport = React.lazy(() =>
  lazyRetry(
    () => import('modules/regulatory-reporting/report/regulatory-reporting-report'),
    'RegulatoryReportingReport',
  ),
);
const Error = React.lazy(() => lazyRetry(() => import('components/error'), 'Error'));
const EscalatedCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/escalated-cases'), 'EscalatedCases'),
);
const MyCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/my-cases'), 'MyCases'),
);
const NotOpenedCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/not-opened-cases'), 'NotOpenedCases'),
);
const OpenCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/open-cases'), 'OpenCases'),
);
const RuleManager = React.lazy(() =>
  lazyRetry(() => import('modules/configuration/rule-manager/rule-manager'), 'RuleManager'),
);
const ListManager = React.lazy(() =>
  lazyRetry(() => import('modules/configuration/list-manager/list-manager'), 'ListManager'),
);
const EntityResolutionPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/entity-resolution/entity-resolution-page'),
    'EntityResolutionPage',
  ),
);
const SettingsPage = React.lazy(() =>
  lazyRetry(() => import('modules/configuration/settings/settings-page'), 'SettingsPage'),
);
const AnyCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/any-cases'), 'AnyCases'),
);
const AuditTrail = React.lazy(() =>
  lazyRetry(() => import('modules/audit-trail/audit-trail'), 'AuditTrail'),
);
const Tasks = React.lazy(() =>
  lazyRetry(() => import('modules/workspace/tasks/tasks-view-container'), 'Tasks'),
);
const CaseQualityAssurance = React.lazy(() =>
  lazyRetry(
    () => import('modules/workspace/case-quality-assurance/case-quality-assurance-view-container'),
    'CaseQualityAssurance',
  ),
);
const AutoClosedCases = React.lazy(() =>
  lazyRetry(() => import('modules/trx-cases/case-list/auto-closed-cases'), 'AutoClosedCases'),
);
const UnsubstantiatedCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/trx-cases/case-list/unsubstantiated-cases'),
    'UnsubstantiatedCases',
  ),
);
const AutoClosedReviewCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/trx-cases/case-list/auto-closed-review'),
    'AutoClosedReviewCases',
  ),
);
const UnsubstantiatedBoxReviewCases = React.lazy(() =>
  lazyRetry(
    () => import('modules/trx-cases/case-list/unsubstantiated-review'),
    'UnsubstantiatedReviewCases',
  ),
);
const CustomerListPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-data/customer-list/components/customer-list-page'),
    'CustomerListPage',
  ),
);
const CustomerDetailPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-data/customer-detail/components/customer-detail-page'),
    'CustomerDetailPage',
  ),
);
const AdhocScreeningPage = React.lazy(() =>
  lazyRetry(
    () => import('modules/customer-data/adhoc-screening/adhoc-screening-page'),
    'AdhocScreeningPage',
  ),
);

const defaultLoginRoute = {
  exact: true,
  path: '/',
  component: LoginViewV2Container,
};

const customLoginRoute = {
  exact: true,
  path: config.APP_ROOT_URL,
  component: InitialRedirect,
};

const resetPasswordRoute = {
  exact: true,
  path: config.APP_ROOT_URL + CaseRouteUrls.RESET_PASSWORD,
  component: ResetPasswordView,
};

const errorRoute = {
  exact: true,
  path: config.APP_ROOT_URL + CaseRouteUrls.ERROR,
  component: Error,
};

const APP_ROUTES = {
  customer: {
    path: '/customers/:tenantId',
    cases: {
      exact: true,
      path: '/customers/:tenantId/cases',
      component: CustomerAnyCases,
      key: 'cases.all',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId?/cases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    openCases: {
      exact: true,
      path: '/customers/:tenantId/openCases',
      component: CustomerOpenCases,
      key: 'cases.open',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/openCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    notOpenCases: {
      exact: true,
      path: '/customers/:tenantId/notOpenCases',
      component: CustomerNotOpenCases,
      key: 'cases.notOpened',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/notOpenCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    closedCases: {
      exact: true,
      path: '/customers/:tenantId/closedCases',
      component: CustomerClosedCases,
      key: 'cases.closed',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/closedCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    escalatedCases: {
      exact: true,
      path: '/customers/:tenantId/escalatedCases',
      component: CustomerEscalatedCases,
      key: 'cases.escalated',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/escalatedCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    autoClosedCases: {
      exact: true,
      path: '/customers/:tenantId/autoClosedCases',
      component: CustomerAutoClosedCases,
      key: 'cases.autoClosed',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/autoClosedCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    unsubstantiatedCases: {
      exact: true,
      path: '/customers/:tenantId/unsubstantiatedCases',
      component: CustomerUnsubstantiatedCases,
      key: 'cases.unsubstantiated',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/unsubstantiatedCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
    myCases: {
      exact: true,
      path: '/customers/:tenantId/myCases',
      component: CustomerMyCases,
      key: 'cases.myCases',
      permission: USER_PERMISSIONS.read.customerCaseList,
      detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
      caseDetail: {
        path: '/customers/:tenantId/myCases/:uuid',
        key: 'cases.customer.caseDetail',
        permission: USER_PERMISSIONS.read.customerCaseList,
        detachmentConfigKey: DETACHMENT_CONFIG.customerCasesList,
        component: CustomerCaseDetailLayout,
      },
    },
  },
  transaction: {
    path: '/caseManager/:tenantId?',
    myCases: {
      exact: true,
      path: '/caseManager/:tenantId?/myCases',
      component: MyCases,
      key: 'cases.myCases',
      index: 0,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.myCases],
      caseDetails: {
        exact: true,
        path: '/caseManager/:tenantId?/myCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    escalatedCases: {
      exact: true,
      path: '/caseManager/:tenantId?/escalatedCases',
      component: EscalatedCases,
      key: 'cases.escalated',
      index: 1,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.escalatedCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/escalatedCases/:uuid',
        exact: true,
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    anyCases: {
      exact: true,
      path: '/caseManager/:tenantId?/cases',
      component: AnyCases,
      key: 'cases.all',
      index: -1,
      permission: [
        USER_PERMISSIONS.read.trxCaseList,
        USER_PERMISSIONS.read.myCases,
        USER_PERMISSIONS.read.openCases,
        USER_PERMISSIONS.read.escalatedCases,
      ],
      caseDetails: {
        exact: true,
        path: '/caseManager/:tenantId?/cases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    openCases: {
      exact: true,
      path: '/caseManager/:tenantId?/openCases',
      component: OpenCases,
      key: 'cases.open',
      index: 2,

      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.openCases],
      caseDetails: {
        exact: true,
        path: '/caseManager/:tenantId?/openCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    fincenRegulatoryReport: {
      path: `/caseManager/:tenantId?/:caseStatus/:uuid/regulatory-reporting/report/:regulatory/:reportType`,
      component: RegulatoryReport,
      displayKibanaLink: false,
      permission: [
        USER_PERMISSIONS.read.trxCaseList,
        USER_PERMISSIONS.read.openCases,
        USER_PERMISSIONS.read.caseDetails,
      ],
      microfrontend: true,
    },
    closedCases: {
      path: '/caseManager/:tenantId?/closedCases',
      exact: true,
      component: ClosedCases,
      key: 'cases.closed',
      index: 3,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.closedCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/closedCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    autoClosedCases: {
      exact: true,
      path: '/caseManager/:tenantId?/autoClosedCases',
      component: AutoClosedCases,
      key: 'cases.autoClosed',
      index: 4,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.autoClosedCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/autoClosedCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    unsubstantiatedCases: {
      exact: true,
      path: '/caseManager/:tenantId?/unsubstantiatedCases',
      component: UnsubstantiatedCases,
      key: 'cases.unsubstantiated',
      index: 4,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.unsubstantiatedCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/unsubstantiatedCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    notOpenedCases: {
      path: '/caseManager/:tenantId?/notOpenedCases',
      exact: true,
      component: NotOpenedCases,
      key: 'cases.notOpened',
      index: 6,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.notOpenedCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/notOpenedCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    beingProcessedCases: {
      path: '/caseManager/:tenantId?/beingProcessedCases',
      exact: true,
      component: BeingProcessedCases,
      key: 'cases.beingProcessed',
      index: 7,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.beingProcessedCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/beingProcessedCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    autoClosedReviewCases: {
      path: '/caseManager/:tenantId?/autoClosedReviewCases',
      exact: true,
      component: AutoClosedReviewCases,
      key: 'cases.autoClosedReview',
      index: 5,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.autoClosedReviewCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/autoClosedReviewCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    unsubstantiatedReviewCases: {
      path: '/caseManager/:tenantId?/unsubstantiatedReviewCases',
      exact: true,
      component: UnsubstantiatedBoxReviewCases,
      key: 'cases.unsubstantiatedReview',
      index: 5,
      permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.autoClosedReviewCases],
      caseDetails: {
        path: '/caseManager/:tenantId?/unsubstantiatedReviewCases/:uuid',
        component: CaseDetailLayout,
        title: Strings.PageTitles['CASE DETAILS'],
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
    customViewCases: {
      path: '/caseManager/:tenantId?/customViewCases',
      exact: true,
      component: CustomViewCases,
      key: 'cases.customViewCases',
      index: 8,
      permission: [USER_PERMISSIONS.read.trxCaseList],
      caseDetails: {
        path: '/caseManager/:tenantId?/customViewCases/:uuid',
        component: CaseDetailLayout,
        title: 'Custom view',
        displayKibanaLink: true,
        permission: [USER_PERMISSIONS.read.trxCaseList, USER_PERMISSIONS.read.caseDetails],
      },
    },
  },
  efficiencyDashboard: {
    exact: true,
    path: '/caseManager/efficiencyDashboard',
    component: DashboardManager,
    icon: <SpeedIcon fontSize="small" />,
    key: 'dashboard.efficiency',
    permission: USER_PERMISSIONS.read.dashboard,
    detachmentConfigKey: DETACHMENT_CONFIG.dashboard.efficiencyDashboard,
  },

  caseStatistics: {
    path: '/caseManager/caseStatisticDashboard',
    exact: true,
    component: HealthPerformanceDashboardManager,
    icon: <AssessmentIcon fontSize="small" />,
    key: 'dashboard.health',
    permission: USER_PERMISSIONS.read.dashboard,
    detachmentConfigKey: DETACHMENT_CONFIG.dashboard.caseStatisticDashboard,
  },

  customerStatistics: {
    path: '/caseManager/customerCaseStatisticsDashboard',
    exact: true,
    component: CustomerStatisticsDashboardPage,
    icon: <AssessmentIcon fontSize="small" />,
    key: 'dashboard.customerCase',
    permission: USER_PERMISSIONS.read.dashboard,
    detachmentConfigKey: DETACHMENT_CONFIG.dashboard.customerStatisticsDashboard,
  },

  platformPerformance: {
    path: '/caseManager/platformPerformanceDashboard',
    exact: true,
    component: PlatformPerformanceDashboardPage,
    icon: <Bolt fontSize="small" />,
    key: 'dashboard.platformPerformance',
    permission: USER_PERMISSIONS.read.dashboard,
    detachmentConfigKey: DETACHMENT_CONFIG.dashboard.platformPerformanceDashboard,
  },

  teamPerformance: {
    path: '/caseManager/teamPerformanceDashboard',
    exact: true,
    component: TeamPerformanceDashboardPage,
    icon: <ShowChart fontSize="small" />,
    key: 'dashboard.teamPerformance',
    permission: USER_PERMISSIONS.read.dashboard,
    detachmentConfigKey: DETACHMENT_CONFIG.dashboard.teamPerformanceDashboard,
  },

  workflowAndUsers: {
    exact: true,
    path: '/caseManager/workflowUsers',
    component: WorkflowsAndUsers,
    icon: <ManageAccountsIcon fontSize="small" />,
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.tenantConfig,
    key: 'config.workflow',
    permission: USER_PERMISSIONS.read.workflowAndUsers,
  },

  settings: {
    exact: true,
    path: '/caseManager/settings',
    component: SettingsPage,
    icon: <SettingsIcon fontSize="small" />,
    key: 'config.settings',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.settings,
    permission: USER_PERMISSIONS.read.settings,
  },

  customerRiskRating: {
    exact: true,
    path: '/caseManager/customerRiskRating',
    component: CustomerRiskRatingPage,
    key: 'config.crr',
    icon: <ThumbsUpDownIcon fontSize="small" />,
    detachmentConfigKey: DETACHMENT_CONFIG.customerRiskRating.organization,
    permission: USER_PERMISSIONS.read.customerRiskRating,
  },

  ruleManager: {
    exact: true,
    path: '/caseManager/ruleManager',
    component: RuleManager,
    icon: <GavelIcon fontSize="small" />,
    key: 'config.ruleManager',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.ruleManager,
    permission: USER_PERMISSIONS.read.ruleManager,
  },
  listManager: {
    exact: true,
    path: '/caseManager/listManager',
    component: ListManager,
    icon: <ListIcon fontSize="small" />,
    key: 'config.listManager',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.listManager,
    permission: USER_PERMISSIONS.read.listManager,
  },
  entityResolution: {
    exact: true,
    path: '/caseManager/entityResolution',
    component: EntityResolutionPage,
    icon: <Hub fontSize="small" />,
    key: 'config.entityResolution',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.entityResolution,
    permission: USER_PERMISSIONS.read.entityResolution,
    isTitleInChildComponent: true,
  },
  experimentation: {
    exact: true,
    path: '/caseManager/experimentation',
    component: Experimentation,
    icon: <ScienceIcon fontSize="small" />,
    key: 'config.experimentation',
    permission: USER_PERMISSIONS.write.experimentationEnvironment,
    additionalAccessibleCheck: (settingsAndAccessContext) =>
      settingsAndAccessContext?.tenantConfig?.experimentationTenant,
  },
  dataFeeds: {
    exact: true,
    path: '/caseManager/dataFeeds',
    component: DataFeeds,
    icon: <CastIcon fontSize="small" />,
    key: 'config.dataFeed',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.dataFeeds,
    permission: USER_PERMISSIONS.read.dataFeeds,
  },
  machineIntelligence: {
    exact: true,
    path: '/caseManager/machineIntelligence',
    component: MachineIntelligence,
    icon: <PsychologyIcon fontSize="small" />,
    key: 'config.machineIntelligence',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.machineIntelligence,
    permission: USER_PERMISSIONS.read.machineIntelligence,
  },

  detachmentConfig: {
    exact: true,
    path: '/caseManager/detachmentConfiguration',
    component: DetachmentConfig,
    key: 'config.detachmentConfig',
    permission: USER_PERMISSIONS.write.detachmentConfig,
  },

  tooling: {
    exact: true,
    path: '/caseManager/tooling',
    component: Tooling,
    key: 'config.tooling',
    permission: USER_PERMISSIONS.write.tooling,
  },

  branding: {
    exact: true,
    path: '/branding',
    component: BrandingPage,
    icon: <BrushIcon fontSize="small" />,
    key: 'config.branding',
    detachmentConfigKey: DETACHMENT_CONFIG.configurations.whitelabel,
    permission: USER_PERMISSIONS.write.whitelabel,
  },

  auditTrail: {
    exact: true,
    path: '/caseManager/auditTrack',
    component: AuditTrail,
    icon: <AdminPanelSettingsIcon fontSize="small" />,
    key: 'audit.auditTrail',
    permission: USER_PERMISSIONS.read.auditTrack,
  },

  tasks: {
    exact: true,
    path: '/caseManager/tasks',
    component: Tasks,
    icon: <UpcomingOutlined fontSize="small" />,
    key: 'workspace.tasks',
    permission: USER_PERMISSIONS.read.tasks,
  },

  caseQualityAssurance: {
    path: '/caseManager/case-quality-assurance',
    component: CaseQualityAssurance,
    detachmentConfigKey: DETACHMENT_CONFIG.caseQualityAssurance,
    icon: <Try fontSize="small" />,
    key: 'workspace.caseQualityAssurance',
    permission: [USER_PERMISSIONS.read.workspace, USER_PERMISSIONS.read.caseQualityAssurance],
    microfrontend: true,
  },

  customerList: {
    exact: true,
    path: '/customerList/:tenantId',
    component: CustomerListPage,
    icon: <AccountBox fontSize="small" />,
    key: 'customerList',
    detachmentConfigKey: DETACHMENT_CONFIG.customerView,
    // todo permission will be different in the future
    permission: USER_PERMISSIONS.read.customerCaseList,
    customerDetail: {
      path: '/customerList/:tenantId/customer/:uuid',
      component: CustomerDetailPage,
      key: 'customerList.customerDetail',
      detachmentConfigKey: DETACHMENT_CONFIG.customerView,
      // todo permission will be different in the future
      permission: USER_PERMISSIONS.read.customerCaseList,
    },
  },

  adhocScreening: {
    exact: true,
    path: '/adhocScreening',
    component: AdhocScreeningPage,
    icon: <Add fontSize="small" />,
    key: 'adhocScreening',
    detachmentConfigKey: DETACHMENT_CONFIG.adhocScreening,
    permission: USER_PERMISSIONS.write.adhocScreening,
  },
};

export const customerCasesRoutes = [
  APP_ROUTES.customer.myCases,
  APP_ROUTES.customer.myCases.caseDetail,
  APP_ROUTES.customer.cases,
  APP_ROUTES.customer.cases.caseDetail,
  APP_ROUTES.customer.openCases,
  APP_ROUTES.customer.openCases.caseDetail,
  APP_ROUTES.customer.closedCases,
  APP_ROUTES.customer.closedCases.caseDetail,
  APP_ROUTES.customer.escalatedCases,
  APP_ROUTES.customer.escalatedCases.caseDetail,
  APP_ROUTES.customer.autoClosedCases,
  APP_ROUTES.customer.autoClosedCases.caseDetail,
  APP_ROUTES.customer.unsubstantiatedCases,
  APP_ROUTES.customer.unsubstantiatedCases.caseDetail,
  APP_ROUTES.customer.notOpenCases,
  APP_ROUTES.customer.notOpenCases.caseDetail,
];

export const customerListRoutes = [APP_ROUTES.customerList, APP_ROUTES.customerList.customerDetail];

/**
 * DEPRECATED BELOW
 * for adding routes, use the new object APP_ROUTES and add in relevant child
 */
const logoutRoute = {
  exact: true,
  path: `${CaseRouteUrls.LOGOUT}`,
  component: LoginViewV2Container,
};

const anyCasesRoute = APP_ROUTES.transaction.anyCases;

const myCasesRoute = APP_ROUTES.transaction.myCases;

const customViewCasesRoute = APP_ROUTES.transaction.customViewCases;

const escalatedCasesRoute = APP_ROUTES.transaction.escalatedCases;

const autoClosedReviewCasesRoute = APP_ROUTES.transaction.autoClosedReviewCases;

const unsubstantiatedReviewCasesRoute = APP_ROUTES.transaction.unsubstantiatedReviewCases;

const openCasesRoute = APP_ROUTES.transaction.openCases;

const closedCasesRoute = APP_ROUTES.transaction.closedCases;

const autoClosedCasesRoute = APP_ROUTES.transaction.autoClosedCases;

const unsubstantiatedCasesRoute = APP_ROUTES.transaction.unsubstantiatedCases;

const notOpenedCasesRoute = APP_ROUTES.transaction.notOpenedCases;

const beingProcessedCasesRoute = APP_ROUTES.transaction.beingProcessedCases;

const anyCaseDetailRoute = APP_ROUTES.transaction.anyCases.caseDetails;

const myCaseDetailRoute = APP_ROUTES.transaction.myCases.caseDetails;
const customViewDetailRoute = APP_ROUTES.transaction.customViewCases.caseDetails;

const escalatedDetailCaseRoute = APP_ROUTES.transaction.escalatedCases.caseDetails;

const openCaseDetailRoute = APP_ROUTES.transaction.openCases.caseDetails;
const caseFincenRegulatoryReport = APP_ROUTES.transaction.fincenRegulatoryReport;

const closedCaseDetailRoute = APP_ROUTES.transaction.closedCases.caseDetails;

const autoClosedCaseDetailRoute = APP_ROUTES.transaction.autoClosedCases.caseDetails;

const unsubstantiatedCaseDetailRoute = APP_ROUTES.transaction.unsubstantiatedCases.caseDetails;

const autoClosedReviewDetailRoute = APP_ROUTES.transaction.autoClosedReviewCases.caseDetails;

const unsubstantiatedCasesDetailRoute = APP_ROUTES.transaction.unsubstantiatedCases.caseDetails;

const notOpenedCaseDetailRoute = APP_ROUTES.transaction.notOpenedCases.caseDetails;

const beingProcessedCaseDetailRoute = APP_ROUTES.transaction.beingProcessedCases.caseDetails;

const efficiencyDashboardRoute = APP_ROUTES.efficiencyDashboard;

const healthPerformanceDashboardRoute = APP_ROUTES.caseStatistics;

const customerStatisticsDashboardRoute = APP_ROUTES.customerStatistics;

const platformPerformanceDashboardRoute = APP_ROUTES.platformPerformance;

const teamPerformanceDashboardRoute = APP_ROUTES.teamPerformance;

export const dashboardRoutes = [
  efficiencyDashboardRoute,
  healthPerformanceDashboardRoute,
  customerStatisticsDashboardRoute,
  platformPerformanceDashboardRoute,
  teamPerformanceDashboardRoute,
];

const settingsRoute = APP_ROUTES.settings;

const customerRiskRatingConfigForOrganizationRoute = APP_ROUTES.customerRiskRating;

const tenantConfigRoute = APP_ROUTES.workflowAndUsers;

const ruleManagerRoute = APP_ROUTES.ruleManager;

const listManagerRoute = APP_ROUTES.listManager;

const entityResolutionRoute = APP_ROUTES.entityResolution;

const experimentationRoute = APP_ROUTES.experimentation;

const dataFeedsRoute = APP_ROUTES.dataFeeds;

const machineIntelligenceRoute = APP_ROUTES.machineIntelligence;

const detachmentConfigRoute = APP_ROUTES.detachmentConfig;

const toolingRoute = APP_ROUTES.tooling;

const brandingRoute = APP_ROUTES.branding;

const tasksRoute = APP_ROUTES.tasks;
const caseQualityAssuranceRoute = APP_ROUTES.caseQualityAssurance;
const adhocScreeningRoute = APP_ROUTES.adhocScreening;

const customerListRoute = APP_ROUTES.customerList;

const customerDetailRoute = APP_ROUTES.customerList.customerDetail;

const configurationRoutes = [
  settingsRoute,
  customerRiskRatingConfigForOrganizationRoute,
  tenantConfigRoute,
  ruleManagerRoute,
  listManagerRoute,
  entityResolutionRoute,
  experimentationRoute,
  dataFeedsRoute,
  machineIntelligenceRoute,
  detachmentConfigRoute,
  toolingRoute,
  brandingRoute,
];

const auditNotesRoute = APP_ROUTES.auditTrail;
/**
 * ==============================
 * END OF DEPRECATION
 */

const routes = [
  logoutRoute,
  anyCasesRoute,
  APP_ROUTES.transaction.escalatedCases,
  APP_ROUTES.transaction.myCases,
  APP_ROUTES.transaction.customViewCases,
  openCasesRoute,
  closedCasesRoute,
  autoClosedCasesRoute,
  unsubstantiatedCasesRoute,
  autoClosedReviewCasesRoute,
  unsubstantiatedReviewCasesRoute,
  notOpenedCasesRoute,
  beingProcessedCasesRoute,
  anyCaseDetailRoute,
  myCaseDetailRoute,
  customViewDetailRoute,
  escalatedDetailCaseRoute,
  openCaseDetailRoute,
  caseFincenRegulatoryReport,
  closedCaseDetailRoute,
  autoClosedCaseDetailRoute,
  unsubstantiatedCaseDetailRoute,
  autoClosedReviewDetailRoute,
  unsubstantiatedCasesDetailRoute,
  notOpenedCaseDetailRoute,
  beingProcessedCaseDetailRoute,
  auditNotesRoute,
  tasksRoute,
  caseQualityAssuranceRoute,
  defaultLoginRoute,
  customLoginRoute,
  resetPasswordRoute,
  errorRoute,
  ...configurationRoutes,
  ...dashboardRoutes,
  ...customerCasesRoutes,
  ...customerListRoutes,
  adhocScreeningRoute,
];

const getMatchRoute = (pathname, exactMatch, partialRoutes) => {
  const searchRoutes = partialRoutes ?? routes;
  for (let i = 0; i < searchRoutes.length; i++) {
    const route = searchRoutes[i];
    const match = matchPath(pathname, {
      path: route.path,
      exact: exactMatch ?? route.exact,
    });
    if (match) {
      return {
        ...route,
        matchRoute: match,
      };
    }
  }
  return undefined;
};

export default routes;

export {
  logoutRoute,
  defaultLoginRoute,
  customLoginRoute,
  resetPasswordRoute,
  errorRoute,
  anyCasesRoute,
  myCasesRoute,
  customViewCasesRoute,
  escalatedCasesRoute,
  autoClosedReviewCasesRoute,
  unsubstantiatedReviewCasesRoute,
  openCasesRoute,
  closedCasesRoute,
  autoClosedCasesRoute,
  unsubstantiatedCasesRoute,
  notOpenedCasesRoute,
  beingProcessedCasesRoute,
  anyCaseDetailRoute,
  myCaseDetailRoute,
  customViewDetailRoute,
  escalatedDetailCaseRoute,
  openCaseDetailRoute,
  caseFincenRegulatoryReport,
  closedCaseDetailRoute,
  autoClosedCaseDetailRoute,
  unsubstantiatedCaseDetailRoute,
  autoClosedReviewDetailRoute,
  notOpenedCaseDetailRoute,
  beingProcessedCaseDetailRoute,
  tenantConfigRoute,
  auditNotesRoute,
  ruleManagerRoute,
  listManagerRoute,
  entityResolutionRoute,
  experimentationRoute,
  dataFeedsRoute,
  efficiencyDashboardRoute,
  healthPerformanceDashboardRoute,
  customerStatisticsDashboardRoute,
  platformPerformanceDashboardRoute,
  teamPerformanceDashboardRoute,
  machineIntelligenceRoute,
  detachmentConfigRoute,
  getMatchRoute,
  toolingRoute,
  brandingRoute,
  customerRiskRatingConfigForOrganizationRoute,
  settingsRoute,
  tasksRoute,
  caseQualityAssuranceRoute,
  customerListRoute,
  customerDetailRoute,
  APP_ROUTES,
  configurationRoutes,
  adhocScreeningRoute,
};
