import BlockIcon from '@mui/icons-material/Block';
import { Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ConditionalTooltip } from 'components/tooltip/tooltip';
import { useTranslation } from 'react-i18next';

export const BlockedChip = ({
  disabled,
  dataTestId = 'blocked',
  iconOnly = false,
  size = 'small',
  ruleInstance = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const label = ruleInstance ? t('blocking.heading') : t('blocked.heading');
  const tooltipLabel = ruleInstance ? t('blocking.subheading') : t('blocked.subheading');

  if (iconOnly) {
    return (
      <ConditionalTooltip title={tooltipLabel}>
        <BlockIcon fontSize={size} className="color-error block-icon" />
      </ConditionalTooltip>
    );
  }
  return (
    <Chip
      disabled={disabled}
      data-testid={dataTestId}
      style={theme.custom?.riskChipComponent?.high}
      size={size}
      label={label}
      icon={<BlockIcon style={theme.custom?.riskChipComponent?.high} className="block-icon" />}
    />
  );
};

export const NotBlockedText = ({ ruleInstance }) => {
  const { t } = useTranslation();
  const label = ruleInstance ? t('notBlocking.heading') : t('notBlocked.heading');

  return (
    <Typography variant="body2" color="textSecondary">
      {label}
    </Typography>
  );
};
