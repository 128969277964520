import { Card, Divider, Grid, ListItemIcon, ListItemText, MenuItem, Tab } from '@mui/material';
import { ReactComponent as InventoryIcon } from 'assets/images/icons/inventory.svg';
import ExecutionStatusChip from 'components/chips/execution-status-chip';
import IconButtons from 'components/controls/icon-button/icon-buttons';
import { DeleteCustomViewDialog } from 'components/custom-view/delete-custom-view-dialog';
import { EmptyState } from 'components/empty-state';
import { SkeletonLoader } from 'components/loaders/skeleton/skeleton';
import { LoadingContainer } from 'components/loaders/spinner';
import { MenuButton, MenuTriggerType } from 'components/menu-button/menu-button';
import TotalResult from 'components/typography/total-result';
import { useAuthorization } from 'hooks/use-authorization';
import { useCustomViews } from 'hooks/use-custom-views';
import { useDetachment } from 'hooks/use-detachment';
import { cloneDeep, isEmpty } from 'lodash';
import { CASE_ACTIONS } from 'middleware/action-types';
import { getDistinctCurrencyValuesByCaseStatus } from 'middleware/actions/case';
import { getSettings } from 'middleware/actions/settings';
import { useExecutionStatus } from 'modules/configuration/experimentation/hooks/use-execution-status';
import { getEnabledFilters } from 'modules/trx-cases/case-detail/utils/helpers/filters';
import CaseNavigator from 'modules/trx-cases/case-list/components/case-navigator';
import TrxCaseListHeader from 'modules/trx-cases/case-list/components/trx-case-list-header';
import { useCategoryWorkflowConfig } from 'modules/trx-cases/case-list/hooks/use-category-workflow-config';
import { useSearchUrl } from 'modules/trx-cases/case-list/hooks/use-search-url';
import { useUrlBasedTransactionFilters } from 'modules/trx-cases/case-list/hooks/use-url-based-transaction-filters';
import { getAllCases } from 'modules/trx-cases/case-list/middleware/actions/case-list';
import { getCustomViewsPath } from 'modules/trx-cases/case-list/utils/helpers/custom-views-path';
import { buildPreFilters } from 'modules/trx-cases/case-list/utils/helpers/filters';
import { buildTableHeadCells, TableCell } from 'modules/trx-cases/case-list/utils/helpers/table';
import { buildCaseMenuItems } from 'modules/trx-cases/case-list/utils/helpers/trx-case-list-utils';
import useMachineIntelligenceFeatures from 'modules/trx-cases/case-list/utils/helpers/use-machine-intelligence-features';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SessionStorageService from 'services/session-storage-service';
import { DETACHMENT_CONFIG } from 'utils/constants/detachment-config';
import PageSize from 'utils/constants/page-size';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';
import { canSeeAssignedUser, checkPermissions } from 'utils/helpers/permissions-util';
import RedirectUrl from 'utils/helpers/redirect-url';
import { enrichWithDefaults } from 'utils/helpers/url-utils/url-params-manager';
import { RootState } from 'middleware/store';
import { CustomView } from 'models/custom-view';
import { Filter } from 'models/api/search';
import { NestedFilter, Order } from 'models/case/search/group-search-request';
import { HeadCell } from 'modules/customer-data/types';
import { parseSortRequest } from 'utils/helpers/cases/case-utility';
import TrxTable from 'modules/trx-cases/case-list/components/trx-table';
import LayersIcon from '@mui/icons-material/Layers';

const sessionStorageService = new SessionStorageService();

interface CaseStatusFilter {
  values: string[];
  title: string;
  key: string;
}

type AllCasesProps = {
  nestedFilter: NestedFilter;
  caseStatusFilter: CaseStatusFilter;
  allMaskedDataShown: boolean;
  children?: React.ReactNode;
  excludedFilters?: string[];
  queryPreFilters?: any[];
};

interface FilterOption {
  key: string;
  form: Filter;
}

const AllCases: FC<AllCasesProps> = ({
  nestedFilter,
  caseStatusFilter,
  allMaskedDataShown,
  children,
  excludedFilters = [],
  queryPreFilters = [],
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const { cases = [], totalCases } = useSelector((state: RootState) => state.case);
  const { userPermissions } = useSelector((state: RootState) => state.authentication);
  const userId = useSelector((state: RootState) => state.authentication.user?.userId);
  const processing = useSelector((state: RootState) => state.processing);
  const tenantConfig = useSelector((state: RootState) => state.tenantConfig);
  const scrollId = useSelector((state: RootState) => state.case?.paging?.scrollId);
  const lastPage = useSelector((state: RootState) => state.case?.paging?.lastPage);
  const { detachmentConfig } = useAuthorization();
  const { autoCloseRelabel: isAutoCloseRelabelAttached } = useDetachment('autoCloseRelabel');
  const { isLoading: isCustomViewsLoading, customViews } = useCustomViews();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState<Filter[]>([]);
  const [viewToDelete, setViewToDelete] = useState<CustomView | undefined>(undefined);

  const { isFeatureAttached } = useAuthorization();
  const isInvestigationStatusAttached = isFeatureAttached('investigationStatus');
  const isCategoryWorkflowsAttached = isFeatureAttached('categoryWorkflows');

  const { categoryWorkflowConfig } = useCategoryWorkflowConfig();
  const categoryWorkflowFraudConfig = categoryWorkflowConfig?.categoryWorkflows?.fraud;

  const executionStatus = useExecutionStatus()?.data?.data?.status;

  const isClosedCasesTable = caseStatusFilter?.key === 'closed';

  const preFilters = useMemo(
    () => buildPreFilters(caseStatusFilter?.key, userId, nestedFilter),
    [caseStatusFilter, userId, nestedFilter],
  );
  const { isAnomalyScoreEnabled, isAutoCloseScoreEnabled } = useMachineIntelligenceFeatures();

  const alertPrioritization = useMemo(
    () => ({
      isAnomalyScoreEnabled,
      isAutoCloseScoreEnabled,
    }),
    [isAnomalyScoreEnabled, isAutoCloseScoreEnabled],
  );

  const excluded = excludedFilters.concat(
    preFilters.filter((x) => 'field' in x).map((x) => (x as Filter).field),
  );

  const isCategoryWorkflowAttached = isFeatureAccessible(
    DETACHMENT_CONFIG.configurations.categoryWorkflows,
    detachmentConfig,
  );

  const canSeeInvestigationOutcome =
    isCategoryWorkflowAttached &&
    categoryWorkflowFraudConfig?.active &&
    categoryWorkflowFraudConfig?.caseClosingLabelActive;

  const enabledFilters = getEnabledFilters({
    t,
    canSeeAssignedUser: canSeeAssignedUser(userPermissions),
    isInvestigationStatusAttached,
    isCategoryAttached: isCategoryWorkflowsAttached,
    excludedFilters: excluded,
    alertPrioritization: { isAnomalyScoreEnabled, isAutoCloseScoreEnabled },
    canSeeInvestigationOutcome,
    caseNotificationRuleInstanceFilterEnabled: true,
    isAutoCloseRelabelAttached: isAutoCloseRelabelAttached as boolean,
  });

  const [columnConfig, setColumnConfig] = useState<TableCell[]>(
    buildTableHeadCells(
      caseStatusFilter,
      userPermissions,
      t,
      isCategoryWorkflowsAttached,
      isInvestigationStatusAttached,
      alertPrioritization,
      isClosedCasesTable,
      isAutoCloseRelabelAttached as boolean,
    ),
  );

  // TODO: replace by detachment config check later
  useEffect(() => {
    setColumnConfig(
      buildTableHeadCells(
        caseStatusFilter,
        userPermissions,
        t,
        isCategoryWorkflowsAttached,
        isInvestigationStatusAttached,
        alertPrioritization,
        isClosedCasesTable,
        isAutoCloseRelabelAttached as boolean,
      ),
    );
  }, [
    caseStatusFilter,
    userPermissions,
    isCategoryWorkflowsAttached,
    isInvestigationStatusAttached,
    alertPrioritization,
    isClosedCasesTable,
    t,
    isAutoCloseRelabelAttached,
  ]);

  const handleChangeColumnConfig = (newColumnConfig: TableCell[]) => {
    setColumnConfig(newColumnConfig);
  };

  const { urlFilterOptions, sortingOptions, setSortingOptions } = useUrlBasedTransactionFilters(
    enabledFilters,
    categoryWorkflowConfig !== undefined,
  );

  const queryCases = (
    filterConfig: Filter[],
    queryCasesSortingOptions: Order[],
    resetScrollId: boolean | null,
  ) => {
    const scroll = resetScrollId ? null : scrollId;

    const filters = [...filterConfig, ...preFilters, ...queryPreFilters];

    if (filterConfig && queryCasesSortingOptions) {
      const query = {
        paging: {
          pageSize: PageSize.CASES,
          scrollId: scroll,
        },
        sorting: {
          orders: queryCasesSortingOptions,
        },
        filtering: {
          filters: cloneDeep(filters),
          operator: 'AND',
        },
        allMaskedDataShown,
      };
      dispatch(getAllCases(query));
    }
  };

  /**
   * handle filtering and attach time frame without adding it to state
   * @param {*} filterConfig
   */
  const handleFilterConfigChanges = (filterConfig: FilterOption[] | Filter[]) => {
    const filterData: Filter[] = [];

    filterConfig.forEach((filter: FilterOption | Filter) => {
      if ('form' in filter) {
        filterData.push(filter.form);
      } else {
        filterData.push(filter);
      }
    });

    setFilterOptions(filterData);
    queryCases(filterData, sortingOptions, true);
  };

  useSearchUrl(filterOptions, sortingOptions);

  /**
   * running single time on load
   */
  useEffect(
    () => {
      if (!isEmpty(caseStatusFilter)) {
        dispatch(getDistinctCurrencyValuesByCaseStatus(caseStatusFilter?.key.toUpperCase()));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    sessionStorageService.setUnmaskCount(0);
  });

  const requestMoreCases = () => {
    if (cases.length > 0) {
      setCurrentPage(currentPage + 1);
      queryCases(filterOptions, sortingOptions, null);
    }
  };

  RedirectUrl.save(location.pathname);

  /**
   * handling when receiving a sort request
   * @param {*} _
   * @param {*} headCell
   * @param {*} direction
   */
  const handleRequestSort = (
    _: React.MouseEvent<HTMLElement>,
    headCell: HeadCell,
    direction: string | undefined,
  ) => {
    const newSortingOptions = parseSortRequest(headCell, direction, sortingOptions);
    setSortingOptions(newSortingOptions);
    queryCases(filterOptions, newSortingOptions, true);
  };

  const caseTypes = buildCaseMenuItems(
    userPermissions,
    isAutoCloseRelabelAttached as unknown as boolean,
  );

  const { canSeeCaseDetails } = checkPermissions(userPermissions);

  const applyCustomViewRoute = (customView: CustomView) => {
    const newRoute = getCustomViewsPath();
    const searchEnrichedWithDefault = enrichWithDefaults(customView.value, location.search);
    history.push({
      pathname: newRoute,
      search: searchEnrichedWithDefault,
    });
  };

  const mapToMenuItem = (view: CustomView) => (
    <MenuItem key={view.name} dense disableGutters>
      <ListItemText sx={{ px: 2 }} primary={view.name} onClick={() => applyCustomViewRoute(view)} />
      <ListItemIcon onClick={() => setViewToDelete(view)}>
        <IconButtons.Delete size="small" edge="end" />
      </ListItemIcon>
    </MenuItem>
  );
  return (
    <>
      {!isCustomViewsLoading && (
        <CaseNavigator caseTypes={caseTypes} search={window.location.search}>
          {customViews?.length > 0 && (
            <Tab
              key={Number.MAX_SAFE_INTEGER}
              disableRipple
              value={getCustomViewsPath()}
              className="px-2 py-0 min-w-0 min-h-0 border-left"
              label={
                <MenuButton
                  title={t('case:caseList.customViews.savedViews.heading')}
                  isLoading={isCustomViewsLoading}
                  isSelected={getCustomViewsPath() === location.pathname}
                  trigger={MenuTriggerType.CHIP}
                  icon={<LayersIcon />}
                >
                  {customViews?.map((view) => mapToMenuItem(view))}
                </MenuButton>
              }
            />
          )}
        </CaseNavigator>
      )}
      <DeleteCustomViewDialog viewToDelete={viewToDelete} setViewToDelete={setViewToDelete} />
      <Card sx={{ position: 'relative', overflow: 'visible' }}>
        <ExecutionStatusChip executionStatus={executionStatus} distanceToParentMultiplier={8} />
        <Grid container id="scrollableDiv">
          <TrxCaseListHeader
            enabledFilters={enabledFilters}
            columnConfig={columnConfig}
            filterOptions={filterOptions}
            preFilters={preFilters}
            sortingOptions={sortingOptions}
            urlFilterOptions={urlFilterOptions}
            handleChangeColumnConfig={handleChangeColumnConfig}
            handleFilterConfigChanges={handleFilterConfigChanges}
            totalCases={totalCases}
            caseStatusFilter={caseStatusFilter}
          />

          <Grid item xs={12}>
            <Divider light />
          </Grid>

          {Boolean(totalCases || totalCases === 0) && (
            <Grid item className="pl-4">
              <TotalResult value={totalCases} />
            </Grid>
          )}

          <Grid item xs={12} className="ml-3">
            {children}
          </Grid>

          <Grid container item xs={12} style={{ display: 'table' }}>
            <InfiniteScroll
              dataLength={cases.length}
              next={requestMoreCases}
              hasMore={!lastPage}
              hasChildren={false}
              style={{ overflow: 'none', height: '100%' }}
              scrollableTarget="scrollableDiv"
              loader={undefined}
            >
              <TrxTable
                cases={cases}
                columnConfig={columnConfig}
                currentPath={currentPath}
                canSeeCaseDetails={canSeeCaseDetails}
                tenantConfig={tenantConfig}
                sortingOptions={sortingOptions}
                handleRequestSort={handleRequestSort}
              />
            </InfiniteScroll>

            {Boolean(!totalCases || totalCases === 0) && !processing.isProcessing && (
              <EmptyState
                icon={<InventoryIcon className="color-black-50 icon-75" />}
                heading={t('case:caseList.emptyList.heading')}
                subheading={t('case:caseList.emptyList.subheading')}
                dense={false}
              />
            )}

            <LoadingContainer actionType={CASE_ACTIONS.allCases.fetch.data}>
              <Grid container item xs={12} spacing={2} className="py-2 px-3">
                <SkeletonLoader variant="row" elementsPerRow={6} />
                <SkeletonLoader variant="row" elementsPerRow={6} />
              </Grid>
            </LoadingContainer>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AllCases;
