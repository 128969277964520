import {
  anyCasesRoute,
  APP_ROUTES,
  autoClosedCasesRoute,
  autoClosedReviewCasesRoute,
  beingProcessedCasesRoute,
  closedCasesRoute,
  escalatedCasesRoute,
  myCasesRoute,
  notOpenedCasesRoute,
  openCasesRoute,
} from 'utils/constants/routes';
import { isFeatureAccessible } from 'utils/helpers/feature-visibility';

export const buildCaseMenuItems = (userPermissions = [], isAutoCloseRelabelAttached = false) => {
  const caseMenuItems = [];

  if (isFeatureAccessible(APP_ROUTES.transaction.myCases.permission, userPermissions)) {
    caseMenuItems.push(myCasesRoute);
  }

  if (isFeatureAccessible(APP_ROUTES.transaction.openCases.permission, userPermissions)) {
    caseMenuItems.push(openCasesRoute);
  }

  if (isFeatureAccessible(APP_ROUTES.transaction.escalatedCases.permission, userPermissions)) {
    caseMenuItems.push(escalatedCasesRoute);
  }

  if (isFeatureAccessible(APP_ROUTES.transaction.closedCases.permission, userPermissions)) {
    caseMenuItems.push(closedCasesRoute);
  }

  if (
    !isAutoCloseRelabelAttached &&
    isFeatureAccessible(APP_ROUTES.transaction.autoClosedCases.permission, userPermissions)
  ) {
    caseMenuItems.push(autoClosedCasesRoute);
  }

  if (
    !isAutoCloseRelabelAttached &&
    isFeatureAccessible(APP_ROUTES.transaction.autoClosedReviewCases.permission, userPermissions)
  ) {
    caseMenuItems.push(autoClosedReviewCasesRoute);
  }

  if (isFeatureAccessible(APP_ROUTES.transaction.notOpenedCases.permission, userPermissions)) {
    caseMenuItems.push(notOpenedCasesRoute);
  }

  if (isFeatureAccessible(APP_ROUTES.transaction.beingProcessedCases.permission, userPermissions)) {
    caseMenuItems.push(beingProcessedCasesRoute);
  }

  caseMenuItems.sort((a, b) => (a.index > b.index ? 1 : -1));

  if (isFeatureAccessible(APP_ROUTES.transaction.anyCases.permission, userPermissions)) {
    caseMenuItems.splice(1, 0, anyCasesRoute);
  }

  return caseMenuItems;
};
