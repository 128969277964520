import React, { FC, useState } from 'react';
import { Box, ListItemText, Menu, MenuItem } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from 'components/loaders/loading-button';
import { LoadingChipButton } from 'components/loaders/loading-chip-button';

export type Option = {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  description?: string;
  message?: string;
};

type MenuButtonProps = {
  title: string;
  options?: Option[];
  isLoading: boolean;
  isSelected?: boolean;
  trigger?: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
};

export const MenuTriggerType = {
  BUTTON: 'BUTTON',
  CHIP: 'CHIP',
};

export const MenuButton: FC<MenuButtonProps> = ({
  title,
  options,
  isLoading,
  icon,
  isSelected,
  trigger = MenuTriggerType.BUTTON,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (callback: () => void) => {
    callback();
    closeMenu();
  };

  const renderTrigger = () => {
    switch (trigger) {
      case MenuTriggerType.CHIP:
        return (
          <LoadingChipButton
            title={title}
            icon={icon}
            onClick={openMenu}
            isSelected={isSelected || false}
            isLoading={isLoading}
          />
        );
      default:
        return (
          <LoadingButton
            iconButtonMode
            title={title}
            size="small"
            variant="outlined"
            onClick={openMenu}
            startIcon={<FileDownloadOutlinedIcon />}
            isLoading={isLoading}
            globalActionListener
          />
        );
    }
  };

  return (
    <>
      {renderTrigger()}
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={closeMenu}>
        <div onClick={closeMenu}>{children}</div>
        {options?.map((option) => (
          <MenuItem
            key={option.title}
            onClick={() => handleClick(option.onClick)}
            disabled={option.disabled}
          >
            <ListItemText
              primary={option.title}
              secondary={
                <Box sx={{ whiteSpace: 'pre-line' }} component="span">
                  {option.description && `${option.description}\n`}
                  {option.message}
                </Box>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
