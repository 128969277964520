import { isDevOrTestEnvironment } from 'utils/helpers/enviroment-util';

export const TenantCategoryKeys = {
  staging: 'STAGING',
  production: 'PRODUCTION',
  productionRealTime: 'PRODUCTION_REAL_TIME',
};

export const DefaultTenantCategoryKey = isDevOrTestEnvironment()
  ? TenantCategoryKeys.staging
  : TenantCategoryKeys.production;

export type TenantCategoryKey = typeof TenantCategoryKeys[keyof typeof TenantCategoryKeys];
