import { DetachmentConfig } from 'models/authorization/detachment';
import { KeyValueHeadingMap } from 'components/filters/models/filter';

export function updateProcessingStatusesByAutoClosedRelabeling(
  processingStatusList: KeyValueHeadingMap,
  isAutoCloseRelabelAttached: string | boolean | DetachmentConfig,
): KeyValueHeadingMap {
  if (!isAutoCloseRelabelAttached) {
    if (processingStatusList.UNSUBSTANTIATED) {
      delete processingStatusList.UNSUBSTANTIATED;
    }
    if (processingStatusList.unsubstantiated) {
      delete processingStatusList.unsubstantiated;
    }
  }

  return processingStatusList;
}
