export interface AutocloseData {
  isAutoclosed: boolean;
  reasons: string[];
}

export interface EntityName {
  nameType: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  fullName: string;
  nameId: number;
  score?: number;
  title?: string;
  suffix?: string;
}

export interface EntityAddress {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  region?: string;
  zip?: string;
  addressType?: string;
}

export interface EntityScreeningData {
  screeningArea: string;
  screeningTags?: string[];
  regionFilters?: string | string[];
  screeningListCode: string;
  screeningListName: string;
  active?: boolean;
  createdAt?: string;
  expiresOn?: string;
  updatedAt?: string;
  notes?: string;
}

export enum ScreeningEntityCountryAssociations {
  CITIZENSHIP = 'CITIZENSHIP',
  RESIDENCY = 'RESIDENCY',
  PEP = 'PEP_ASSOCIATION',
  NATIONALITY = 'NATIONALITY',
  HEADQUARTERS = 'HEADQUARTERS',
  OWNERSHIP = 'OWNERSHIP',
  FLAG = 'FLAG',
  SPECIAL_VOTING_RIGHT = 'SPECIAL_VOTING_RIGHT',
  OTHER = 'OTHER_COUNTRY_ASSOCIATION',
}
export type ScreeningEntityCountryAssociation = `${ScreeningEntityCountryAssociations}`;

// from hawkai_py_utils/screening/screening_entity.py
export interface ScreeningHitEntity {
  entityType: string;
  entityId: number;
  updatedAt: string;
  lastKnownUpdatedAt?: string;
  primaryName: EntityName;
  secondaryIds?: {
    id: string;
    value: string;
  }[];
  countryDetails?: {
    id: ScreeningEntityCountryAssociation;
    value: string;
  }[];
  dates?: {
    dateType: string;
    exact: string;
  }[];
  personalDetails?: {
    isDeceased?: boolean;
    gender?: 'M' | 'F';
    roles?: {
      roleType?: string;
      roleSince?: string;
      roleUntil?: string;
      roleName?: string;
      roleNotes?: string;
    }[];
  } | null;
  profileNotes?: string[] | null;
  // not found in Info4C
  sourceItems?: {
    sourceType?: 'SOURCE' | 'IMAGE'; // DJ only
    notes?: string; // DJ only
    url?: string;
  }[];
  identityDocuments?:
    | {
        id: string;
        value: string;
      }[]
    | null;
  addresses?: EntityAddress[] | null;
  otherNames?: EntityName[];
  screeningData: EntityScreeningData[];
  relatedEntities?: unknown[];
}

export interface ScreeningHit {
  score: number;
  record: any;
  entity: ScreeningHitEntity;
  taeField?: string;
  jsonPath?: string | null;
  configInstanceId: string;
  autocloseData?: AutocloseData;
  sanctionedEntityId?: number;
}

export const JSON_PATH_ROOT = '$.';

export const isJsonPath = (field?: string): boolean => field?.startsWith(JSON_PATH_ROOT) || false;

export const getLeafNode = (sourceFieldKey?: string): string =>
  sourceFieldKey?.split('.').pop()?.split('[')[0] || '';

export const DEFAULT_PARTY_DATA_PATH = 'masterData.accountHoldingPartyData';
export const PYTHON_ARRAY_SEPARATOR = '#';
export const ARRAY_INDEX_PLACEHOLDER = '{#}';
export const PARTY_PLACEHOLDER = '{PARTY}';

export const parseTaeField = (fieldToParse = ''): { taeField: string; arrayIndex: string } => ({
  taeField: fieldToParse.split(PYTHON_ARRAY_SEPARATOR)[0],
  arrayIndex: fieldToParse.split(PYTHON_ARRAY_SEPARATOR)[1] || '-1',
});

// for amlScreeningTaeFields and customerScreeningTaeFields
// all taeFields that can be configured need to be added here
// also, please add fullPath to i18n: mapping:nameTypes and
// key to configuration:ruleManager.amlRiskScreening.screeningTaeFields
export const SCREENING_TAE_FIELD_MAPPING: Record<
  string,
  {
    fullPath: string;
    needsPartySpecified?: boolean;
    pathToScreeningMatchPartyData: string;
    jsonPath: string;
    entityTypeFilters: string[];
  }
> = {
  hp_full_person_name: {
    fullPath: 'masterData.accountHoldingPartyData.personalDetails.personName.fullName',
    jsonPath:
      '$.transaction.masterData.accountHoldingPartyData.personalDetails.personName.fullName',
    entityTypeFilters: ['PERSON'],
    pathToScreeningMatchPartyData: 'masterData.accountHoldingPartyData',
  },
  cp_full_person_name: {
    fullPath: 'masterData.counterpartyData.personalDetails.personName.fullName',
    jsonPath: '$.transaction.masterData.counterpartyData.personalDetails.personName.fullName',
    entityTypeFilters: ['PERSON'],
    pathToScreeningMatchPartyData: 'masterData.counterpartyData',
  },
  hp_company_name: {
    fullPath: 'masterData.accountHoldingPartyData.companyDetails.companyName',
    jsonPath: '$.transaction.masterData.accountHoldingPartyData.companyDetails.companyName',
    entityTypeFilters: ['ORGANIZATION'],
    pathToScreeningMatchPartyData: 'masterData.accountHoldingPartyData',
  },
  hp_contact_name: {
    fullPath: 'masterData.accountHoldingPartyData.contactDetails.contactName',
    jsonPath: '$.transaction.masterData.accountHoldingPartyData.contactDetails.contactName',
    entityTypeFilters: ['PERSON', 'ORGANIZATION'],
    pathToScreeningMatchPartyData: 'masterData.accountHoldingPartyData',
  },
  hp_contact_name_extended: {
    fullPath: 'masterData.accountHoldingPartyData.contactDetails.contactNameExtended',
    jsonPath: '$.transaction.masterData.accountHoldingPartyData.contactDetails.contactNameExtended',
    entityTypeFilters: ['PERSON', 'ORGANIZATION'],
    pathToScreeningMatchPartyData: 'masterData.accountHoldingPartyData',
  },
  cp_company_name: {
    fullPath: 'masterData.counterpartyData.companyDetails.companyName',
    jsonPath: '$.transaction.masterData.counterpartyData.companyDetails.companyName',
    entityTypeFilters: ['ORGANIZATION'],
    pathToScreeningMatchPartyData: 'masterData.counterpartyData',
  },
  hp_holder_name: {
    fullPath: `${PARTY_PLACEHOLDER}.holderName`,
    jsonPath: `$.transaction.accountHoldingPartyAccount.holderName`,
    entityTypeFilters: ['PERSON', 'ORGANIZATION'],
    needsPartySpecified: true,
    pathToScreeningMatchPartyData: 'masterData.accountHoldingPartyData',
  },
  cp_holder_name: {
    fullPath: `${PARTY_PLACEHOLDER}.holderName`,
    jsonPath: `$.transaction.counterPartyAccount.holderName`,
    entityTypeFilters: ['PERSON', 'ORGANIZATION'],
    needsPartySpecified: true,
    pathToScreeningMatchPartyData: 'masterData.counterpartyData',
  },
  hp_bank_name: {
    fullPath: `${PARTY_PLACEHOLDER}.bankName`,
    jsonPath: `$.transaction.accountHoldingPartyAccount.bankName`,
    entityTypeFilters: [],
    needsPartySpecified: true,
    pathToScreeningMatchPartyData: '-',
  },
  cp_bank_name: {
    fullPath: `${PARTY_PLACEHOLDER}.bankName`,
    jsonPath: `$.transaction.counterPartyAccount.bankName`,
    entityTypeFilters: [],
    needsPartySpecified: true,
    pathToScreeningMatchPartyData: '-',
  },
  hp_bank_identifier: {
    fullPath: `${PARTY_PLACEHOLDER}.bankIdentifier`,
    jsonPath: `$.transaction.accountHoldingPartyAccount.bankIdentifier`,
    entityTypeFilters: ['ORGANIZATION'],
    needsPartySpecified: true,
    pathToScreeningMatchPartyData: '-',
  },
  cp_bank_identifier: {
    fullPath: `${PARTY_PLACEHOLDER}.bankIdentifier`,
    jsonPath: `$.transaction.counterPartyAccount.bankIdentifier`,
    entityTypeFilters: ['ORGANIZATION'],
    needsPartySpecified: true,
    pathToScreeningMatchPartyData: '-',
  },
  hp_shareholder_names: {
    fullPath: `masterData.accountHoldingPartyData.companyDetails.shareholders[${ARRAY_INDEX_PLACEHOLDER}].personalDetails.personName.fullName`,
    jsonPath: `$.transaction.masterData.accountHoldingPartyData.companyDetails.shareholders[:].personalDetails.personName.fullName`,
    entityTypeFilters: [],
    pathToScreeningMatchPartyData: `masterData.accountHoldingPartyData.companyDetails.shareholders[${ARRAY_INDEX_PLACEHOLDER}]`,
  },
  hp_staff_names: {
    fullPath: `masterData.accountHoldingPartyData.companyDetails.staff[${ARRAY_INDEX_PLACEHOLDER}].personalDetails.personName.fullName`,
    jsonPath: `$.transaction.masterData.accountHoldingPartyData.companyDetails.staff[:].personalDetails.personName.fullName`,
    entityTypeFilters: ['PERSON'],
    pathToScreeningMatchPartyData: `masterData.accountHoldingPartyData.companyDetails.staff[${ARRAY_INDEX_PLACEHOLDER}]`,
  },
  intermediary_names: {
    fullPath: `transactionProcessingDetails.intermediaries[${ARRAY_INDEX_PLACEHOLDER}].name`,
    jsonPath: `$.transaction.transactionProcessingDetails.intermediaries[:].name`,
    entityTypeFilters: [],
    pathToScreeningMatchPartyData: `transactionProcessingDetails.intermediaries[${ARRAY_INDEX_PLACEHOLDER}]`,
  },
  intermediary_holders: {
    fullPath: `transactionProcessingDetails.intermediaries[${ARRAY_INDEX_PLACEHOLDER}].account.holderName`,
    jsonPath: `$.transaction.transactionProcessingDetails.intermediaries[:].account.holderName`,
    entityTypeFilters: [],
    pathToScreeningMatchPartyData: `transactionProcessingDetails.intermediaries[${ARRAY_INDEX_PLACEHOLDER}]`,
  },
  usage: {
    fullPath: 'usage',
    jsonPath: '$.transaction.usage',
    entityTypeFilters: [],
    pathToScreeningMatchPartyData: '-',
  },
};

export interface Assessment {
  answer: string;
  question: string;
  questionId: string;
  userId: string;
  custom: { reasons: string };
  originCaseId?: string;
}
