import { EnhancedTableHead, SortingOption } from 'components/table/enhanced-table-head';
import TableBody from '@mui/material/TableBody';
import TrxCaseRowContainer from 'modules/trx-cases/case-list/components/row/trx-case-row-container';
import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { HeadCell } from 'modules/customer-data/types';
import { TableCell } from 'modules/trx-cases/case-list/utils/helpers/table';
import { TenantConfig } from 'models/tenant/tenant';

export interface TrxTableProps {
  cases: any[];
  columnConfig: TableCell[];
  currentPath: string;
  canSeeCaseDetails: boolean;
  tenantConfig: TenantConfig;
  sortingOptions: SortingOption[] | undefined;
  handleRequestSort: (
    _: React.MouseEvent<HTMLElement>,
    headCell: HeadCell,
    direction: string | undefined,
  ) => void;
}

const TrxTable: FC<TrxTableProps> = ({
  cases,
  columnConfig,
  currentPath,
  canSeeCaseDetails,
  tenantConfig,
  sortingOptions,
  handleRequestSort,
}) => (
  <TableContainer>
    <Table className="support-right-click" size="small" id="caseList" data-testid="case-list">
      <EnhancedTableHead
        sortingOptions={sortingOptions}
        headCells={columnConfig}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {cases.map((row, index) => {
          const caseDetailUrl = `${currentPath}/${row.groupId}`;
          return (
            <TrxCaseRowContainer
              columnConfig={columnConfig}
              testIdPrefix={`case-list-${index}`}
              key={row.groupId}
              item={row}
              canSeeCaseDetails={canSeeCaseDetails}
              detailUrl={caseDetailUrl}
              tenantConfig={tenantConfig}
            />
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TrxTable;
