import TooltipTable from 'components/tooltip-table';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import SessionService from 'modules/authentication/login/services/session-service';
import InfoTooltip from 'modules/configuration/info-tooltip';
import { PYTHON_ARRAY_SEPARATOR } from 'modules/trx-cases/case-detail/components/sanction-hit/models/screening-hit';
import CaseType from 'modules/trx-cases/case-detail/utils/constants/case-type';
import React from 'react';
import { Col, Progress, Row } from 'reactstrap';
import TenantManagementService from 'services/tenant/tenant-management-service';
import AssessmentAnswer from 'utils/constants/assessment-answer';
import AssessmentQuestion from 'utils/constants/assessment-question';
import CaseStatusFilters from 'utils/constants/case-status-filters';
import { FEATURE_STATUS, RISK_CATEGORY, TRANSACTION_PARTY } from 'utils/constants/constant';
import CountryRisk from 'utils/constants/country-risk';

import {
  getContextExplanation,
  getFeatureDisplay,
  getFeatureUnit,
  getReadableFeatureUnit,
} from 'utils/constants/data-feature-details';
import DataFeatureName from 'utils/constants/data-feature-name';
import DecisionName from 'utils/constants/decision-name';
import Party from 'utils/constants/party';
import RiskAssessment from 'utils/constants/risk-assessment';
import { SORT_DIRECTION } from 'utils/constants/sort-directions';
import TENANT_IDS from 'utils/constants/tenant-ids';
import USER_PERMISSIONS from 'utils/constants/user-permissions';
import VolumeRiskConfigurationTypes from 'utils/constants/volume-risk-configuration-type';
import VolumeRiskFeatureNames from 'utils/constants/volume-risk-feature-names';
import { getMasterDataParties } from 'utils/helpers/cases/case-party-utils';
import { isClosedCase, isOpenCase } from 'utils/helpers/cases/case-status-util';
import { toggleSort } from 'utils/helpers/cases/case-table-util';
import CustomStyleTenants from 'utils/helpers/custom-style-tenants';
import CustomStyles from 'utils/helpers/custom-styles';
import { isEmptyObject } from 'utils/helpers/examination-util';
import Strings from 'utils/helpers/locales/strings.en';
import getMasksFromObject from 'utils/helpers/mask-util';
import { formatMoney, randomHtmlId, toFixed } from 'utils/helpers/math-util';
import { hasPermission } from 'utils/helpers/permissions-util';
import { isEscalatedCase, isNewCase, isSarFilingCase } from 'utils/helpers/processing-status-util';
import RiskLevel from 'utils/helpers/risk-level';
import {
  makeReadableTitleFromDataFeatureName,
  makeReadableValueDependingOnUnit,
  shortenLongString,
  shortenSourceField,
} from 'utils/helpers/string-util';
import { checkURL } from 'utils/helpers/url-utils/url-util';

const auth = new SessionService();

export function isReadOnlyCase(caseDetail) {
  return isNewCase(caseDetail?.processingStatus);
}

export const isSameLoggedUser = (differentUsername) => {
  if (
    differentUsername === auth.getUsername() ||
    differentUsername === auth.getEmail() ||
    differentUsername === auth.getNickname() ||
    differentUsername === auth.getUserId()
  ) {
    return true;
  }
  return false;
};

export function isCaseAssignedToLoggedUser(caseDetail, auth) {
  const { assignedToUserId } = caseDetail || {};
  const userId = auth?.userId || auth?.user?.userId || {};
  return (
    assignedToUserId !== undefined &&
    assignedToUserId !== null &&
    userId !== undefined &&
    userId !== null &&
    assignedToUserId === userId
  );
}

export function isExpiredAssessment(assessment) {
  const expirationDateStr = ((assessment || {}).custom || {}).expirationDate || {};
  if (isEmptyObject(expirationDateStr)) {
    return false;
  }

  const now = new Date();
  const expirationDate = new Date(expirationDateStr);
  return expirationDate < now;
}

export function isMicroAssessmentAllowed(caseDetail, auth) {
  const { caseStatus, processingStatus } = caseDetail || {};
  const isEscalated = isEscalatedCase(processingStatus);
  const isSarFiling = isSarFilingCase(processingStatus);
  const isUserAssignedToCase = isCaseAssignedToLoggedUser(caseDetail, auth);
  const canReadCaseState =
    hasPermission(auth?.userPermissions, USER_PERMISSIONS.read.caseState) || false;

  if (isOpenCase(caseStatus)) {
    if (isEscalated || isSarFiling) {
      return canReadCaseState;
    }
    return isUserAssignedToCase;
  }
  return false;
}

export function getTransactionsFromCase(caseDetail) {
  return caseDetail ? caseDetail.transactions || [] : [];
}

/**
 * dynamic list to return index needed from transaction
 * @param {*} caseDetail
 * @param {*} idx
 * @returns
 */
export function getTransactionFromCaseAtIndex(caseDetail, idx) {
  if (caseDetail && idx >= 0) {
    const transactions = getTransactionsFromCase(caseDetail);
    return idx < transactions.length ? transactions[idx] || {} : {};
  }
  return {};
}

/**
 * getting transaction with index 0
 * @param {*} caseDetail
 * @returns
 */
export function getFirstTransactionFromCase(caseDetail) {
  return getTransactionFromCaseAtIndex(caseDetail, 0);
}

export function getAccountHolderPartyFromTransaction(transaction) {
  if (transaction) {
    return transaction.accountHoldingParty === Party.FUNDS_BENEFICIARY
      ? transaction.fundsBeneficiary || {}
      : transaction.fundsOriginator || {};
  }
  return {};
}

export function getCounterPartyFromTransaction(transaction) {
  if (transaction) {
    return transaction.accountHoldingParty === Party.FUNDS_BENEFICIARY
      ? transaction.fundsOriginator || {}
      : transaction.fundsBeneficiary || {};
  }
  return {};
}

export function getCustomerFromTransaction(transaction) {
  return getAccountHolderPartyFromTransaction(transaction).holderName || {};
}

export function getCustomerAccountFromTransaction(transaction) {
  return getAccountHolderPartyFromTransaction(transaction).accountNumber || {};
}

export function getCustomerAccountLookupIdFromTransaction(transaction) {
  return getAccountHolderPartyFromTransaction(transaction).accountLookupId || {};
}

export function getCustomerFromTransactions(transactions) {
  if (Array.isArray(transactions) && transactions.length > 0) {
    const firstCustomer = getCustomerFromTransaction(transactions[0]);
    let theSameCustomer = true;
    for (let i = 1; i < transactions.length; i++) {
      const nextCustomer = getCustomerFromTransaction(transactions[i]);
      if (firstCustomer.token !== nextCustomer.token) {
        theSameCustomer = false;
        break;
      }
    }
    return theSameCustomer ? firstCustomer : { name: 'Various' };
  }
  return {};
}

export function getHolderNameFromTransactionByParty(transaction, party) {
  if (transaction && party) {
    const obj =
      Party.FUNDS_BENEFICIARY === party
        ? transaction.fundsBeneficiary || {}
        : transaction.fundsOriginator || {};
    return obj.holderName || {};
  }
  return {};
}

export function getAccountNumberFromTransactionByParty(transaction, party) {
  if (transaction && party) {
    const obj =
      Party.FUNDS_BENEFICIARY === party
        ? transaction.fundsBeneficiary || {}
        : transaction.fundsOriginator || {};
    return obj.accountNumber || {};
  }
  return {};
}

// TODO: check if this can be deprecated or improved, see `willShowSanctionHit()`
/**
 * Return first sanction item that matches party or source field
 * @param {*} caseDetail
 * @param {*} party
 * @param {*} sourceField
 * @returns
 */
export function getSanctionHitFromCaseForParty(caseDetail, party, sourceField) {
  if (caseDetail && party) {
    const sanctionEvent = (caseDetail.sanctionEventV2 ?? caseDetail.sanctionEvent) || {};
    const sanctionHitList = sanctionEvent.sanctionHitList || [];
    if (Array.isArray(sanctionHitList) && sanctionHitList.length > 0) {
      return sanctionHitList.find((sh) => sh.party === party);
    }
  } else if (sourceField) {
    const sanctionHitList = caseDetail.sanctionHitList || [];
    if (Array.isArray(sanctionHitList) && sanctionHitList.length > 0) {
      return sanctionHitList.find((sh) => sh.sourceField === sourceField);
    }
  }
  return undefined;
}

/**
 * Return name matching score of a name in a hit, or 0 if name doesn't exist.
 */
export function getNameMatchScoreInHit(name, hit) {
  const nameDetails = hit?.nameDetails ?? [];
  for (let i = 0; i < nameDetails.length; i++) {
    if (nameDetails[i].singleStringName === name) {
      return nameDetails[i].score ?? 0;
    }
  }
  return 0;
}

/**
 * Return name matching score of a name in a case for a party, or 0 if name doesn't exist.
 */
export function getNameMatchScore(name, caseDetail, party) {
  const hits = getSanctionHitFromCaseForParty(caseDetail, party)?.hits ?? [];
  for (let i = 0; i < hits.length; i++) {
    const tempScore = getNameMatchScoreInHit(name, hits[i]);
    if (tempScore !== 0) {
      return tempScore;
    }
  }
  return 0;
}

/**
 * Return maximum name matching score in a hit.
 */
export function getMaxNameMatchScoreInHit(hit) {
  let maxScore = 0;
  const nameDetails = hit?.nameDetails ?? [];
  for (let i = 0; i < nameDetails.length; i++) {
    if (maxScore < nameDetails[i].score) {
      maxScore = nameDetails[i].score;
    }
  }
  return maxScore;
}

/**
 * Return maximum name matching score in a case for a party.
 */
export function getMaxNameMatchScore(caseDetail, party) {
  let maxScore = 0;
  const hits = getSanctionHitFromCaseForParty(caseDetail, party)?.hits ?? [];
  for (let i = 0; i < hits.length; i++) {
    const tempScore = getMaxNameMatchScoreInHit(hits[i]);
    if (maxScore < tempScore) {
      maxScore = tempScore;
    }
  }
  return maxScore;
}

/**
 * checks if the sanction hit is on the field `bankIdentifier`
 * to hide contextual replay, and for scoring
 */
const isBankIdentifierHit = (sourceFieldKey) =>
  sourceFieldKey === i18next.t('mapping:nameTypes.fundsOriginator_bankIdentifier.key') ||
  sourceFieldKey === i18next.t('mapping:nameTypes.fundsBeneficiary_bankIdentifier.key') ||
  sourceFieldKey === i18next.t('mapping:nameTypes.fundsBeneficiary_accountNumber.key') ||
  sourceFieldKey === i18next.t('mapping:nameTypes.fundsOriginator_accountNumber.key');

export function addMasked(maskeds, masked) {
  const currentMasked = maskeds.find((m) => m.token === masked.token);
  if (!!currentMasked && !currentMasked.decrypted && !!masked.decrypted) {
    currentMasked.decrypted = masked.decrypted;
  }
  if (maskeds && masked && masked.token && !currentMasked) {
    maskeds.push(masked);
  }
}

export function addMaskeds(maskeds, newList) {
  if (maskeds && newList) {
    newList.forEach((m) => addMasked(maskeds, m));
  }
}

export function getMaskedsFromTransaction(transaction) {
  return getMasksFromObject(transaction);
}

export function getMaskedsFromTransactions(transactions) {
  const maskeds = [];
  transactions.forEach((t) => addMaskeds(maskeds, getMaskedsFromTransaction(t)));
  return maskeds;
}

export function getMaskedsFromNetworkAnalysis(middleMen) {
  const transactions = [];
  middleMen.forEach((m) => {
    transactions.push(...m.income);
    transactions.push(...m.spend);
  });
  return getMaskedsFromTransactions(transactions);
}

export function getMaskedsFromPassthroughV3(middleMen) {
  const transactions = [
    ...Object.values(middleMen[0].incomeV2),
    ...Object.values(middleMen[0].spendV2),
  ];
  return getMaskedsFromTransactions(transactions);
}

export function getMaskedsFromSanctionEvent(sanctionEvent = {}) {
  const maskeds = [];
  const sanctionHitList = sanctionEvent.sanctionHitList || [];
  sanctionHitList.forEach((sh) => {
    if (sh.nameV2 && sh.nameV2.token && Array.isArray(sh.hits) && sh.hits.length > 0) {
      maskeds.push(sh.nameV2);
    }
  });
  return maskeds;
}

export function getMaskedsFromCase(caseDetail = {}, isSingleCase) {
  const maskeds = getMaskedsFromSanctionEvent(
    caseDetail.sanctionEventV2 ?? caseDetail.sanctionEvent,
  );
  const transaction = isSingleCase ? caseDetail : getFirstTransactionFromCase(caseDetail);

  addMaskeds(maskeds, getMaskedsFromTransaction(transaction));
  return maskeds;
}

export function getMaskedsFromCases(cases) {
  const maskeds = [];

  if (cases?.length) {
    cases.forEach((c) => addMaskeds(maskeds, getMaskedsFromCase(c)));
  } else if (cases.transactions?.length) {
    addMaskeds(maskeds, getMaskedsFromCase(cases, true));
  }

  return maskeds;
}

// TODO: performance, this method is called very often, allMasks is an array and we iterate to find the token
export function findMask(allMasks, mask) {
  if (allMasks && mask) {
    return allMasks.find((m) => m.token === mask.token) || { masked: mask.masked };
  }
  return {};
}

export function getRawDisplayMask(allMasks, mask) {
  const obj = findMask(allMasks, mask);
  return obj.unmasked ? obj.unmasked : obj.masked;
}

/**
 * DEPRECIATED use getMaskValue() instead
 * due to initilizaing empty strings with a length
 * @param {*} allMasks
 * @param {*} mask
 */
export function getDisplayMask(allMasks, mask) {
  const result = getRawDisplayMask(allMasks, mask);
  return result || ' ';
}

/**
 * getting value of a masked element
 * @param {*} allMasks
 * @param {*} mask
 */
export const getMaskValue = (allMasks, mask) => {
  const result = getRawDisplayMask(allMasks, mask);
  return result || '';
};

export const MASKED_VALUE = '***';

export const formatMaskedValue = (showUnmaskedData, allMasks, fieldObject) => {
  if (showUnmaskedData) {
    return getMaskValue(allMasks, fieldObject);
  }
  if (fieldObject?.masked) {
    return fieldObject?.masked;
  }
  return fieldObject?.token ? MASKED_VALUE : '';
};

export const formatMaskedArray = (showUnmaskedData, allMasks, dataArray) => {
  if (!dataArray) {
    return [];
  }
  const array = [];
  if (showUnmaskedData) {
    dataArray.forEach((it) => {
      const mask = findMask(allMasks, it);
      if (mask.unmasked) {
        array.push(mask.unmasked);
      }
    });
  } else {
    dataArray.forEach((it) => {
      if (it?.masked) {
        array.push(it.masked);
      } else if (it?.token) {
        array.push(MASKED_VALUE);
      }
    });
  }
  return array;
};
export function getRiskColorByAssessment(assessment) {
  if (assessment === RiskAssessment.ACCEPT || assessment === AssessmentAnswer.OK) {
    return 'green';
  }
  if (assessment === RiskAssessment.REJECT || assessment === AssessmentAnswer.NOK) {
    return 'red';
  }
  return 'orange';
}

export function getIndividualDecisionByName(caseDetail, decisionName) {
  const caseDetailInternal = caseDetail || {};
  const decisionTakenEvent = caseDetailInternal.decisionTakenEvent || {};
  const individualDecisions = decisionTakenEvent.individualDecisions || [];
  return individualDecisions.find((d) => d.name === decisionName) || {};
}

/**
 * Detecting if its a phishing transaction according to phishing score value and threshold
 * @param {*} caseDetail
 */
export function isPhishingTransaction(caseDetail, detachmentConfig, decisionEngineConfig) {
  const typesString = caseDetail.typesString || '';
  const decision = getIndividualDecisionByName(caseDetail, DecisionName.PHISHING_DECISION);

  const decisionTakenEvent = caseDetail.decisionTakenEvent || {};
  const dataFeatures = decisionTakenEvent.dataFeatures || {};
  const phishingFeature = dataFeatures[DataFeatureName.PHISHING_SCORE] || {};

  const phishingFeatureValue = phishingFeature.value || 0;

  return (
    detachmentConfig.phishing === FEATURE_STATUS.attached &&
    typesString.indexOf(CaseType.PHISHING) >= 0 &&
    isOpenCase(decision.caseStatus) &&
    phishingFeatureValue >= decisionEngineConfig.phishingThreshold
  );
}

/**
 * Retrieve metadata from caseDetail.metadataComputedEvent matching the key
 * @param {*} caseDetail
 * @param {string} key
 */
export function getMetadata(caseDetail = {}, key) {
  return caseDetail?.metadataComputedEvent?.metadatas?.find((mt) => mt.key === key);
}

export function getMetadataPrefixString(caseDetail = {}, keyPrefix) {
  return caseDetail?.metadataComputedEvent?.metadatas?.find((mt) => mt.key.startsWith(keyPrefix));
}

/**
 * Retrieve metadata.values from caseDetail.metadataComputedEvent matching the key
 * @param {*} caseDetail
 * @param {string} key
 */
export function getMetadataValues(caseDetail = {}, key) {
  return getMetadata(caseDetail, key)?.values;
}

/**
 * Detecting if it's a fan-in transaction according to fan-in score value and threshold
 * @param {*} caseDetail
 */
export function isFanInTransaction(caseDetail, detachmentConfig) {
  const decision = getIndividualDecisionByName(caseDetail, DecisionName.FAN_IN_DECISION);
  const typesString = caseDetail.typesString || '';

  return (
    detachmentConfig.fanIn === FEATURE_STATUS.attached &&
    typesString.indexOf(CaseType.FAN_IN) >= 0 &&
    isOpenCase(decision.caseStatus)
  );
}

export function isMoneyMuleTransaction(caseDetail) {
  // TODO: remove it once all data features available
  const transaction = getFirstTransactionFromCase(caseDetail);
  const fundsOriginator = transaction.fundsOriginator || {};
  const accountNumber = fundsOriginator.accountNumber || {};
  if (
    accountNumber.token === '1f6ad451-3f54-4cc2-bc43-da0fd257c1cd' ||
    accountNumber.token === '9b422b3d-5377-4d6a-8dfb-88f394e870d7'
  ) {
    if (caseDetail.types.find((t) => t.name === CaseType.MONEY_MULE) === undefined) {
      caseDetail.types.push({
        name: CaseType.MONEY_MULE,
        description: 'Money Mule',
      });
    }

    // eslint-disable-next-line no-param-reassign
    caseDetail.fakeMoneyMuleData = {
      confidence: 0.932,
      readableDataFeatures: [
        {
          barValue: 89,
          description: 'Fast movement of money in/out from/to unusual source/destination',
        },
        {
          barValue: 78,
          description: 'Unusual and irregular source of money for account holder',
        },
        {
          barValue: 75,
          description:
            'Similar potential money mule originator/destination with different customer',
        },
        {
          barValue: 72,
          description: 'Similar potential money mule originator/destination on other bank',
        },
        {
          barValue: 61,
          description: 'High Money mule target risk customer segment',
        },
      ],
    };

    const createdAt2 = caseDetail.createdAt;
    const createdAt = new Date(createdAt2);
    createdAt.setDate(createdAt.getDate() - 1);

    const incomeDate = new Date(createdAt);
    incomeDate.setDate(incomeDate.getDate() - 4);

    const incomeDate2 = new Date(createdAt);
    incomeDate2.setDate(incomeDate2.getDate() - 3);
    // eslint-disable-next-line no-param-reassign
    caseDetail.fakeNetworkAnalysis = {
      otherBank: {
        name: '3 Accounts',
        info: 'Other banks monitored by hawk:AI',
        income: {
          amount: 18000,
          minDate: incomeDate,
          maxDate: incomeDate2,
          numOfTransactions: '3 transactions',
        },
        spend: {
          amount: 17500,
          minDate: createdAt,
          maxDate: createdAt2,
          numOfTransactions: '4 transactions',
        },
      },
      fundsOriginator: {
        fullName: {
          unmasked: 'Trevor Goodwin',
        },
        title: '(Suspected Mule Operator)',
        numOfTransactions: 9,
      },
      fundsBeneficiary: {
        fullName: {
          unmasked: 'Hong Chan',
        },
        title: '(Suspected Mule Operator)',
        numOfTransactions: 11,
      },
      transformedMiddleMen: [
        {
          fullName: {
            unmasked: 'Tuan Nguyen',
          },
          current: true,
          income: {
            amount: 10000,
            minDate: incomeDate,
            maxDate: incomeDate2,
            numOfTransactions: '2 transactions',
          },
          spend: {
            amount: 9800,
            minDate: createdAt,
            maxDate: createdAt2,
            numOfTransactions: 'current + 1 transactions',
          },
        },
        {
          fullName: {
            unmasked: 'Hans Brandon',
          },
          income: {
            amount: 16000,
            minDate: incomeDate,
            maxDate: incomeDate2,
            numOfTransactions: '2 transactions',
          },
          spend: {
            amount: 15900,
            minDate: createdAt,
            maxDate: createdAt2,
            numOfTransactions: '3 transactions',
          },
        },
        {
          fullName: {
            unmasked: 'Rosetta Hayes',
          },
          income: {
            amount: 7000,
            minDate: incomeDate,
            maxDate: incomeDate,
            numOfTransactions: '1 transaction',
          },
          spend: {
            amount: 6900,
            minDate: createdAt,
            maxDate: createdAt,
            numOfTransactions: '1 transaction',
          },
        },
        {
          fullName: {
            unmasked: 'Josef',
          },
          income: {
            amount: 10000,
            minDate: incomeDate,
            maxDate: incomeDate,
            numOfTransactions: '1 transaction',
          },
          spend: {
            amount: 9900,
            minDate: createdAt,
            maxDate: createdAt,
            numOfTransactions: '1 transaction',
          },
        },
      ],
    };

    return true;
  }

  const typesString = caseDetail.typesString || '';
  const decision = getIndividualDecisionByName(caseDetail, DecisionName.MONEY_MULE_DECISION);
  const decisionTakenEvent = caseDetail.decisionTakenEvent || {};
  const dataFeatures = decisionTakenEvent.dataFeatures || {};
  const moneyMuleFeature = dataFeatures['money-mule'] || {};
  const moneyMuleFeatureValue = moneyMuleFeature.value || 0;
  // TODO: 0.6 is value from backend
  const MINIMUM_PERCENTAGE_TO_INDICATE_MONEY_MULE = 0.6;
  return (
    typesString.indexOf('MONEY_MULE') >= 0 ||
    isOpenCase(decision.caseStatus) ||
    moneyMuleFeatureValue >= MINIMUM_PERCENTAGE_TO_INDICATE_MONEY_MULE
  );
}

export function getAIConfidence(caseDetail, decisionName) {
  const decision = getIndividualDecisionByName(caseDetail, decisionName);
  const labels = decision.labelsV2 || [];
  const label = labels.length > 0 ? labels[0] : {};
  return label.confidence;
}

export function getReferenceAccountNumberToken(transaction) {
  const masterData = transaction.masterData || {};
  const accountHoldingPartyData = masterData.accountHoldingPartyData || {};
  const referenceAccount = accountHoldingPartyData.referenceAccount || {};
  const accountNumber = referenceAccount.accountNumber || {};
  return accountNumber.token || '';
}

export function getReferenceAccountLookupIdToken(transaction) {
  const masterData = transaction.masterData || {};
  const accountHoldingPartyData = masterData.accountHoldingPartyData || {};
  const referenceAccount = accountHoldingPartyData.referenceAccount || {};
  const accountLookupId = referenceAccount.accountLookupId || {};
  return accountLookupId.token || '';
}

export function getfundsOriginatorFromTransaction(transaction) {
  if (transaction) {
    return transaction.fundsOriginator || {};
  }
  return {};
}

export function calculateTotalAmount(transactions, useSettledFundsInBaseCurrency = true) {
  if (!Array.isArray(transactions)) {
    return 0;
  }

  let result = 0;
  transactions.forEach((t) => {
    result +=
      useSettledFundsInBaseCurrency &&
      !!t.settledFundsInBaseCurrency &&
      !!t.settledFundsInBaseCurrency.value
        ? t.settledFundsInBaseCurrency.value
        : t.settledFunds.value;
  });

  return result;
}

export function sortTransactions(transactions) {
  if (!Array.isArray(transactions) || transactions.length === 0) {
    return false;
  }
  transactions.sort(
    (t1, t2) => new Date(t2.processedAt).getTime() - new Date(t1.processedAt).getTime(),
  );
  return true;
}

export function getMinMaxProcessedAt(transactions) {
  if (!sortTransactions(transactions)) {
    return {};
  }

  return {
    maxProcessedAt: transactions[0].processedAt,
    minProcessedAt: transactions[transactions.length - 1].processedAt,
  };
}

export function filterTransactionsByOriginatorAccountNumber(transactions, token) {
  if (!Array.isArray(transactions)) {
    return transactions;
  }

  return transactions.filter(
    (t) => token === undefined || token === null || token === t.fundsOriginator.accountNumber.token,
  );
}

export function getDataFeatureByName(dataFeatures = {}, featureName = '') {
  const result = {
    confidence: 0,
    contributions: {},
    type: '',
    matched: false,
  };

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const featureKey in dataFeatures) {
    const confidenceValue = dataFeatures[featureKey].value || 0;
    if (featureKey === featureName && confidenceValue >= 0.6) {
      result.confidence = dataFeatures[featureKey].value;
      result.contributions = dataFeatures[featureKey].contributions;
      result.type = dataFeatures[featureKey].type;
      result.matched = true;
      break;
    }
  }

  return result;
}

export function renderDataFeatureRows(
  dataFeatureInfo,
  showAll,
  minShowValue,
  dataFeatures,
  featureName,
) {
  const decisiveData = [];
  const { confidence } = dataFeatureInfo;
  const { contributions } = dataFeatureInfo;

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in contributions) {
    decisiveData.push({
      shap: contributions[key].importance.shap,
      title:
        Strings.DecisiveDataFeatures[key] === undefined
          ? makeReadableTitleFromDataFeatureName(contributions[key].name)
          : Strings.DecisiveDataFeatures[key],
      name: contributions[key].name,
    });
  }

  decisiveData.sort((a, b) => (a.shap < b.shap ? 1 : -1));
  const maxShap = decisiveData.length > 0 ? decisiveData[0].shap : 0;

  const rows = decisiveData.map((m, idx) => {
    const barValue = (Math.abs(m.shap) / maxShap) * confidence * 100;
    const barText = toFixed(barValue / 10, 1);
    const negative = m.shap < 0.0;

    const randId = randomHtmlId();
    const readableUnit = getReadableFeatureUnit(m.name);
    const unitPrefix = readableUnit.length > 0 ? `${readableUnit} ` : '';

    const feature = dataFeatures[m.name] || {};
    const value =
      dataFeatures === undefined
        ? undefined
        : makeReadableValueDependingOnUnit(feature.value, getFeatureUnit(m.name));

    const contextExplanation = getContextExplanation(featureName, m.name);
    const tooltipRows = [['Value', unitPrefix + value]];
    if (contextExplanation !== undefined && contextExplanation.length > 0) {
      tooltipRows[1] = ['Explanation', contextExplanation];
    }

    const renderInfoAndTooltip = unitPrefix.length > 0 && value !== undefined && value.length > 0;

    if (!showAll ? barValue >= minShowValue && !negative : true) {
      return (
        <Row key={idx}>
          <Col md="2" className="align-self-md-center" style={{ paddingLeft: 15 }}>
            <div className="progress">
              {negative ? (
                <Progress color="warning" bar value={barValue}>
                  {barText}
                </Progress>
              ) : (
                <Progress color="secondary" bar value={barValue}>
                  {barText}
                </Progress>
              )}
            </div>
          </Col>
          <Col className="align-self-md-center no-left-right bar-title">
            {m.title}{' '}
            {renderInfoAndTooltip && (
              <span>
                {` (${unitPrefix}${value}) `}
                <InfoTooltip id={randId}>
                  <TooltipTable targetId={randId} header={m.title} rows={tooltipRows} />
                </InfoTooltip>
              </span>
            )}
          </Col>
        </Row>
      );
    }
    return null;
  });

  return rows;
}

export function getProviderFromScreeningHitEvent(caseDetail) {
  const screeningHitEvents = caseDetail.screeningHitEvents || [];
  try {
    const record = screeningHitEvents[screeningHitEvents.length - 1]?.hits[0]?.record || {};

    return record.provider;
  } catch (e) {
    return undefined;
  }
}

export const OLD_BLACKLIST = 'BLACKLIST';

export function getSanctionEventProvider(caseDetail, configInstanceId) {
  if (!caseDetail) {
    return '';
  }
  let sanctionEvent;

  if (configInstanceId) {
    sanctionEvent = (
      (caseDetail.configInstanceSanctionEventsV2 ?? caseDetail.configInstanceSanctionEvents) ||
      caseDetail.sanctionEvents
    ).find((sanctionEvent) => sanctionEvent?.configInstanceId === configInstanceId);
  } else {
    sanctionEvent =
      caseDetail?.sanctionEvents?.find(
        (sanctionEvent) => sanctionEvent.sanctionEventType === null,
      ) ||
      (caseDetail.sanctionEventV2 ?? caseDetail.sanctionEvent) ||
      {};
  }

  const indicationOfSource = sanctionEvent?.indicationOfSource || {};
  return indicationOfSource?.provider || OLD_BLACKLIST;
}

export function tryGetProvider(caseDetail, configInstanceId, fromScreeningHitEvent = false) {
  let provider;
  if (fromScreeningHitEvent) {
    provider = getProviderFromScreeningHitEvent(caseDetail);
    return provider || 'blacklist';
  }
  return getSanctionEventProvider(caseDetail, configInstanceId);
}

export function buildPepSanctionQuestionKey(
  provider,
  providerId,
  hitSourceField,
  holderNameToken,
  accountNumberToken,
  instanceId,
  supportIndexField = false,
) {
  let questionKey =
    provider !== OLD_BLACKLIST
      ? `${AssessmentQuestion.PEPSANCTION_RISK_ACCEPTABLE_FOR_PARTIES_ID}-${provider}-${providerId}`
      : `${AssessmentQuestion.BLACKLIST_RISK_ACCEPTABLE_FOR_PARTIES_ID}-blacklist-${providerId}`;

  if (hitSourceField) {
    const shortenedField = supportIndexField
      ? shortenSourceField(hitSourceField)
      : shortenLongString(hitSourceField);
    questionKey = `${questionKey}-${shortenedField}`;
  }

  if (holderNameToken) {
    questionKey = `${questionKey}-${holderNameToken}`;
  }

  if (accountNumberToken) {
    questionKey = `${questionKey}-${accountNumberToken}`;
  }

  if (instanceId) {
    questionKey = `${questionKey}-${instanceId}`;
  }

  return questionKey;
}

export function findPepSanctionAssessment(
  caseDetail,
  hitData,
  hitSourceField = '',
  holderNameToken = '',
  accountNumberToken = '',
  configInstanceId,
  fromScreeningHitEvent = false,
  sanctionOperatorMatchRequestId,
  sanctionOperatorMatchMap = {},
) {
  const pepSanctionRisk = caseDetail.pepSanctionRisk || {};
  const assessmentAnnouncedEvents = pepSanctionRisk.assessmentAnnouncedEvents || {};
  const provider = tryGetProvider(caseDetail, configInstanceId, fromScreeningHitEvent);
  const questionKey = buildPepSanctionQuestionKey(
    provider,
    hitData.sanctionedEntityId,
    hitSourceField,
    holderNameToken,
    accountNumberToken,
    configInstanceId,
  );

  let assessmentAnnouncedEvent = assessmentAnnouncedEvents[questionKey];

  const FO_HN = 'fundsOriginator.holderName';
  const FB_HN = 'fundsBeneficiary.holderName';
  if (!assessmentAnnouncedEvent && hitSourceField === FO_HN) {
    const questionWithFbHn = buildPepSanctionQuestionKey(
      provider,
      hitData.sanctionedEntityId,
      FB_HN,
      holderNameToken,
      accountNumberToken,
      configInstanceId,
    );
    assessmentAnnouncedEvent = assessmentAnnouncedEvents[questionWithFbHn];
  }
  if (!assessmentAnnouncedEvent && hitSourceField === FB_HN) {
    const questionWithFoHn = buildPepSanctionQuestionKey(
      provider,
      hitData.sanctionedEntityId,
      FO_HN,
      holderNameToken,
      accountNumberToken,
      configInstanceId,
    );
    assessmentAnnouncedEvent = assessmentAnnouncedEvents[questionWithFoHn];
  }

  if (!assessmentAnnouncedEvent && hitSourceField.includes('[') && hitSourceField.includes(']')) {
    const questionWithIndexKey = buildPepSanctionQuestionKey(
      provider,
      hitData.sanctionedEntityId,
      hitSourceField,
      holderNameToken,
      accountNumberToken,
      configInstanceId,
      true,
    );
    assessmentAnnouncedEvent = assessmentAnnouncedEvents[questionWithIndexKey];
  }

  // Fallback to old map key for backward compatible
  if (!assessmentAnnouncedEvent) {
    const questionKey2 = buildPepSanctionQuestionKey(
      provider,
      hitData.sanctionedEntityId,
      hitSourceField,
    );
    assessmentAnnouncedEvent = assessmentAnnouncedEvents[questionKey2];
  }

  // use response from assessSanctionHit instead of waiting for get caseDetailed
  // helpful in case of slow processing assessmentAnnouncedEvent
  if (
    !assessmentAnnouncedEvent &&
    !isEmpty(sanctionOperatorMatchMap) &&
    sanctionOperatorMatchRequestId
  ) {
    assessmentAnnouncedEvent = {
      assessment: sanctionOperatorMatchMap[sanctionOperatorMatchRequestId],
    };
  }

  return assessmentAnnouncedEvent?.assessment || {};
}

export function findPepSanctionAssessmentForCustomerCases(
  caseDetail,
  hitData,
  hitSourceField = '',
  holderNameToken = '',
  accountNumberToken = '',
  configInstanceId,
) {
  const pepSanctionRisk = caseDetail.pepSanctionRisk || {};
  const assessmentAnnouncedEvents = pepSanctionRisk.assessmentAnnouncedEvents || {};

  const provider = getSanctionEventProvider(caseDetail, configInstanceId);
  const questionKey = buildPepSanctionQuestionKey(
    provider,
    hitData.sanctionedEntityId,
    hitSourceField,
    holderNameToken,
    accountNumberToken,
    configInstanceId,
  );

  const assessmentAnnouncedEvent = assessmentAnnouncedEvents[questionKey];
  return assessmentAnnouncedEvent?.assessment || {};
}

export function getMapFinTechSelectStyle(tenantId) {
  let selectComponentStyle = {};
  const customStyleInfo = CustomStyleTenants.find((t) => t.id === tenantId);
  if (customStyleInfo) {
    selectComponentStyle = customStyleInfo.selectComponentStyle;
  } else {
    selectComponentStyle = CustomStyles.defaultSelectComponent;
  }

  return selectComponentStyle;
}

export function getMasterDataFromCase(caseDetail) {
  const transaction = getFirstTransactionFromCase(caseDetail);
  return transaction.masterData;
}

export function getIdentityDocumentLinks(masterData, allMasks) {
  if (!masterData) {
    return [];
  }

  const imagesUrls = [];

  const accountHoldingPartyData = masterData.accountHoldingPartyData || {};
  const personalDetails = accountHoldingPartyData.personalDetails || {};
  const identityDocumentLinks = personalDetails.identityDocumentLinks || [];

  identityDocumentLinks.forEach((doc) => {
    const link = doc.link || {};
    const mask = findMask(allMasks, link);
    const unmaskedStr = mask.unmasked;
    if (unmaskedStr) {
      if (checkURL(unmaskedStr)) {
        imagesUrls.push(unmaskedStr);
      }
    }
  });

  return imagesUrls;
}

export function getCustomerAccountCountry(caseDetail) {
  const transactions = caseDetail.transactions || [];
  const transaction = transactions[0] || {};
  const countryRisk = caseDetail.countryRisk || {};

  return transaction.accountHoldingParty === Party.FUNDS_BENEFICIARY
    ? countryRisk.beneficiaryIbanCountry
    : countryRisk.originatorIbanCountry;
}

export function getAuditTrackMessage(transaction) {
  let auditTrackMessage = '';
  if (isClosedCase(transaction?.caseStatus) || isEscalatedCase(transaction?.processingStatus)) {
    const auditTracks = transaction.auditTracks || [];

    const auditTrack = auditTracks.find(
      (at) =>
        at.event === 'STATE_CHANGE' &&
        (at.caseStateChange.action === 'ACCEPT' || at.caseStateChange.action === 'ESCALATE'),
    );
    if (auditTrack) {
      auditTrackMessage = auditTrack.message;
    }
  }

  const commentStartIndex = auditTrackMessage.indexOf('Notes');
  const rawComment = auditTrackMessage.substring(commentStartIndex, auditTrackMessage.length);
  const brIndex = rawComment.indexOf('<br />');
  return rawComment.substring(brIndex + 6, rawComment.length);
}

export function getRiskCountry(countryRisk) {
  let highestCountryRisk;
  if (countryRisk.beneficiaryIbanCountryRisk === CountryRisk.HIGH) {
    highestCountryRisk = countryRisk.beneficiaryIbanCountry;
  } else if (countryRisk.originatorIbanCountryRisk === CountryRisk.HIGH) {
    highestCountryRisk = countryRisk.originatorIbanCountry;
  } else if (countryRisk.beneficiaryIbanCountryRisk === CountryRisk.MEDIUM) {
    highestCountryRisk = countryRisk.beneficiaryIbanCountry;
  } else if (countryRisk.originatorIbanCountryRisk === CountryRisk.MEDIUM) {
    highestCountryRisk = countryRisk.originatorIbanCountry;
  } else if (countryRisk.beneficiaryIbanCountryRisk === CountryRisk.LOW) {
    highestCountryRisk = countryRisk.beneficiaryIbanCountry;
  } else {
    highestCountryRisk = countryRisk.originatorIbanCountry;
  }

  return highestCountryRisk;
}

export function isCustomerInHighOrMediumCountryRisk(transaction, countryRisk) {
  if (
    transaction.accountHoldingParty === Party.FUNDS_BENEFICIARY &&
    (countryRisk.beneficiaryIbanCountryRisk === CountryRisk.HIGH ||
      countryRisk.beneficiaryIbanCountryRisk === CountryRisk.MEDIUM)
  ) {
    return true;
  }
  if (
    transaction.accountHoldingParty === Party.FUNDS_ORIGINATOR &&
    (countryRisk.originatorIbanCountryRisk === CountryRisk.HIGH ||
      countryRisk.originatorIbanCountryRisk === CountryRisk.MEDIUM)
  ) {
    return true;
  }

  return false;
}

export function getCounterpartyData(transaction) {
  const masterData = transaction.masterData || {};
  return masterData.counterpartyData || {};
}

export function getVolumeRiskDisplayData(volumeRisk = {}) {
  const { transactionCount } = volumeRisk;
  const transactionCountText =
    !Number.isNaN(transactionCount) && transactionCount > 1
      ? `${transactionCount} transactions`
      : `${transactionCount} transaction`;

  const { amountDistance } = volumeRisk;
  const { amountAcceleratorMultiplier } = volumeRisk;
  const acceleratorFactorExists =
    !Number.isNaN(amountAcceleratorMultiplier) && amountAcceleratorMultiplier > 0;

  let additionalText = '';

  if (!Number.isNaN(amountDistance) && amountDistance > 0) {
    additionalText = `The transaction amount is varied ${Number(amountDistance).toFixed(
      0,
    )}% to the mean.`;
  }

  let threshold = volumeRisk.configuredThreshold;
  let thresholdType = '';

  if (acceleratorFactorExists) {
    const amountAcceleratorThreshold = volumeRisk.configuredThreshold * amountAcceleratorMultiplier;

    if (volumeRisk.totalAggregatedAmount >= amountAcceleratorThreshold) {
      threshold = amountAcceleratorThreshold;
      thresholdType = ' accelerated';

      additionalText = `The threshold is accelerated to ${formatMoney(
        amountAcceleratorThreshold,
      )} (threshold of ${formatMoney(
        volumeRisk.configuredThreshold,
      )} * accelation factor of ${formatMoney(amountAcceleratorMultiplier)})`;
    }
  }
  return { transactionCountText, thresholdType, threshold, additionalText };
}

export function findOpenDecisionByName(individualDecisions, decisionName) {
  return individualDecisions.find((d) => d.name === decisionName && d.caseStatus === 'OPEN');
}

export function findDecisionByName(individualDecisions, decisionName) {
  if (!Array.isArray(individualDecisions) || !decisionName) {
    return undefined;
  }
  return individualDecisions.find((d) => d.name === decisionName);
}

function getVolumeRiskDataFeatures(contributions, featureList) {
  const hits = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in contributions) {
    const featureName = featureList.find((n) => n === key);
    if (featureName) {
      hits.push(contributions[key]);
    }
  }

  return hits;
}

export function getVolumeRiskHits(contributions, configurationType) {
  if (configurationType === VolumeRiskConfigurationTypes.TRANSFER_BASED) {
    return getVolumeRiskDataFeatures(contributions, VolumeRiskFeatureNames.TRANSFER_BASED);
  }
  if (configurationType === VolumeRiskConfigurationTypes.ACCOUNT_BASED) {
    return getVolumeRiskDataFeatures(contributions, VolumeRiskFeatureNames.ACCOUNT_BASED);
  }
  if (configurationType === VolumeRiskConfigurationTypes.CUSTOMER_BASED) {
    return getVolumeRiskDataFeatures(contributions, VolumeRiskFeatureNames.CUSTOMER_BASED);
  }
  if (configurationType === VolumeRiskConfigurationTypes.CUSTOMER_GROUP_BASED) {
    return getVolumeRiskDataFeatures(contributions, VolumeRiskFeatureNames.CUSTOMER_GROUP_BASED);
  }

  return [];
}

export function getRules(contributions) {
  const rules = [];
  contributions.forEach((el) => {
    const featureData = getFeatureDisplay(el.name);
    rules.push({
      key: el.name,
      name: featureData.name,
      timeframe: featureData.timeframeText,
      index: featureData.index,
    });
  });

  rules.sort((r1, r2) => r2.index - r1.index);
  return rules;
}

function getAccountHoldingPartyRiskLevel(caseDetail) {
  const masterData = getMasterDataFromCase(caseDetail);
  return masterData?.accountHoldingPartyData?.riskLevel;
}

export function getCountryRiskLevel(caseDetail) {
  const transactionRiskLevel = caseDetail?.transaction?.riskLevel;
  return transactionRiskLevel || getAccountHoldingPartyRiskLevel(caseDetail);
}

export function getVolumeRiskThresholdFromConfig(ruleConfig, riskLevel) {
  const thresholds = [
    {
      expectedRiskLevel: RiskLevel.PROHIBITED_RISK,
      value: (ruleConfig) => ruleConfig.highRiskThreshold,
      configuredValue: (ruleConfig) => ruleConfig.highRiskThreshold,
      riskType: () =>
        i18next.t(
          'case:caseDetail.tabContainer.volumeRisk.customer.thresholdRiskType.high.heading',
        ),
    },
    {
      expectedRiskLevel: RiskLevel.HIGH_RISK,
      value: (ruleConfig) => ruleConfig.highRiskThreshold,
      configuredValue: (ruleConfig) => ruleConfig.highRiskThreshold,
      riskType: () =>
        i18next.t(
          'case:caseDetail.tabContainer.volumeRisk.customer.thresholdRiskType.high.heading',
        ),
    },
    {
      expectedRiskLevel: RiskLevel.MEDIUM_RISK,
      value: (ruleConfig) => ruleConfig.mediumRiskThreshold,
      configuredValue: (ruleConfig) => ruleConfig.mediumRiskThreshold,
      riskType: () =>
        i18next.t(
          'case:caseDetail.tabContainer.volumeRisk.customer.thresholdRiskType.medium.heading',
        ),
    },
    {
      expectedRiskLevel: undefined,
      value: (ruleConfig) => ruleConfig.lowRiskThreshold,
      configuredValue: (ruleConfig) => ruleConfig.lowRiskThreshold,
      riskType: () =>
        i18next.t('case:caseDetail.tabContainer.volumeRisk.customer.thresholdRiskType.low.heading'),
    },
  ];

  const threshold = thresholds.reduce((prev, current) => {
    if (current.expectedRiskLevel === riskLevel) {
      return current;
    }
    return prev;
  }, thresholds[thresholds.length - 1]);

  return {
    threshold: threshold.value(ruleConfig),
    configuredThreshold: threshold.configuredValue(ruleConfig),
    thresholdRiskType: threshold.riskType(),
  };
}

export function getVolumeRiskDisplayDataByRule(
  volumeRisk = {},
  selectedRule,
  riskLevel,
  totalAggregatedAmount,
) {
  const rules = volumeRisk.rules || [];
  const configuredDataForSelectedRule =
    rules.find((r) => r.dataFeatureName === selectedRule.key) || {};
  const { amountAcceleratorMultiplier } = volumeRisk;
  const acceleratorFactorExists =
    !Number.isNaN(amountAcceleratorMultiplier) && amountAcceleratorMultiplier > 0;
  const { currency } = configuredDataForSelectedRule;
  const contextualReplay = false;

  const { threshold, configuredThreshold, thresholdRiskType } = getVolumeRiskThresholdFromConfig(
    configuredDataForSelectedRule,
    riskLevel,
  );

  let thresholdValue = threshold;

  let additionalText = '';
  let thresholdType = '';
  if (acceleratorFactorExists && configuredDataForSelectedRule && contextualReplay) {
    const amountAcceleratorThreshold = thresholdValue * amountAcceleratorMultiplier;

    if (totalAggregatedAmount >= amountAcceleratorThreshold) {
      thresholdValue = amountAcceleratorThreshold;
      thresholdType = ' accelerated';

      additionalText = `The threshold is accelerated to ${currency} ${formatMoney(
        amountAcceleratorThreshold,
      )} (threshold of ${formatMoney(configuredThreshold)} * accelation factor of ${formatMoney(
        amountAcceleratorMultiplier,
      )})`;
    }
  }

  return {
    thresholdValue,
    thresholdType,
    additionalText,
    thresholdRiskType,
    currency,
  };
}

export function isStatusBlocked(status) {
  return [
    i18next.t(`mapping:transactionStatus.BLOCKED.key`),
    i18next.t(`mapping:transactionStatusV2.blocked.key`),
  ].includes(status);
}

export function getTxCountByRule(contributions, dataFeature) {
  const txCountContributionKey = `${dataFeature}-tx-count`;
  const contribution = contributions?.[txCountContributionKey] || {};
  const importance = contribution.importance || {};
  return importance.score || 0;
}

export function getVolumeRiskCurrencyByRule(volumeRisk = {}, selectedRule) {
  const rules = volumeRisk.rules || [];
  const configuredDataForSelectedRule =
    rules.find((r) => r.dataFeatureName === selectedRule.key) || {};
  return configuredDataForSelectedRule.currency;
}

export function getCustomerId(transaction) {
  const masterData = transaction.masterData || {};
  const accountHoldingPartyData = masterData.accountHoldingPartyData || {};
  return accountHoldingPartyData.customerId || {};
}

export function getCustomerGroupId(transaction) {
  const masterData = transaction.masterData || {};
  const accountHoldingPartyData = masterData.accountHoldingPartyData || {};
  return accountHoldingPartyData.customerGroupId || {};
}

export function getCaseIdsFromSelectedRuleDataFeature(decisionTakenEvent, ruleKey) {
  const dataFeatures = decisionTakenEvent.dataFeatures || {};
  const dataFeatureForSelectedRule = dataFeatures[ruleKey] || {};
  const metadata = dataFeatureForSelectedRule.metadata || {};

  return metadata.caseIds || '';
}

export function shouldShowGroupActionButtons(caseDetail, userId) {
  return caseDetail.assignedToUserId === userId && isOpenCase(caseDetail.caseStatus);
}

export function shouldEnableActionButtons(caseDetail, groupCase, auth) {
  // if a case is case group member but operator opens it separately,
  // operator should not allow to do anything, except open case group
  const isSingleCase = caseDetail?.uuid === groupCase?.uuid;
  const isCaseMember = !!caseDetail?.forwardingToCaseId;
  if (isSingleCase && isCaseMember) {
    return false;
  }

  // when operator changes case state of a group case,
  // the state of member cases may not be changed immediately like group case itself
  // due to background processing
  // so here we need to check both group case & case member
  return isMicroAssessmentAllowed(caseDetail, auth) || isMicroAssessmentAllowed(groupCase, auth);
}

export function shouldDisableActionButtons(caseDetail, groupCase, auth) {
  return !shouldEnableActionButtons(caseDetail, groupCase, auth);
}

export function getDisplayAccountNumber(fundsObj) {
  if (fundsObj?.accountNumberOriginal) {
    return fundsObj?.accountNumber || {};
  }

  if (fundsObj?.accountNumberOriginal === false) {
    return {};
  }

  /**
   * If accountNumberOriginal is undefined then supporting backward compatibility since accountNumber used to be
   * automatically generated from different fields if it didn't exist
   */
  if (
    fundsObj?.accountNumber?.token === fundsObj?.accountLookupId?.token &&
    (fundsObj?.accountEmailId?.token ||
      fundsObj?.accountId?.token ||
      fundsObj?.bankCountry ||
      fundsObj?.bankIdentifier ||
      fundsObj?.last4Digits ||
      fundsObj?.expiry)
  ) {
    return {};
  }

  return fundsObj?.accountNumber || {};
}

export function getTxAmount(transaction, useSettledFundsInBaseCurrency = true) {
  const { settledFundsInBaseCurrency } = transaction;
  const { settledFunds } = transaction;

  if (useSettledFundsInBaseCurrency && !!settledFundsInBaseCurrency) {
    return settledFundsInBaseCurrency;
  }

  return settledFunds || {};
}

export function isCurrencyDifferent(caseDetail) {
  const transaction = getFirstTransactionFromCase(caseDetail);
  const { settledFundsInBaseCurrency } = transaction;
  if (!settledFundsInBaseCurrency) {
    return false;
  }
  const settledFunds = transaction.settledFunds || {};
  const baseCurrency = settledFundsInBaseCurrency.currency || '';
  const settledFundsCurrency = settledFunds.currency || '';

  return !!baseCurrency && !!settledFundsCurrency && baseCurrency !== settledFundsCurrency;
}

export function isSix() {
  return TenantManagementService.getActiveTenantId() === TENANT_IDS.multipleEnviroments.six;
}

export function isCreditSuisse() {
  return (
    TenantManagementService.getActiveTenantId() === TENANT_IDS.multipleEnviroments.creditSuisse
  );
}

export function isSubOfRootTenant(rootTenantPrefix) {
  const activeTenant = TenantManagementService.getActiveTenant();
  const tenantName = activeTenant.name || '';
  return tenantName.toLowerCase().indexOf(rootTenantPrefix) >= 0;
}

export function getOpenCasesSortConfig() {
  return {
    text: 'Sort by Risk (Blocked, Highest risk first, Customer alphabetically, Unassigned first, Oldest on top)',
    value: `transactionStatus,${SORT_DIRECTION.asc};transaction.riskLevelNumeric,${SORT_DIRECTION.desc};customer,${SORT_DIRECTION.asc};assignedToUserId,${SORT_DIRECTION.desc};updatedAt,${SORT_DIRECTION.asc}`,
  };
}

export function getNotOpenCasesConfig() {
  return {
    text: 'Newest case on top',
    value: `updatedAt,${SORT_DIRECTION.desc}`,
  };
}

export function isColumnCanSort(caseStatusFilter) {
  return !!(
    caseStatusFilter.key === CaseStatusFilters.OPEN.key ||
    caseStatusFilter.key === CaseStatusFilters.MY_CASES.key ||
    caseStatusFilter.key === CaseStatusFilters.ESCALATED.key ||
    caseStatusFilter.key === CaseStatusFilters.PRIORITY.key ||
    caseStatusFilter.key === CaseStatusFilters.ANY_CASES.key
  );
}

export function isColumnMatchSortConfig(sortConfig, column) {
  return sortConfig.includes(column);
}

/**
 * retrieving originator or beneficaery of a transaction
 * @param {*} partyKey
 * @param {*} transaction
 * @param {*} masterData
 */
// eslint-disable-next-line consistent-return
const getPartyMasterData = (partyKey, transaction, masterData) => {
  if (partyKey === TRANSACTION_PARTY.originator) {
    if (transaction?.accountHoldingParty === TRANSACTION_PARTY.originator) {
      return masterData?.accountHoldingPartyData;
    }
    return masterData?.counterpartyData;
  }
  if (partyKey === TRANSACTION_PARTY.beneficiary) {
    if (transaction?.accountHoldingParty === TRANSACTION_PARTY.beneficiary) {
      return masterData?.accountHoldingPartyData;
    }
    return masterData?.counterpartyData;
  }
};

/**
 * retrieving originator or beneficaery of a transaction
 * @param {*} partyKey
 * @param {*} transaction
 * @param {*} masterData
 */
const getPartyObject = (partyKey, transaction, masterData) => {
  const party = getPartyMasterData(partyKey, transaction, masterData);
  const parties = getMasterDataParties(partyKey, transaction, masterData);
  return {
    data: {
      ...party,
      party,
      parties,
      calculatedRiskLevel: transaction.riskLevel,
      transaction:
        partyKey === TRANSACTION_PARTY.originator
          ? transaction?.fundsOriginator
          : transaction?.fundsBeneficiary,
      underwriterData:
        transaction.accountHoldingParty === partyKey ? masterData?.underwriterData : undefined,
    },
    isCustomer: transaction.accountHoldingParty === partyKey,

    isInitiator: transaction.initiatingParty === partyKey,
  };
};

/**
 * Get customer category from transaction, customer case or item data in case list.
 */
const getCustomerCategory = (item) => {
  if (!item) {
    return undefined;
  }

  // If it is a full case
  const transaction = getFirstTransactionFromCase(item);
  if (!isEmptyObject(transaction)) {
    return transaction.customerCategory;
  }

  // If it is just simple case data, with transaction property
  if (item.transaction) {
    return item.transaction.customerCategory;
  }

  // If its item data in case list
  const { customerCategories } = item;
  if (Array.isArray(customerCategories) && customerCategories.length > 0) {
    return customerCategories[0];
  }

  return undefined;
};

/**
 * check if company
 * @param {*} customerCategory
 * @param {*} defaultCustomerCategory
 */
const isCompany = (customerCategory, defaultCustomerCategory) => {
  const finalCustomerCategory = customerCategory ?? defaultCustomerCategory;
  return i18next.t('mapping:customerType.company.key') === finalCustomerCategory;
};

/**
 * fetching css class color for risks
 * @param {*} riskLevel
 */
const getRiskClassName = (riskLevel) => {
  const riskLevelClass = riskLevel?.toLowerCase()?.replace(RISK_CATEGORY.default, '');

  if (riskLevelClass === RISK_CATEGORY.low) {
    return 'color-success';
  }
  if (riskLevelClass === RISK_CATEGORY.medium) {
    return 'color-warning';
  }
  if (riskLevelClass === RISK_CATEGORY.high) {
    return 'color-error';
  }
  if (riskLevelClass === RISK_CATEGORY.prohibited) {
    return 'color-prohibited';
  }
  return 'color-on-surface-disabled';
};

/**
 * retrieving person name
 * @param {*} personDetails
 */
// eslint-disable-next-line consistent-return
const getPersonName = (personName, skipHolderName = false) => {
  if (personName.holderName && !skipHolderName) {
    return `${personName.holderName}`;
  }
  if (personName.firstName || personName.lastName) {
    return `${personName.firstName} ${personName.lastName}`;
  }
  if (personName.fullName) {
    return personName.fullName;
  }
  if (personName.companyName) {
    return personName.companyName;
  }
  if (personName.partyPersonData) {
    const partiesNames = personName.partyPersonData
      .map((partyPersonName) => {
        if (partyPersonName.firstName || partyPersonName.lastName) {
          return `${partyPersonName.firstName} ${partyPersonName.lastName}`;
        }
        if (partyPersonName.fullName) {
          return partyPersonName.fullName;
        }
        if (partyPersonName.companyName) {
          return partyPersonName.companyName;
        }
        return '';
      })
      .filter((name) => name !== '')
      .join(' and ');
    if (!isEmpty(partiesNames)) {
      return partiesNames;
    }
  }
};

/**
 * calculate person data to display
 * @param {*} allMasks
 * @param {*} personDetails
 * @param {*} visibleState
 * @param {*} companyDetails
 * @param {*} accountHolderParty
 * @param {*} isCompanyTransaction
 */
const getPersonData = (
  allMasks,
  personalDetails,
  companyDetails,
  accountHolderParty,
  visibleState,
  isCompanyTransaction,
) => {
  if (!visibleState) {
    return {
      firstName: personalDetails?.personName?.firstName?.masked,
      lastName: personalDetails?.personName?.lastName?.masked,
      fullName: personalDetails?.personName?.fullName?.masked,
      companyName: companyDetails?.companyName?.masked,
      holderName: accountHolderParty?.holderName?.masked,
    };
  }

  const accountHolderName = getMaskValue(allMasks, accountHolderParty?.holderName);
  return {
    firstName: getMaskValue(allMasks, personalDetails?.personName?.firstName),
    lastName: getMaskValue(allMasks, personalDetails?.personName?.lastName),
    fullName: getMaskValue(allMasks, personalDetails?.personName?.fullName),
    companyName: getMaskValue(allMasks, companyDetails?.companyName),
    holderName:
      accountHolderName.includes('*') && isCompanyTransaction
        ? accountHolderParty?.holderName?.decrypted
        : accountHolderName,
  };
};

/**
 * get label for person type
 * @param {*} isCustomer
 * @param {*} isStaff
 */
const getPersonLabel = (isCustomer, isStaff) => {
  if (isStaff) return i18next.t('staffMember.heading');
  if (isCustomer) return i18next.t('customer.heading');
  return i18next.t('counterParty.heading');
};

/**
 * get customer name
 */
const getVariousCustomerName = () => i18next.t('accountNames.various.heading');

export const hasAddress = (addressObject) =>
  addressObject.street ||
  addressObject.streetExtended ||
  addressObject.town ||
  addressObject.region ||
  addressObject.zip ||
  addressObject.country?.value ||
  addressObject.unstructured;

export const getFromTransactionV2 = (transactions, sourceField) => {
  if (transactions == null) {
    return null;
  }
  const transaction = transactions[0];
  if (sourceField === 'transaction.usage') {
    return transaction.usage;
  }
  if (sourceField === 'accountHoldingPartyData.personalDetails.personName.fullName') {
    return transaction.masterData?.accountHoldingPartyData?.personalDetails?.personName?.fullName;
  }
  if (sourceField && sourceField.includes('transactionProcessingDetails.intermediaries.name')) {
    const idx = sourceField.split(PYTHON_ARRAY_SEPARATOR)[1];
    return transaction.transactionProcessingDetails?.intermediaries[idx]?.name;
  }
  if (
    sourceField &&
    sourceField.includes('transactionProcessingDetails.intermediaries.account.holder')
  ) {
    const idx = sourceField.split(PYTHON_ARRAY_SEPARATOR)[1];
    return transaction.transactionProcessingDetails?.intermediaries[idx]?.account?.holderName;
  }
  if (
    sourceField &&
    sourceField.includes(
      'accountHoldingPartyData.companyDetails.shareholders.personalDetails.personName',
    )
  ) {
    const idx = sourceField.split(PYTHON_ARRAY_SEPARATOR)[1];
    return transaction.masterData?.accountHoldingPartyData?.companyDetails?.shareholders[idx]
      ?.personalDetails?.personName?.fullName;
  }
  return null;
};

export const getFromCrrDetail = (crrDetailData, sourceField) => {
  if (crrDetailData == null) {
    return null;
  }
  if (
    sourceField &&
    sourceField.includes(
      'accountHoldingPartyData.companyDetails.shareholders.personalDetails.personName',
    )
  ) {
    const idx = sourceField.split(PYTHON_ARRAY_SEPARATOR)[1];

    return crrDetailData?.accountHoldingPartyData?.companyDetails?.shareholders[idx]
      ?.personalDetails?.personName?.fullName;
  }
  return null;
};

export const setAccountIdToEmptyStringInRawData = (transaction) => {
  if (!isEmpty(transaction?.fundsBeneficiary) && !transaction.fundsBeneficiary?.accountId) {
    transaction.fundsBeneficiary.accountId = '';
  }

  if (!isEmpty(transaction?.fundsOriginator) && !transaction.fundsOriginator?.accountId) {
    transaction.fundsOriginator.accountId = '';
  }
};

export const doesCaseContainScreeningHitEvent = (caseDetail) =>
  caseDetail?.screeningHitEvents && caseDetail?.screeningHitEvents?.length > 0;

export const transformScreeningHitRecordEntityToJson = (screeningHits) => {
  screeningHits.forEach((hit) => {
    try {
      hit.record = JSON.parse(hit.record);
      hit.entity = JSON.parse(hit.entity);
    } catch (e) {
      //
    }
  });
};

export const hackConvertScreeningRecordEntityToJson = (caseDetail) => {
  // TODO (ERS) we don't need this when we stop saving "record" and "entity" as string, https://hawkai.atlassian.net/browse/ERS-886
  caseDetail.screeningHitEvents?.forEach((she) => {
    transformScreeningHitRecordEntityToJson(she.hits);
  });
};

const decryptWebLinks = (showUnmaskedData, allMasks, webLinks) =>
  webLinks?.map((it) => ({
    title: formatMaskedValue(showUnmaskedData, allMasks, it.title),
    url: formatMaskedValue(showUnmaskedData, allMasks, it.url),
  })) || [];

const valueOrEmptyString = (value) => (value ? `${value} ` : '');

export const parseSortRequest = (headCell, direction, sortingOptions) => {
  const isInverse = headCell.sortConfig?.inverseSortIcon;
  let newDirection;
  if (direction) {
    newDirection = toggleSort(direction, isInverse);
  } else if (isInverse) {
    newDirection = SORT_DIRECTION.desc;
  } else {
    newDirection = SORT_DIRECTION.asc;
  }

  const newSortingOptions = [];

  if (newDirection) {
    newSortingOptions.push({
      field: headCell.sortConfig.name,
      direction: newDirection,
    });
  }

  sortingOptions
    .filter((so) => so.field !== headCell?.sortConfig?.name)
    .forEach((so) => newSortingOptions.push(so));
  return newSortingOptions;
};

export {
  getPartyObject,
  getCustomerCategory,
  isCompany,
  getPersonName,
  getPersonData,
  getVariousCustomerName,
  getRiskClassName,
  getPersonLabel,
  isBankIdentifierHit,
  decryptWebLinks,
  valueOrEmptyString,
};
