import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import CaseAPICommunicator from 'modules/trx-cases/case-detail/services/case/case-api-communicator';
import CaseManager from 'modules/trx-cases/case-detail/services/case/case-manager';
import { CASE_ACTIONS, CaseActions } from 'middleware/action-types';
import {
  addCaseToCaseGroupError,
  addCaseToCaseGroupSuccess,
  breakOutCaseFromGroupError,
  breakOutCaseFromGroupSuccess,
  getCaseDetail,
  getCaseLast4MonthsTxHeatmapImageError,
  getCaseLast4MonthsTxHeatmapImageSuccess,
  getCaseLast4MonthsTxMetadataError,
  getCaseLast4MonthsTxMetadataSuccess,
  getCaseListByIdsError,
  getCaseListByIdsSuccess,
  getCustomerPreviousAlertsError,
  getCustomerPreviousAlertsSuccess,
  getCustomerProfileBalancesError,
  getCustomerProfileBalancesSuccess,
  getTransactionsError,
  getTransactionsSuccess,
} from 'middleware/actions/case';
import { showError } from 'middleware/actions/error';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { updateMasks } from 'middleware/actions/mask';
import { getMaskedsFromCases } from 'utils/helpers/cases/case-utility';

const caseCommunicator = new CaseAPICommunicator();
const caseManager = new CaseManager(caseCommunicator);

function* getTransactions(action, queryTransactions) {
  yield put(startProcessing(CaseActions.TRANSACTIONS_GET));
  try {
    const json = yield queryTransactions(action);
    yield put(
      getTransactionsSuccess({
        type: action.payload.type,
        historyKey: action.payload.historyKey,
        data: json,
      }),
    );
    yield put(updateMasks(getMaskedsFromCases(json)));
  } catch (error) {
    yield put(getTransactionsError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.TRANSACTIONS_GET));
}

function* getTransactionsBegin(action) {
  yield getTransactions(action, (a) =>
    caseManager.queryTransactions({
      ...a.payload,
    }),
  );
}

function* getTransactionsV2(action, queryTransactions) {
  yield put(startProcessing(CaseActions.TRANSACTIONS_V2.get));
  try {
    const json = yield queryTransactions(action);
    yield put(
      getTransactionsSuccess({
        type: action.payload.type,
        data: json,
        scrollId: json.scrollId,
      }),
    );

    yield put(updateMasks(getMaskedsFromCases(json)));
  } catch (error) {
    yield put(getTransactionsError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.TRANSACTIONS_V2.get));
}

function* getTransactionsV2Begin(action) {
  yield getTransactionsV2(action, ({ payload }) => {
    const { filtering, paging, sorting } = payload;
    return caseManager.queryTransactionsV2(filtering, paging, sorting);
  });
}

export function* getTransactionsWatcher() {
  yield takeEvery(CaseActions.TRANSACTIONS_GET, getTransactionsBegin);
}

export function* getTransactionsV2Watcher() {
  yield takeEvery(CaseActions.TRANSACTIONS_V2.get, getTransactionsV2Begin);
}

function* getCustomerPreviousAlertsBegin(action) {
  yield put(startProcessing(CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS));
  try {
    const payload = action.payload || {};
    const json = yield caseManager.getCustomerPreviousAlerts(
      payload.customerAccountLookupIdToken,
      payload.caseId,
      payload.createdAt,
      payload.page,
      payload.pageSize,
    );
    yield put(getCustomerPreviousAlertsSuccess(json));
  } catch (error) {
    yield put(getCustomerPreviousAlertsError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS));
}

export function* getCustomerPreviousAlertsWatcher() {
  yield takeLatest(CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS, getCustomerPreviousAlertsBegin);
}

function* getCustomerProfileBalancesBegin(action) {
  yield put(startProcessing(CaseActions.GET_CUSTOMER_PROFILE_BALANCES));
  try {
    const payload = action.payload || {};
    const json = yield caseManager.getCustomerProfileBalances({
      customerAccountLookupIdToken: payload.customerAccountLookupIdToken,
      fromYear: payload.fromYear,
      zoneId: payload.zoneId,
    });
    yield put(
      getCustomerProfileBalancesSuccess({
        ...json,
        customerAccountLookupIdToken: payload.customerAccountLookupIdToken,
      }),
    );
  } catch (error) {
    yield put(getCustomerProfileBalancesError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.GET_CUSTOMER_PROFILE_BALANCES));
}

export function* getCustomerProfileBalancesWatcher() {
  yield takeEvery(CaseActions.GET_CUSTOMER_PROFILE_BALANCES, getCustomerProfileBalancesBegin);
}

function* getCaseLast4MonthsTxMetadataBegin(action) {
  yield put(startProcessing(CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA));
  try {
    const payload = action.payload || {};
    const json = yield caseManager.getCaseLast4MonthsTxMetadata(payload.tenantId, payload.caseId);
    yield put(getCaseLast4MonthsTxMetadataSuccess(json));
  } catch (error) {
    yield put(getCaseLast4MonthsTxMetadataError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA));
}

export function* getCaseLast4MonthsTxMetadataWatcher() {
  yield takeEvery(
    CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA,
    getCaseLast4MonthsTxMetadataBegin,
  );
}

function* getCaseLast4MonthsTxHeatmapImageBegin(action) {
  yield put(startProcessing(CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE));
  try {
    const payload = action.payload || {};
    const json = yield caseManager.getCaseLast4MonthsTxHeatmapImage(
      payload.tenantId,
      payload.caseId,
    );
    yield put(getCaseLast4MonthsTxHeatmapImageSuccess(json));
  } catch (error) {
    yield put(getCaseLast4MonthsTxHeatmapImageError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE));
}

export function* getCaseLast4MonthsTxHeatmapImageWatcher() {
  yield takeEvery(
    CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE,
    getCaseLast4MonthsTxHeatmapImageBegin,
  );
}

function* breakOutCaseFromGroupBegin(action) {
  yield put(startProcessing(CaseActions.BREAK_OUT_CASE_FROM_GROUP));
  try {
    const payload = action.payload || {};
    const json = yield caseManager.breakOutCaseFromGroup(
      payload.groupId,
      payload.breakingOutCaseIds,
      payload.operatorNote,
    );
    if (json.groupCase !== null) {
      yield put(breakOutCaseFromGroupSuccess(json));
    } else {
      const error = {
        message: `${json.errorMessage}. Please reopen this case to update the final status.`,
      };
      yield put(showError({ ...error, action }));
    }
  } catch (error) {
    yield put(breakOutCaseFromGroupError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.BREAK_OUT_CASE_FROM_GROUP));
}

export function* breakOutCaseFromGroupBeginWatcher() {
  yield takeEvery(CaseActions.BREAK_OUT_CASE_FROM_GROUP, breakOutCaseFromGroupBegin);
}

function* addCaseToGroupBegin(action) {
  yield put(startProcessing(CaseActions.ADD_CASE_TO_CASE_GROUP));
  try {
    const payload = action.payload || {};
    const json = yield caseManager.addCaseToGroup(
      payload.groupId,
      payload.addingCaseId,
      payload.operatorNote,
    );
    if (json.groupCase !== null) {
      yield put(addCaseToCaseGroupSuccess(json));
      yield put(getCaseDetail(payload.groupId, true));
    } else {
      const error = {
        message: `${json.errorMessage}. Please reopen this case to update the final status.`,
      };
      yield put(showError({ ...error, action }));
    }
  } catch (error) {
    yield put(addCaseToCaseGroupError());
    yield put(showError({ ...error, action }));
  }

  yield put(stopProcessing(CaseActions.ADD_CASE_TO_CASE_GROUP));
}

export function* addCaseToGroupWatcher() {
  yield takeEvery(CaseActions.ADD_CASE_TO_CASE_GROUP, addCaseToGroupBegin);
}

function* getCaseCollectionByIds(action) {
  try {
    yield put(startProcessing(CASE_ACTIONS.allCases.list.data));
    const json = yield caseManager.getCaseCollection(action.payload.ids);
    yield put(getCaseListByIdsSuccess(json));
  } catch (error) {
    yield put(getCaseListByIdsError());
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing(CASE_ACTIONS.allCases.list.data));
}

export function* getCaseCollectionByIdsWatcher() {
  yield takeLatest(CASE_ACTIONS.allCases.list.data, getCaseCollectionByIds);
}
