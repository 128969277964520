import { isNil, orderBy } from 'lodash';
import {
  CASE_ACTIONS,
  CaseActions,
  ConfluenceActions,
  ImageActions,
} from 'middleware/action-types';
import { getDerivedTransactionHistory } from 'middleware/reducers/reducer-utils';
import { TRX_CASE_DETAIL_ACTIONS } from 'modules/trx-cases/case-detail/middleware/action-types';
import {
  getFirstTransactionFromCase,
  hackConvertScreeningRecordEntityToJson,
  isMoneyMuleTransaction,
} from 'utils/helpers/cases/case-utility';
import { createAndDownloadBlobFile } from 'utils/helpers/file.helpers';
import { randomInterval } from 'utils/helpers/utility';
import { buildVolumeRiskAssessmentKey } from 'utils/helpers/volume-rule-util';

interface CaseDetail {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactionsV2?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transaction?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactionV2?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caseNotes?: any[];
}

interface CaseState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cases: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caseDetail?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardingCases?: any[];
  totalCases?: number;
  paging?: {
    scrollId: string;
    lastPage: boolean;
  };
  currentPage?: number;
  lastPage?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caseStateChanges?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currencies?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sanctionOperatorMatchMap?: any;
  beneficiaryBlurState?: boolean;
  originatorBlurState?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transactionHistory?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customerPreviousAlerts?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customerProfileBalances?: any;
  customerProfileAccountLookupIdToken?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confluenceArticles?: any;
}

const replaceWithTransactionsV2 = (caseDetail: CaseDetail) => {
  if (caseDetail.transactionsV2) {
    caseDetail.transactions = caseDetail.transactionsV2;
  }
  if (caseDetail.transactionV2) {
    caseDetail.transaction = caseDetail.transactionV2;
  }
  return caseDetail;
};

const initialState = {
  cases: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const caseReducer = (state: CaseState = initialState, action: any) => {
  switch (action.type) {
    case CASE_ACTIONS.allCases.fetch.data: {
      if (!action?.payload?.paging?.scrollId) {
        return {
          ...state,
          cases: [],
          caseDetail: undefined,
        };
      }
      return state;
    }

    case CASE_ACTIONS.allCases.fetch.success: {
      const cases = [...state.cases, ...action.payload.cases];
      return {
        ...state,
        cases,
        totalCases: action.payload.total,
        paging: {
          scrollId: action.payload.paging.scrollId,
          lastPage: action.payload.cases.length === 0,
        },
      };
    }

    case CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET: {
      if (action.page === 0 && action.firstLoad === true) {
        return {
          ...state,
          forwardingCases: [],
        };
      }
      return state;
    }

    case CaseActions.CASES_BY_FORWARDING_TO_CASEID_GET_SUCCESS: {
      const prevForwardingCases = state.forwardingCases ?? [];
      const { page, pageSize, forwardingCases } = action.payload;

      forwardingCases.forEach((caseItem: CaseDetail) => {
        caseItem.caseNotes = orderBy(caseItem.caseNotes, ['timestamp'], ['desc']);
        caseItem = replaceWithTransactionsV2(caseItem); // eslint-disable-line no-param-reassign
        hackConvertScreeningRecordEntityToJson(caseItem);
      });

      const nextForwardingCases = [...prevForwardingCases];
      nextForwardingCases.splice(page * pageSize, pageSize, ...forwardingCases);

      return {
        ...state,
        forwardingCases: nextForwardingCases,
      };
    }

    case CaseActions.CASE_DETAIL_SELECT: {
      return {
        ...state,
        caseDetail: action.caseDetail,
        beneficiaryBlurState: false,
        originatorBlurState: false,
      };
    }

    case CaseActions.CASE_DETAIL_GET: {
      if (action.firstLoad) {
        // reset necessary case data whenever load a new case
        // otherwise it could cause display issue
        return {
          ...state,
          caseDetail: undefined,
          forwardingCases: undefined,
        };
      }

      return state;
    }

    case CaseActions.CASE_DETAIL_CLEAR: {
      return {
        ...state,
        caseDetail: undefined,
        forwardingCases: undefined,
      };
    }

    case CaseActions.CASE_DETAIL_GET_SUCCESS: {
      const caseDetail = action.payload;
      // TODO: remove it once all data features available
      isMoneyMuleTransaction(caseDetail);
      replaceWithTransactionsV2(caseDetail);

      // TODO: Will be removed
      const decisionTakenEvent = caseDetail.decisionTakenEvent || {};
      const indivdualDecisions = decisionTakenEvent.individualDecisions || [];
      const transaction = getFirstTransactionFromCase(caseDetail);
      const masterData = transaction.masterData || {};
      const accountHoldingPartyData = masterData.accountHoldingPartyData || {};
      const { referenceAccount } = accountHoldingPartyData;

      for (let i = 0; i < indivdualDecisions.length; i++) {
        const decision = indivdualDecisions[i];
        if (
          decision.name === 'ReferenceAccountDecision' &&
          decision.decisionEffect === 'ACTIVATED' &&
          referenceAccount !== null &&
          decision.caseStatus === 'AUTO_CLOSED'
        ) {
          caseDetail.types.push({
            name: 'REFERENCE_ACCOUNT',
            description: 'Reference account',
          });

          break;
        }
      }

      caseDetail.caseNotes = orderBy(action.payload.caseNotes, ['timestamp'], ['desc']);

      hackConvertScreeningRecordEntityToJson(caseDetail);

      return {
        ...state,
        caseDetail,
      };
    }

    case CaseActions.CASE_DETAIL_GET_ERROR: {
      return {
        ...state,
        caseDetail: undefined,
        beneficiaryBlurState: false,
        originatorBlurState: false,
      };
    }

    case CaseActions.CASE_STATUS_FORCEFUL_UPDATE_SUCCESS: {
      const caseDetail = action.payload;
      return {
        ...state,
        caseDetail,
      };
    }

    case CaseActions.CASE_STATUS_FORCEFUL_UPDATE_ERROR: {
      return {
        ...state,
      };
    }

    case CaseActions.FORWARDING_CASE_DETAIL_GET_SUCCESS: {
      const forwardingCases = state.forwardingCases || [];
      const forwardingCase = action.payload || {};
      let idx = -1;
      for (let i = 0; i < forwardingCases.length; i++) {
        if (forwardingCases[i].uuid === forwardingCase.uuid) {
          idx = i;
          hackConvertScreeningRecordEntityToJson(forwardingCase);
          break;
        }
      }
      const newForwardingCases = [
        ...forwardingCases.slice(0, idx),
        forwardingCase,
        ...forwardingCases.slice(idx + 1),
      ];

      newForwardingCases.forEach((caseItem) => {
        caseItem.caseNotes = orderBy(caseItem.caseNotes, ['timestamp'], ['desc']);
      });

      return {
        ...state,
        forwardingCases: newForwardingCases,
      };
    }

    case CaseActions.CASE_STATE_CHANGES_GET:
    case CaseActions.CASE_STATE_UPDATE: {
      return {
        ...state,
        caseStateChanges: [],
      };
    }

    case CaseActions.CASE_STATE_CHANGES_GET_SUCCESS:
    case CaseActions.CASE_STATE_UPDATE_SUCCESS: {
      const caseStateChanges = action.payload;
      return {
        ...state,
        caseStateChanges,
      };
    }

    case CaseActions.CURRENCY_GET_SUCCESS: {
      const newState = { ...state };
      newState.currencies = action.payload;
      return newState;
    }

    case TRX_CASE_DETAIL_ACTIONS.caseNotes.add.success: {
      // TODO: put notes to correct case in group
      const caseDetail = state.caseDetail || {};
      const caseNotes = caseDetail.caseNotes || [];

      return {
        ...state,
        caseDetail: {
          ...caseDetail,
          caseNotes: [action.payload, ...caseNotes],
        },
      };
    }

    case TRX_CASE_DETAIL_ACTIONS.grouping.stopGrouping.success: {
      const caseDetail = state.caseDetail || {};

      return {
        ...state,
        caseDetail: {
          ...caseDetail,
          stopGroupingInfo: { ...action.payload },
        },
      };
    }

    case TRX_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.success: {
      createAndDownloadBlobFile(action.payload.blob, action.payload.properties.fileName);

      const { caseNotes } = state.caseDetail;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const noteIndex = caseNotes?.findIndex((x: any) => x.id === action.payload.properties.noteId);
      const attachmentIndex = caseNotes?.[noteIndex]?.attachments?.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x: any) => x.uniqueId === action.payload.properties.attachmentId,
      );

      if (!isNil(attachmentIndex)) {
        caseNotes[noteIndex].attachments[attachmentIndex].blobData = action.payload.blob;
      }

      return {
        ...state,
        caseDetail: {
          ...state.caseDetail,
          caseNotes,
        },
      };
    }

    case CaseActions.SANCTION_HIT_VALID_MATCHES_UPDATE_SUCCESS: {
      const sanctionOperatorMatchMap = state.sanctionOperatorMatchMap || {};
      sanctionOperatorMatchMap[action.requestId] = action.payload;
      return {
        ...state,
        sanctionOperatorMatchMap,
      };
    }

    case ImageActions.SHOW_BENEFICIARY_IMAGE_SUCCESS: {
      return {
        ...state,
        beneficiaryBlurState: true,
        beneficiaryImageInterval: randomInterval(),
      };
    }

    case ImageActions.SHOW_ORIGINATOR_IMAGE_SUCCESS: {
      return {
        ...state,
        originatorBlurState: true,
        originatorImageInterval: randomInterval(),
      };
    }

    case CaseActions.ASSESS_VOLUME_RISK_SUCCESS: {
      const { masterCaseId, caseId, assessment } = action.payload || {};
      const forwardingCases = state.forwardingCases || [];
      const caseDetail =
        masterCaseId === caseId ? state.caseDetail : forwardingCases.find((c) => c.uuid === caseId);

      const volumeRisk = caseDetail.volumeRisk || {};
      const assessmentEvents = volumeRisk.assessmentEvents || {};
      const key: string =
        buildVolumeRiskAssessmentKey(assessment.questionId, assessment.custom.ruleName) || '';
      let assessmentEvent = assessmentEvents[key];
      if (!assessmentEvent) {
        assessmentEvent = {};
        assessmentEvents[key] = assessmentEvent;
      }
      assessmentEvent.assessment = assessment;

      const newCaseDetail = {
        ...caseDetail,
        volumeRisk: {
          ...caseDetail.volumeRisk,
          assessmentEvents,
        },
      };

      if (masterCaseId === caseId) {
        return {
          ...state,
          caseDetail: newCaseDetail,
        };
      }
      const idx = forwardingCases.indexOf(caseDetail);
      forwardingCases[idx] = newCaseDetail;
      return {
        ...state,
        forwardingCases: [...forwardingCases],
      };
    }

    case CaseActions.ASSESS_COUNTRY_RISK_SUCCESS: {
      const { masterCaseId, caseId, assessment } = action.payload || {};
      const forwardingCases = state.forwardingCases || [];
      const caseDetail =
        masterCaseId === caseId ? state.caseDetail : forwardingCases.find((c) => c.uuid === caseId);

      const countryRisk = caseDetail.countryRisk || {};
      const assessmentEvents = countryRisk.assessmentEvents || {};
      let assessmentEvent = assessmentEvents[assessment.questionId];

      // TODO: return object is just assessment and not a full assessmentEvent
      // so without reload we are missing the data from the event like createdAt
      if (!assessmentEvent) {
        assessmentEvent = {};
        assessmentEvents[assessment.questionId] = assessmentEvent;
      }

      assessmentEvent.assessment = assessment;

      const newCaseDetail = {
        ...caseDetail,
        countryRisk: {
          ...caseDetail.countryRisk,
          assessmentEvents,
        },
      };

      if (masterCaseId === caseId) {
        return {
          ...state,
          caseDetail: newCaseDetail,
        };
      }
      const idx = forwardingCases.indexOf(caseDetail);
      forwardingCases[idx] = newCaseDetail;
      return {
        ...state,
        forwardingCases: [...forwardingCases],
      };
    }

    case CaseActions.TRANSACTIONS_V2.get: {
      if (!action.payload.paging?.scrollId) {
        return {
          ...state,
          transactionHistory: getDerivedTransactionHistory(state, action.payload.type, []),
        };
      }
      return state;
    }

    case CaseActions.TRANSACTIONS_GET: {
      const payload = action.payload || {};
      if (payload.page === 0) {
        return {
          ...state,
          transactionHistory: getDerivedTransactionHistory(
            state,
            payload.historyKey || payload.type,
            [],
          ),
        };
      }
      return state;
    }

    case CaseActions.TRANSACTIONS_GET_SUCCESS: {
      const payload = action.payload || {};

      const transactionHistory = state.transactionHistory || {};
      const cases = payload.data.transactions || [];
      const type = payload.historyKey || payload.type;

      return {
        ...state,
        transactionHistory: getDerivedTransactionHistory(
          state,
          type,
          transactionHistory[type],
          cases,
        ),
        ...(action.payload.type === 'CUSTOMER_ACCOUNT'
          ? {
              transactionHistoryPaging: {
                scrollId: action.payload.data.scrollId,
              },
            }
          : undefined),
      };
    }

    case CASE_ACTIONS.allCases.list.success: {
      return {
        ...state,
        transactionHistory: getDerivedTransactionHistory(
          state,
          'COUNTER_PARTY',
          action.payload.cases,
        ),
      };
    }

    case CaseActions.ASSIGN_CASE_TO_OPERATOR:
    case CaseActions.ASSIGN_CASE_TO_OPERATOR_ERROR: {
      return {
        ...state,
      };
    }

    case CaseActions.ASSIGN_CASE_TO_OPERATOR_SUCCESS: {
      const caseDetail = replaceWithTransactionsV2(action.payload);
      return {
        ...state,
        caseDetail,
      };
    }

    case CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          customerPreviousAlerts: [],
        };
      }
      return state;
    }

    case CaseActions.GET_CUSTOMER_PREVIOUS_ALERTS_SUCCESS: {
      const payload = action.payload || {};
      const data = payload.data || {};
      const content = data.content || [];
      const customerPreviousAlerts = [...state.customerPreviousAlerts, ...content];

      return {
        ...state,
        customerPreviousAlerts,
        currentPage: data.number,
        lastPage: data.last,
      };
    }

    case CaseActions.GET_CUSTOMER_PROFILE_BALANCES_SUCCESS: {
      const customerProfileBalances = state.customerProfileBalances || {};
      const payload = action.payload || {};
      const {
        monthlyCredits = [],
        monthlyDebits = [],
        yearlyCredits = [],
        yearlyDebits = [],
      } = payload;

      customerProfileBalances.totalMonthlyCredits = monthlyCredits;
      customerProfileBalances.totalMonthlyDebits = monthlyDebits;
      customerProfileBalances.totalYearlyCredits = yearlyCredits;
      customerProfileBalances.totalYearlyDebits = yearlyDebits;

      return {
        ...state,
        customerProfileBalances: { ...customerProfileBalances },
        customerProfileTimestamp: new Date().getTime(),
        customerProfileAccountLookupIdToken: payload.customerAccountLookupIdToken,
      };
    }

    case CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA: {
      return {
        ...state,
        caseLast4MonthsTxMetadata: [],
      };
    }

    case CaseActions.GET_CASE_LAST_4_MONTHS_TX_METADATA_SUCCESS: {
      return {
        ...state,
        caseLast4MonthsTxMetadata: action.payload,
      };
    }

    case CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE: {
      return {
        ...state,
        caseLast4MonthsTxHeatmapImage: undefined,
      };
    }

    case CaseActions.GET_CASE_LAST_4_MONTHS_TX_HEATMAP_IMAGE_SUCCESS: {
      return {
        ...state,
        caseLast4MonthsTxHeatmapImage: action.payload,
      };
    }

    case CaseActions.BREAK_OUT_CASE_FROM_GROUP_SUCCESS: {
      return {
        ...state,
        breakingOutCaseCompletedAt: new Date().getTime(),
      };
    }

    case ConfluenceActions.CONFLUENCE_ARTICLE_GET_SUCCESS: {
      return {
        ...state,
        confluenceArticles: [...(state.confluenceArticles || []), action.payload],
      };
    }

    case CaseActions.ADD_CASE_TO_CASE_GROUP_SUCCESS: {
      const groupCase = action.payload.groupCase || {};
      return {
        ...state,
        newGroupCase: groupCase,
      };
    }

    case CASE_ACTIONS.config.fetch.success: {
      return {
        ...state,
        tenantConfig: action.payload.tenantConfig,
        latestTenantConfig: action.payload.latestTenantConfig,
        decisionEngineConfig: action.payload.decisionEngineConfig,
        countryRiskConfig: action.payload.countryRiskConfig,
        detachmentConfig: action.payload.detachmentConfig,
        config: {
          pepSanctionInstanciableConfig: action.payload.pepSanctionInstanciableConfig,
          amlRiskScreeningConfig: action.payload.amlRiskScreeningConfig,
        },
      };
    }

    case CASE_ACTIONS.transactionHistory.reset: {
      return {
        ...state,
        transactionHistory: [],
        transactionHistoryPaging: {},
      };
    }

    default:
      return state;
  }
};

export default caseReducer;
