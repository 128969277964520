import { Auth0Provider } from '@auth0/auth0-react';
import GlobalState from 'contexts/global/global-state';
import { BrowserRouter } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'assets/css/app.scss';
import 'assets/css/audit-trail.scss';
import 'assets/css/case-detail.css';
import 'assets/css/case-list.scss';
import 'assets/css/float-button.css';
import 'assets/css/grid.css';
import 'assets/css/master-details-global.css';
import 'assets/css/person-chip.css';
import 'assets/css/react-draft-wysiwyg.css';
import 'assets/css/sankey.css';
import 'assets/css/search-box.css';
import 'assets/css/sidebar.scss';
import 'assets/css/style.min.css';
import 'assets/css/switch.css';
import 'assets/css/tree-view.css';
import { providerConfig } from 'modules/authentication/auth0-config';
import CursorState from 'modules/layout/cursor-state';
import AlertContainer from 'modules/layout/main-view/components/alert-container';
import RouterSwitch from 'modules/layout/router-switch';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-sortable-tree/style.css';
import QueryProvider from 'services/query-client/query-client-provider';

const App = () => (
  <BrowserRouter>
    <QueryProvider>
      <GlobalState>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Auth0Provider {...providerConfig}>
            <CursorState>
              <RouterSwitch />
              <AlertContainer />
            </CursorState>
          </Auth0Provider>
        </LocalizationProvider>
      </GlobalState>
    </QueryProvider>
  </BrowserRouter>
);

export default App;
