import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from '@mui/material';
import moment from 'moment';
import Strings from 'utils/helpers/locales/strings.en';
import { formatMoney } from 'utils/helpers/math-util';
import { escapeLinkTag, stripTrimString } from 'utils/helpers/string-util';

export const daysBetween = (startDate, endDate) =>
  Math.abs(moment(startDate).diff(endDate, 'days', true));

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getTimezoneNumber = () => moment().format('Z');

export const convertMultiplierToCorrectView = (multiplier) => {
  const multiplierAsInt = parseInt(multiplier, 10);
  const isValidNegative = multiplierAsInt < 0 && Math.abs(multiplierAsInt) > 100;
  return isValidNegative ? '-100' : multiplier;
};

const getPercentageValue = (value) => {
  if (value < 100) {
    return `-${100 - value}`;
  }
  if (value >= 100) {
    return value - 100;
  }
  return value;
};

export const convertMultiplierToReadablePercentage = (action) => {
  const { instances } = action.payload;
  instances.forEach((instance) => {
    if (instance.threshold) {
      const v = parseInt(instance.threshold, 10);
      instance.threshold = getPercentageValue(v);
    } else {
      const v = parseInt(instance.multiplier, 10);
      instance.multiplier = getPercentageValue(v);
    }
  });
  return instances;
};

/** 2nd implementation without modifying the threshold by substracting 100 * */
export const convertMultiplierToReadablePercentage2 = (action) => {
  const { instances } = action.payload;
  instances.forEach((instance) => {
    if (instance.threshold) {
      const v = parseInt(instance.threshold, 10);
      instance.threshold = v < 0 ? `-${v}` : v;
    } else {
      const v = parseInt(instance.multiplier, 10);
      instance.multiplier = v < 0 ? `-${v}` : v;
    }
  });
  return instances;
};

export function buildPlusTabData() {
  return {
    name: null,
    content: (
      <IconButton color="primary" size="large">
        <AddCircleIcon />
      </IconButton>
    ),
  };
}

export function buildDisabledPlusTabData() {
  return {
    name: null,
    content: (
      <IconButton
        style={{ backgroundColor: 'transparent', pointerEvents: 'none' }}
        color="disabled"
        size="large"
      >
        <AddCircleIcon />
      </IconButton>
    ),
  };
}

export function buildTabTitle(rule, defaultTitle) {
  if (rule.amountThreshold === 0) {
    return defaultTitle;
  }

  const title = `${defaultTitle} ${formatMoney(rule.amountThreshold)} - ${
    rule.timeframeThreshold
  } day(s)`;
  if (title.length > 35) {
    return `${title.substring(0, 35)}...`;
  }

  return title;
}

export function getTransactionTypeText(type) {
  if (!type || type.length === 0) {
    return '';
  }

  const text = Strings.TransactionType[type];
  return text || '';
}

export const convertToUtcDate = (dateStr, withHour = false) => {
  if (!dateStr) {
    return null;
  }

  let options = { month: 'long', day: '2-digit', year: 'numeric' };

  if (withHour) {
    options = {
      ...options,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
  }

  const date = new Date(dateStr.replace('[UTC]', ''));
  return new Intl.DateTimeFormat('en', options).format(date);
};

export const convertToLocalDate = (rawDate, withHour = false, language = 'en') => {
  const processedDate = new Date(rawDate);
  let options = { month: 'long', day: '2-digit', year: 'numeric' };
  if (withHour) {
    options = {
      ...options,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
  }
  return new Intl.DateTimeFormat(language, options).format(processedDate);
};

const getValueFromTimeFrameInDays = (timeFrame) => timeFrame.substr(0, timeFrame.length - 1);

export const getValueFromTimeFrame = (timeFrame) => {
  if (timeFrame?.length) {
    if (timeFrame.slice(-1) === 'd') {
      return getValueFromTimeFrameInDays(timeFrame);
    }
    if (timeFrame.slice(-1) === 'w') {
      return (7 * getValueFromTimeFrameInDays(timeFrame)).toString();
    }
    if (timeFrame.slice(-1) === 'm') {
      return (30 * getValueFromTimeFrameInDays(timeFrame)).toString();
    }
  }

  return '';
};

export const stripAndEscapeRuleInputs = (ruleData) => {
  delete ruleData.isNewlyCreatedInstance;
  ruleData.title = stripTrimString(ruleData.title);
  const notes = ruleData.commonConfigCriteria?.notes;

  if (notes) {
    const stripedNote = escapeLinkTag(notes);
    ruleData.commonConfigCriteria = {
      ...ruleData.commonConfigCriteria,
      notes: stripedNote,
    };
    ruleData.notes = stripedNote;
  }
};

export const getInputCss = (borderBottom) =>
  `row-p8 ${borderBottom === false ? '' : 'border-bottom'}`;

export const compareOptionLabel = (op1, op2) => {
  if (op1.label < op2.label) {
    return -1;
  }
  if (op1.label > op2.label) {
    return 1;
  }
  return 0;
};
