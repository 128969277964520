import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCustomViews } from 'hooks/use-custom-views';
import { CustomView } from 'models/custom-view';

type DeleteCustomViewDialogProps = {
  viewToDelete: CustomView | undefined;
  setViewToDelete: (value: CustomView | undefined) => void;
};

export const DeleteCustomViewDialog = (props: DeleteCustomViewDialogProps) => {
  const { viewToDelete, setViewToDelete } = props;
  const { t } = useTranslation();
  const { deleteCustomView } = useCustomViews();

  const handleDelete = (_: React.MouseEvent<HTMLButtonElement>) => {
    if (viewToDelete === undefined) return;
    deleteCustomView(viewToDelete.id);
    setViewToDelete(undefined);
  };

  return (
    <Dialog open={viewToDelete !== undefined} fullWidth closeAfterTransition={false}>
      <DialogTitle className="pb-0">
        {t('case:caseList.customViews.deleteView.heading')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid container item alignItems="center" className="mt-3">
            <Grid item xs alignItems="center">
              <Typography>{`
              ${t('case:caseList.customViews.deleteView.subheading')}
              ${viewToDelete?.name}?`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setViewToDelete(undefined)} color="default">
          {t('cancel.heading')}
        </Button>
        <Button color="warning" variant="contained" onClick={handleDelete}>
          {t('delete.heading')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
